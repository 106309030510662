/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {
  UploadAdapter,
  FileLoader,
} from '@ckeditor/ckeditor5-cloud-services/node_modules/@ckeditor/ckeditor5-upload/src/filerepository';
import { FileUploader } from 'react-drag-drop-files';
import parse from 'html-react-parser';
import Divider from '@mui/material/Divider';
import convertImageToBase64 from '../../../core/utils/convertImageToBase64';
import { Typography } from '@mui/material';
import SideBar from '../../components/Drawer';
import { DrawerProvider } from '../../contexts';
import Header from '../../components/Header';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getPlacePerformanceByID,
  placePerformanceUpdateByID,
} from '../../../service/PlacePerformance';
import ErrorAPI from '../../../core/models/ErrorAPI';
import SnackBar from '../../components/SnackBar';
import Loading from '../../components/Loading';
import { IPlacePerformance } from '../../../core/interfaces';
import { Button } from '@mui/material';
const EditClassroomInfo: React.FC = () => {
  const [imageClassroomInfo, setImageClassroomInfo] = useState<string | null>(
    null,
  );
  const { id_place } = useParams();
  const navigate = useNavigate();
  const [fileBackgroud, setFileBackgroud] = useState<File | null>(null);
  const containerBackgroudRef = useRef<HTMLDivElement | null>(null);
  const [content, setContent] = useState<string>('<p>Hello world</p>');
  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [placePerformance, setPlacePerformance] = useState<IPlacePerformance>(
    {} as IPlacePerformance,
  );

  const handleFileBackgroud = (file: File) => {
    const newImage = new Image();

    newImage.src = URL.createObjectURL(file);

    newImage.onload = function () {
      if (newImage.width !== 1123 && newImage.height !== 794) {
        alert('A imagem deve ter dimensões de 1123x794.');
        return;
      }
      if (file) {
        setFileBackgroud(file);
        convertImageToBase64(file, (base64String) => {
          setImageClassroomInfo(base64String);
        });
      }
    };
  };

  const handleEditorChangeContet = (newContent: string) => {
    setContent(newContent);
  };

  const handleSendUpadtePlace = async () => {
    const data = {
      content_editor: content,
      base_64_image: imageClassroomInfo,
    };

    setOpenLoading(true);
    const response = await placePerformanceUpdateByID(id_place!, data);
    setOpenLoading(false);

    if (response instanceof ErrorAPI) {
      if (response.statusCode == 401) {
        navigate('/logout');
      }

      if (response.statusCode == 500) {
        setSnackbarMessage(response.message);
        setSnackbarSeverity('warning');
        setSnackbarOpen(true);
      }
    } else {
      setPlacePerformance(response);
      setContent(response.content_editor!);
      setImageClassroomInfo(response.base_64_image!);
    }
  };

  function uploadAdapter(loader: FileLoader): UploadAdapter {
    return {
      upload: () => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          const file = await loader.file;
          return {
            default: 'https://teste',
          };
        });
      },
      abort: () => {},
    };
  }
  function uploadPlugin(editor: Editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  useEffect(() => {
    const getData = async () => {
      setOpenLoading(true);
      const response = await getPlacePerformanceByID(id_place!);
      setOpenLoading(false);

      if (response instanceof ErrorAPI) {
        if (response.statusCode == 401) {
          navigate('/logout');
        }

        if (response.statusCode == 500) {
          setSnackbarMessage(response.message);
          setSnackbarSeverity('warning');
          setSnackbarOpen(true);
        }
      } else {
        setPlacePerformance(response);
        setContent(response.content_editor!);
        setImageClassroomInfo(response.base_64_image!);
      }
    };

    getData();
  }, [id_place, navigate]);

  return (
    <DrawerProvider>
      <SideBar>
        <Header title="Edição de exibição das informações" />
        <div>
          <div style={{ margin: '1rem' }}>
            <div
              style={{
                margin: '1rem',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            ></div>

            <section>
              <Typography variant="h6" gutterBottom>
                Configure a Exibição das informações
              </Typography>
              <Divider />
              <CKEditor
                config={{
                  // @ts-ignore
                  extraPlugins: [uploadPlugin],
                }}
                editor={Editor}
                data={placePerformance.content_editor!}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onReady={(editor) => {}}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleEditorChangeContet(data);
                }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '2rem',
                  gap: '1rem',
                }}
              >
                <Button
                  disableElevation
                  onClick={handleSendUpadtePlace}
                  variant="contained"
                >
                  Atualizar
                </Button>
                <FileUploader
                  handleChange={handleFileBackgroud}
                  name="file"
                  multiple={false}
                  hoverTitle="Solte o arquivo aqui"
                  label="Carregue o arquivo aqui. A imagem deve ter dimensões de 1123x794."
                  types={['JPEG', 'PNG', 'GIF']}
                />
              </div>
            </section>
            {imageClassroomInfo && (
              <div
                id="container"
                ref={containerBackgroudRef}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  position: 'relative',
                  alignItems: 'flex-start',
                  backgroundImage: `url(${imageClassroomInfo})`,
                  backgroundSize: 'cover',
                  backgroundColor: 'black',
                  overflow: 'hidden',
                  width: '1123px',
                  height: '794px',
                  margin: '0 auto',
                }}
              >
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  {parse(content)}
                </div>
              </div>
            )}
          </div>
          <Loading open={openLoading} />
          <SnackBar
            open={snackBarOpen}
            type={snackbarSeverity}
            message={snackBarMessage}
            onClose={() => {
              setSnackbarOpen(false);
            }}
          />
        </div>
      </SideBar>
    </DrawerProvider>
  );
};

export default EditClassroomInfo;
