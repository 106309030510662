import { useAuth } from 'react-oidc-context';
import { Navigate } from 'react-router-dom';

const Login: React.FunctionComponent = () => {
  const { isAuthenticated, signinRedirect, removeUser } = useAuth();
  if (isAuthenticated) {
    return <Navigate to="/" />;
  } else {
    void removeUser();
    void signinRedirect();
    return <div>Carregando...</div>;
  }
};

export default Login;
