export default function convertToWhatsAppFormat(text: string) {
  // Remover a tag <p>
  text = text.replace(/<p>/g, '');
  text = text.replace(/<\/p>/g, '');
  // Remover tags <span>
  text = text.replace(/<span[^>]*>(.*?)<\/span>/g, '$1');
  // Substituir tags HTML por formatos do WhatsApp
  text = text.replace(/<strong>(.*?)<\/strong>/g, '*$1*'); // Negrito
  text = text.replace(/<i>(.*?)<\/i>/g, '_$1_'); // Itálico
  text = text.replace(/<s>(.*?)<\/s>/g, '~$1~'); // Riscado
  text = text.replace(/<u>(.*?)<\/u>/g, '_$1_'); // Sublinhado (sem suporte direto no WhatsApp, usando itálico)

  // Substituir quebras de linha
  text = text.replace(/<br\s*[/]?>/gi, '\n');

  // Substituir &nbsp; por duas quebras de linha
  text = text.replace(/&nbsp;/g, '\n\n');

  // Substituir listas não ordenadas e itens de lista
  text = text.replace(/<ul>/g, '');
  text = text.replace(/<\/ul>/g, '');
  text = text.replace(/<li>(.*?)<\/li>/g, '- $1\n');

  // Substituir listas ordenadas e itens de lista
  text = text.replace(/<ol>/g, '');
  text = text.replace(/<\/ol>/g, '');
  let olIndex = 0;
  text = text.replace(/<li>(.*?)<\/li>/g, (match, p1) => {
    olIndex++;
    return `${olIndex}. ${p1}\n`;
  });

  return text;
}
