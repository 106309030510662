/* eslint-disable @typescript-eslint/no-unused-vars */
// External Libraries
import React, { useCallback, useState, useEffect } from 'react';
import { MobileStepper } from '@mui/material';
// eslint-disable-next-line
import { DateRange } from 'rsuite/esm/DateRangePicker';

// Components
import SideBar from '../../components/Drawer';
import Header from '../../components/Header';
import TextButton from '../../components/Button';
import DefinedCheckinShifts from '../../components/DefineCheckinShifts';
import SnackBar from '../../components/SnackBar';
import DefinedCheckinCertificate from '../../components/DefinedCheckinCertificate';
import SearchClassroom from './SearchClassroom';
import { useNavigate, useParams } from 'react-router-dom';
// Contexts
import { DrawerProvider } from '../../contexts';

// Custom Hooks
import {
  ICheckinCertificate,
  IClassroom,
  IClassroomCertificate,
  IEventDate,
  ISearchSOL,
  IShift,
  IShiftsWithDate,
  ISupplier,
} from '../../../core/interfaces';

// Utility Functions
import { isEmpty } from '../../../core/utils';

// Pages
import SupplierAvailable from '../SupplierAvailable';
import QRCodePage from '../QRCodePage';
import DefineCertificate from './DefinedCertificate';

// Styling
import 'rsuite/dist/rsuite.min.css';
import { getByIDClassroomIntegraTemp } from '../../../service/ClassroomService';
import ErrorAPI from '../../../core/models/ErrorAPI';

export default function Generate() {
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const [activeStep, setActiveStep] = useState<number>(0);
  const [classroom, setClassroom] = useState<IClassroom>({} as IClassroom);
  const [disabledNextButton, setdisabledNextButton] = useState<boolean>(true);
  const [initTableData, setInitTableData] = useState<IShiftsWithDate[]>([]);
  const [initShiftName, setInitShiftNames] = useState<string[]>([]);
  const [initShiftsActives, setInitShiftsActives] = useState<IShift[]>(
    classroom.shifts,
  );
  const [initDate, setInitDate] = useState<DateRange>([
    new Date(),
    new Date(),
  ] as DateRange);
  const navigate = useNavigate();
  const [isChildValue, setIsChildValue] = useState(true);
  const [isTypeAprove, setIsTypeAprove] = useState<boolean>(false);
  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };
  const { id_class = '/' } = useParams<'id_class'>();

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const updateArraySupplier = (item: ISupplier[]) => {
    classroom.suppliers = item;
  };

  const handlerSupplier = (value: object[]) => {
    classroom.suppliersSelect = value as ISupplier[];
    const hasInvalidCpf = classroom.suppliersSelect.some(
      (supplier) => !supplier.cpf || supplier.cpf.trim() === '',
    );

    if (hasInvalidCpf) {
      setSnackbarOpen(true);
      setSnackbarMessage(
        'Esse usuário não possui CPF. Por favor, insira essa informação no campo indicado.',
      );
      setSnackbarSeverity('info');
    }

    if (hasInvalidCpf || classroom.suppliersSelect.length === 0) {
      setdisabledNextButton(true);
    } else {
      setdisabledNextButton(false);
    }
  };

  const handleEventCheckin = (
    data: IEventDate,
    shiftName: string[],
    shiftsActives: IShift[],
    date: DateRange,
  ) => {
    setdisabledNextButton(false);
    if (data.tableData.length === 0) {
      setdisabledNextButton(true);
    }
    const totalCheckin = data.tableData.reduce(
      (total, item) => total + Number(item.number_checkin), //isso está sendo feito pq ao editar o number_checkin vira string TO-DO resolver isso
      0,
    );
    setInitDate(date);
    setInitShiftsActives(shiftsActives);
    setInitShiftNames(shiftName);
    setInitTableData(data.tableData);
    classroom.shifts = shiftsActives;
    classroom.event_date = data as IEventDate;
    classroom.checkins_required = totalCheckin;
  };

  const handleOnchageRangeNumber = (value: ICheckinCertificate[]) => {
    setdisabledNextButton(false);

    const hasApto = value.some(
      (item) => item.aprove === 'Apto para o certificado',
    );

    const isButtonDisabled = !hasApto;
    setIsTypeAprove(isButtonDisabled);
    setdisabledNextButton(isButtonDisabled);
    if (value.length === 0) {
      setdisabledNextButton(true);
    }

    classroom.checkin_certificate = value;
  };

  useEffect(() => {
    if (activeStep === 0) {
      isEmpty(classroom)
        ? setdisabledNextButton(true)
        : setdisabledNextButton(false);

      if (
        !classroom.unit_origin ||
        !classroom.sebrae_origin ||
        !classroom.place_performance
      ) {
        setdisabledNextButton(true);
      } else {
        setdisabledNextButton(false);
      }
    }

    if (activeStep === 1) {
      classroom.event_date?.tableData.length === 0
        ? setdisabledNextButton(true)
        : setdisabledNextButton(false);
    }
    if (activeStep === 2) {
      setdisabledNextButton(false);
      if (classroom?.checkin_certificate?.length === 0) {
        setdisabledNextButton(true);
      }

      if (isTypeAprove) {
        setdisabledNextButton(true);
      }
    }
    if (classroom?.require_certificate) {
      if (activeStep === 3) {
        setdisabledNextButton(false);
        if (
          classroom?.certificate === null ||
          (classroom?.solution_name === undefined &&
            classroom?.solution_code === undefined)
        ) {
          setdisabledNextButton(true);
        }
      }
    }

    if (activeStep === 4) {
      setdisabledNextButton(false);
      if (classroom?.suppliersSelect?.length === 0) {
        setdisabledNextButton(true);
      }
    }

    if (activeStep === 5) {
      setdisabledNextButton(true);
    }
  }, [activeStep, classroom, classroom.shifts, classroom.certificate]);

  const handleOnchageSearch = (value: IClassroom) => {
    if (!value.unit_origin || !value.sebrae_origin) {
      setdisabledNextButton(true);
    } else {
      setdisabledNextButton(false);
    }

    if (!value.place_performance) {
      setdisabledNextButton(true);
    } else {
      setdisabledNextButton(false);
    }
    setClassroom(value);
  };

  const handleOnChageCertificate = (
    certicate: IClassroomCertificate | null,
  ) => {
    if (classroom?.require_certificate) {
      classroom.certificate = certicate;
      setdisabledNextButton(false);
      if (
        classroom?.certificate === null ||
        (classroom?.solution_name === undefined &&
          classroom?.solution_code === undefined)
      ) {
        setdisabledNextButton(true);
      }
    }
  };

  const getSolutionFromProposal = (solution: ISearchSOL) => {
    classroom.solution_code = solution.id;
    classroom.solution_name = solution.name;
    if (
      classroom?.certificate === null &&
      classroom?.solution_name === undefined &&
      classroom?.solution_code === undefined
    ) {
      setdisabledNextButton(true);
    }
  };

  const steps: Array<React.ReactNode> = [
    <>
      <SearchClassroom
        initClassroom={classroom}
        onChange={handleOnchageSearch}
      />
    </>,
    <DefinedCheckinShifts
      shifts={classroom.shifts}
      initData={initTableData}
      initShiftsNames={initShiftName}
      initShiftsActives={initShiftsActives}
      initDates={initDate}
      onChange={handleEventCheckin}
      onValueChange={(value) => {
        setIsChildValue(value);
      }}
      boolIsNull={isChildValue}
    />,
    <DefinedCheckinCertificate
      amountCheckin={classroom.checkins_required}
      eventDuration={classroom.InitialDateClassroom}
      initData={
        classroom.checkin_certificate ? classroom.checkin_certificate : []
      }
      onChange={handleOnchageRangeNumber}
    />,
    <DefineCertificate
      checkedSelect={classroom.certificate ? classroom.certificate.uuid : ''}
      defaultMethodology={
        classroom.sol_methodology ? classroom.sol_methodology : ''
      }
      selectedCertificateCallBack={handleOnChageCertificate}
      selectedCertificatePersonalize={(html_back) => {
        if (classroom.certificate) {
          classroom.certificate.html_back_personalize = html_back;
        }
      }}
      classroom={classroom}
      origin={classroom.from_origin ? classroom.from_origin : 'INTEGRA'}
      solutionSelect={getSolutionFromProposal}
      defaultValueSolutionSearch={classroom.solution_name}
    />,
    <>
      <SupplierAvailable
        updateArray={updateArraySupplier}
        suppliers={classroom.suppliers}
        supplierSelects={classroom.suppliersSelect}
        getValue={handlerSupplier}
      />
    </>,
    <>
      <QRCodePage value={classroom} />
    </>,
  ];
  return (
    <>
      <DrawerProvider>
        <SideBar>
          <Header title="Gerar Evento" />
          <MobileStepper
            variant="progress"
            steps={6}
            position="static"
            activeStep={activeStep}
            nextButton={
              <TextButton
                label="Próximo"
                variant="contained"
                disabled={disabledNextButton}
                onClick={handleNext}
              />
            }
            backButton={
              <TextButton
                label="Voltar"
                variant="contained"
                disabled={activeStep === 0 || activeStep === 5}
                onClick={handleBack}
              />
            }
          />

          {steps[activeStep]}
          <SnackBar
            open={snackBarOpen}
            type={snackbarSeverity}
            message={snackBarMessage}
            onClose={handleCloseSnackBar}
          />
        </SideBar>
      </DrawerProvider>
    </>
  );
}
