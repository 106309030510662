/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import { Box, Paper, Typography, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import style from './style.module.css';
import { FileWithPreview } from '../../../core/types/TFilesWithPreview';
import { imageExtensions } from '../../../core/utils';
import { useParams, useNavigate } from 'react-router-dom';
import { classDeleteEventProof } from '../../../service/ClassroomService';
import ErrorAPI from '../../../core/models/ErrorAPI';
import Loading from '../Loading';
import SnackBar from '../SnackBar';
import linkToFile from '../../../core/utils/linkToFile';

interface IPropsDropFile {
  onChangeFiles: (files: File[]) => void;
  defaultsFiles?: FileWithPreview[];
}

const DropFileOrPhoto: React.FC<IPropsDropFile> = ({
  onChangeFiles,
  defaultsFiles,
}) => {
  const [dragOver, setDragOver] = useState(false);
  const [files, setFiles] = useState<FileWithPreview[] | undefined>(
    defaultsFiles,
  );
  const { id_class = '/' } = useParams<'id_class'>();
  const navigate = useNavigate();
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const [openLoading, setOpenLoading] = useState<boolean>(false);

  const handleGetValuesSnack = (
    value: boolean,
    message: string,
    severity: string,
  ) => {
    setSnackbarOpen(value);
    setSnackbarSeverity(severity as 'success' | 'info' | 'warning' | 'error');
    setSnackbarMessage(message);
  };

  useEffect(() => {
    setFiles(
      defaultsFiles?.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
  }, [defaultsFiles]);

  const handleDragOver = useCallback(() => {
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setDragOver(false);
  }, []);

  const handleOnDrop = <T extends File>(
    acceptedFiles: T[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fileRejections: FileRejection[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    event: DropEvent,
  ) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
    onChangeFiles(acceptedFiles);
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files?.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  const handleDeleteFile = async (file_id: string) => {
    setOpenLoading(true);
    const response = await classDeleteEventProof(id_class, file_id);
    setOpenLoading(false);
    if (response instanceof ErrorAPI) {
      if (response.statusCode === 401) {
        navigate('/logout');
      }

      if (response.statusCode === 400) {
        handleGetValuesSnack(
          true,
          'Não foi possível deletar o arquivo no momento.',
          'warning',
        );
      }

      if (response.statusCode === 404) {
        handleGetValuesSnack(
          true,
          'Não foi possível deletar o arquivo no momento.',
          'warning',
        );
      }
      if (response.statusCode === 500) {
        handleGetValuesSnack(
          true,
          'Algo deu errado. Por favor, tente novamente mais tarde.',
          'warning',
        );
      }

      if (response.statusCode === 503) {
        handleGetValuesSnack(
          true,
          'Algo deu errado. Por favor, tente novamente mais tarde.',
          'warning',
        );
      }
    } else {
      handleGetValuesSnack(true, 'Arquivo deletado com sucesso', 'success');
      const filesPromises = response.map(async (value) => {
        return await linkToFile(value.path_file, value.id);
      });

      const files = await Promise.all(filesPromises);
      setFiles(
        files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    }
  };
  const thumbs = files?.map((file, index) => (
    <div className={style.thumb} key={index}>
      <div className={style.thumbInner}>
        <img
          src={file.preview}
          className={style.img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
        <div
          onClick={() => {
            handleDeleteFile(file.name);
          }}
          className={style.overlay}
        >
          X
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <Dropzone accept={{ imageExtensions }} onDrop={handleOnDrop}>
        {({ getRootProps, getInputProps }) => (
          <Box>
            <Paper
              {...getRootProps()}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              variant="outlined"
              style={{
                border: dragOver ? '2px dashed #000' : '2px dashed #aaa',
                padding: 20,
                textAlign: 'center',
                cursor: 'pointer',
                background: dragOver ? '#eee' : '#fafafa',
              }}
            >
              <input {...getInputProps()} />
              <label htmlFor="raised-button-file">
                <Box display="flex" flexDirection="column" alignItems="center">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <CloudUploadIcon style={{ fontSize: 60 }} />
                  </IconButton>
                  <Typography>
                    Arraste e solte os arquivos aqui ou clique para selecionar
                    arquivos.
                  </Typography>
                </Box>
              </label>
            </Paper>
            <aside className={style.thumbsContainer}>{thumbs}</aside>
          </Box>
        )}
      </Dropzone>
      <div>
        <Loading open={openLoading} />
        <SnackBar
          open={snackBarOpen}
          type={snackbarSeverity}
          message={snackBarMessage}
          onClose={() => {
            setSnackbarOpen(false);
          }}
        />
      </div>
    </>
  );
};

export default DropFileOrPhoto;
