/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { DrawerProvider } from '../../../contexts';
import SideBar from '../../../components/Drawer';
import Header from '../../../components/Header';

import {
  getAllMessage,
  updateMessage,
} from '../../../../service/MessageDefaultService';
import ErrorAPI from '../../../../core/models/ErrorAPI';
import { useNavigate } from 'react-router-dom';
import { IMessageDefault } from '../../../../core/interfaces';
import MessageItem from './MessageItem';
import Loading from '../../../components/Loading';


type Description = {
  title: string,
  description: string,
}

type DescriptionWithTitle = {
  [key: string]: Description,
}

const EditDefaultMessages: React.FC = () => {
  const [messages, setMessages] = useState<IMessageDefault[]>([]);
  const navigate = useNavigate();
  const [openLoading, setOpenLoading] = useState<boolean>(true);

  const descriptionWithTitle: DescriptionWithTitle = {
    'qrcode': {title: "QR Code", description: "A mensagem que o cliente receberá ao completar o fluxo para obter o seu QR Code."},
    'scan_qrcode': {title: "Scan QR Code", description: "Após a digitalização do QR Code, esta mensagem será enviada para o cliente."},
    'calendar': {title: "Calendário", description: "A mensagem que o usuário receberá ao ser inscrito em uma turma onde ele não estava previamente presente e receber o link do calendário."},
    'certificate': {title: "Certificado", description: "A mensagem que o usuário receberá junto com o seu certificado de conclusão."},
  };

  useEffect(() => {
    async function searchDataMessageAPI() {
      const messageDefault = await getAllMessage();

      if (messageDefault instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setMessages(messageDefault);
        setOpenLoading(false)
      }
    }
    searchDataMessageAPI();
  }, []);


  return (
    <DrawerProvider>
      <SideBar>
        <Header title="Mensagem de Envio" />
        <Loading open={openLoading} />
        <Box sx={{ width: '100%' }}>
          <Stack spacing={2}>
            {messages.map((value, index) => {
              return (
                <MessageItem
                  id={value.uuid}
                  data={value.message_email}
                  description={descriptionWithTitle[value.message_type]?.description || ''}
                  messageType={value.message_type}
                  title={descriptionWithTitle[value.message_type]?.title || ''}
                  onTextChange={() => {}}
                  key={index}
                />
              );
            })}
          </Stack>
        </Box>
      </SideBar>
    </DrawerProvider>
  );
};

export default EditDefaultMessages;
