import { TFilterClassroom } from '../../../core/types';
import FilterPerCode from './FilterPerCode';
import FilterPerDate from './FilterPerDate';
import FilterPerStatus from './FilterPerStatus';

interface IPropsFilterClassroom {
  typeFilter: TFilterClassroom;
  getValueWithType: (value: string, typeFilter: TFilterClassroom) => void;
}
const FilterClassroom: React.FC<IPropsFilterClassroom> = ({
  typeFilter,
  getValueWithType,
}) => {
  const componentFilter = {
    code: (
      <FilterPerCode
        getValue={(value) => {
          getValueWithType(value, typeFilter);
        }}
      />
    ),
    date: (
      <FilterPerDate
        getValue={(value) => {
          getValueWithType(value, typeFilter);
        }}
      />
    ),
    status: (
      <FilterPerStatus
        getValue={(value) => {
          getValueWithType(value, typeFilter);
        }}
      />
    ),
  }[typeFilter];

  return <>{componentFilter}</>;
};

export default FilterClassroom;
