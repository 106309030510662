/* eslint-disable prefer-const */
import { AxiosError } from 'axios';
import { IPlacePerformance } from '../../core/interfaces';
import { get, patch, post } from '../adapters/xhr';
import ErrorAPI from '../../core/models/ErrorAPI';

export const PlacePerformanceAPI = () => ({
  createPlacePerformance: async (data: IPlacePerformance) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      const response = await post(
        `${BaseURL}/api/v1/place/performance/create`,
        data,
      );
      const PlacePerformaceResponse = response?.data as IPlacePerformance[];

      if (response.status !== 200) {
        return [] as Array<IPlacePerformance>;
      }
      return PlacePerformaceResponse;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 403:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }

        return errorAPI;
      }
      return [] as IPlacePerformance[];
    }
  },
  getAllPlacePerformance: async () => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      const response = await get(`${BaseURL}/api/v1/place/performance/get/all`);
      const PlacePerformaceResponse = response?.data as IPlacePerformance[];

      if (response.status !== 200) {
        return [] as Array<IPlacePerformance>;
      }
      return PlacePerformaceResponse;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 403:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }

        return errorAPI;
      }
      return [] as IPlacePerformance[];
    }
  },
  deletePlacePerformance: async (id: string) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      const response = await patch(
        `${BaseURL}/api/v1/place/performance/delete/by/${id}`,
      );
      const PlacePerformaceResponse = response?.data as IPlacePerformance[];

      if (response.status !== 200) {
        return [] as Array<IPlacePerformance>;
      }
      return PlacePerformaceResponse;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 403:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }

        return errorAPI;
      }
      return [] as IPlacePerformance[];
    }
  },
  getPlacePerformanceCurrentClassroom: async (id: string) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      const response = await get(
        `${BaseURL}/api/v1/place/performance/current/classroom/by/${id}`,
      );
      const PlacePerformaceResponse = response?.data as IPlacePerformance;

      if (response.status !== 200) {
        return {} as IPlacePerformance;
      }
      return PlacePerformaceResponse;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 403:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }

        return errorAPI;
      }
      return {} as IPlacePerformance;
    }
  },
  getPlacePerformanceByID: async (id: string) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      const response = await get(
        `${BaseURL}/api/v1/place/performance/get/by/${id}`,
      );
      const PlacePerformaceResponse = response?.data as IPlacePerformance;

      if (response.status !== 200) {
        return {} as IPlacePerformance;
      }
      return PlacePerformaceResponse;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 403:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }

        return errorAPI;
      }
      return {} as IPlacePerformance;
    }
  },
  placePerformanceUpdateByID: async (id: string, data: object) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      const response = await patch(
        `${BaseURL}/api/v1/place/performance/edit/by/${id}`,
        data,
      );
      const PlacePerformaceResponse = response?.data as IPlacePerformance;

      if (response.status !== 200) {
        return {} as IPlacePerformance;
      }
      return PlacePerformaceResponse;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 403:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }

        return errorAPI;
      }
      return {} as IPlacePerformance;
    }
  },
});
