/* eslint-disable @typescript-eslint/no-unused-vars */
import HeaderDescription from '../../components/HeaderDescription';
import { ISupplier } from '../../../core/interfaces';
import {
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Button,
  Stack,
} from '@mui/material';
import { useEffect, useState } from 'react';
import style from './style.module.css';
import DBGrid from '../../components/Grid';
import Checkbox from '@mui/material/Checkbox';
import SearchSupplier from './SearchSupplier';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
interface IPropsSupplierAvailable {
  suppliers: ISupplier[];
  supplierSelects: ISupplier[];
  updateArray: (value: ISupplier[]) => void;
  getValue?: (value: Array<object>) => void;
}

const SupplierAvailable: React.FunctionComponent<IPropsSupplierAvailable> = ({
  suppliers,
  supplierSelects,
  updateArray,
  getValue,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedItems, setSelectedItems] = useState<ISupplier[]>([]);
  const [typeSearch, setTypeSearch] = useState<'ADD' | 'UPDATE'>('UPDATE');
  const isMobile = screenWidth <= 700;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [rows, setRows] = useState<ISupplier[]>(
    suppliers.map((item: ISupplier) => {
      return {
        id: item.id,
        uuid: item.uuid,
        name: item.name,
        cpf: item.cpf,
        company: item.company,
        cnpj_empresa: item.cnpj_empresa,
        phone: item.phone,
        email: item.email,
      };
    }),
  );

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      if (getValue) {
        getValue(selectedItems);
      }
    }
  }, [selectedItems, getValue, isMobile]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'uuid', headerName: 'UUID', width: 50 },
    { field: 'name', headerName: 'Nome', width: 245 },
    { field: 'cpf', headerName: 'CPF', width: 130, editable: true },
    { field: 'company', headerName: 'Empresa', width: 300 },
    { field: 'cnpj_empresa', headerName: 'CNPJ', width: 150 },
    { field: 'phone', headerName: 'Telefone', width: 130 },
    { field: 'email', headerName: 'E-mail', width: 245 },
  ];

  const handleCardSelect = (item: ISupplier) => {
    const updatedItems = [...selectedItems]; // Criar uma cópia dos itens

    const index = updatedItems.findIndex((value) => value.id === item.id);
    const hasIncompleteData = item.name && item.cpf === null;

    if (index !== -1) {
      updatedItems[index] = item;
    } else {
      if (hasIncompleteData) {
        setOpenDialog(true);
        setTypeSearch('UPDATE');
      }
      updatedItems.push(item);
    }
    setSelectedItems(updatedItems);
  };

  const handleNewValueSelect = (value: object[]) => {
    const supplierSelect = value as ISupplier[];

    // Verifica se algum item está sem o CPF
    const itemWithoutCPF = supplierSelect.find(
      (supplier) => !supplier.cpf || supplier.cpf.trim() === '',
    );

    if (itemWithoutCPF) {
      const isSelected = selectedItems.some(
        (selectedItem) => selectedItem.id === itemWithoutCPF.id,
      );
      if (isSelected) {
        const updatedSelection = selectedItems.filter(
          (selectedItem) => selectedItem.id !== itemWithoutCPF.id,
        );
        setSelectedItems(updatedSelection);
      } else {
        setSelectedItems([...selectedItems, itemWithoutCPF]);
      }
      setOpenDialog(true);
      setTypeSearch('UPDATE');
    }
  };

  const newItem = (
    allItems: ISupplier[],
    selectedItems: ISupplier[],
    itemUpdate: ISupplier,
  ): Promise<ISupplier[]> => {
    return new Promise((resolve, reject) => {
      if (itemUpdate) {
        const updatedItems = [...allItems]; // Criar uma cópia dos itens
        selectedItems.forEach((selectedItem) => {
          const index = updatedItems.findIndex(
            (item) =>
              item.name === selectedItem.name || item.id === selectedItem.id,
          );
          if (index !== -1) {
            const hasIncompleteData =
              selectedItem.name && selectedItem.cpf === null;

            if (hasIncompleteData) {
              updatedItems[index] = { ...itemUpdate, id: selectedItem.id };
            }
          } else {
            updatedItems.push({ ...itemUpdate, id: selectedItem.id });
          }
        });

        resolve(updatedItems);
      } else {
        reject([]);
      }
    });
  };

  const handleGetNewSupplier = async (item: ISupplier) => {
    if (typeSearch === 'UPDATE') {
      const result = await newItem(rows, selectedItems, item);
      updateArray(result);
      setRows(result);
    } else if (typeSearch === 'ADD') {
      const updatedItems = [...rows]; // Criar uma cópia dos itens
      const hasMatchingCPF = updatedItems.some(
        (supplier) => supplier.cpf === item.cpf,
      );
      if (!hasMatchingCPF) {
        updatedItems.push(item);
      }
      updateArray(updatedItems);
      setRows(updatedItems);
    }
    setOpenDialog(false);
  };

  return (
    <>
      <HeaderDescription
        title="Fornecedor disponiveis para o tipo de solução"
        subtitle="Selecione o Fornecedor(es) que desejar"
      />
      <Stack
        direction={'row'}
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
        margin={'1rem'}
        sx={{ margin: '1rem' }}
      >
        <Button
          variant="contained"
          startIcon={<PersonAddIcon />}
          onClick={() => {
            setOpenDialog(true);
            setTypeSearch('ADD');
          }}
        >
          Adicionar fornecedor de forma manual
        </Button>
      </Stack>
      <SearchSupplier
        onClose={() => {
          setOpenDialog(false);
        }}
        getValue={handleGetNewSupplier}
        openDialogSupplier={openDialog}
      />
      <div className={style.listCards}>
        {isMobile ? (
          rows.map((item) => (
            <Card
              key={item.id}
              sx={{ marginBottom: 1 }}
              className={style.cardTable}
            >
              <CardContent className={style.cardTableContent}>
                <List dense={true} className={style.styleTableList}>
                  <ListItem disablePadding>
                    <span id={style.spanCheckBox}>Selecione o Fornecedor </span>
                    <Checkbox
                      id={style.checkBoxStyle}
                      defaultChecked={false}
                      onChange={() => {
                        handleCardSelect(item);
                      }}
                    />
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemText primary="Nome" secondary={item.name} />
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemText primary="CPF" secondary={item.cpf} />
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemText
                      primary="CNPJ"
                      secondary={item.cnpj_empresa}
                    />
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemText primary="Telefone" secondary={item.phone} />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          ))
        ) : (
          <DBGrid
            columns={columns}
            rows={rows}
            loading={false}
            checkboxSelection={true}
            selectedValue={getValue} //esse é para o callback
            selectedNewValue={handleNewValueSelect} //esse é para o dialog ser aberto
            defaultValue={supplierSelects}
            columnVisibilityModel={{ id: false, uuid: false }}
            className={style.gridTable}
          />
        )}
      </div>
    </>
  );
};

export default SupplierAvailable;
