import Main from './main';
import * as locales from 'rsuite/locales';
import { CustomProvider } from 'rsuite';

function App() {
  return (
    <>
      <CustomProvider locale={locales.ptBR}>
        <Main />
      </CustomProvider>
    </>
  );
}

export default App;
