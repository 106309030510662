import { IUser } from '../../../../core/interfaces';
import style from '../style.module.css';
import {
  Card,
  CardContent,
  Checkbox,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

interface IPropsCardMobile {
  dataAPI: IUser[];
  setSelectedItems: (obj: object[]) => void;
}

const CardMobile: React.FC<IPropsCardMobile> = ({
  dataAPI,
  setSelectedItems,
}) => {
  return (
    <div className={style.listCards}>
      {dataAPI.length > 0 &&
        dataAPI.map((item) => (
          <Card
            key={item.id}
            sx={{ marginBottom: 1 }}
            className={style.cardTable}
          >
            <CardContent className={style.cardTableContent}>
              <List dense={true} className={style.styleTableList}>
                <ListItem disablePadding>
                  <span id={style.spanCheckBox}>Selecione o Fornecedor </span>
                  <Checkbox
                    id={style.checkBoxStyle}
                    defaultChecked={false}
                    onChange={() => {
                      setSelectedItems([item]);
                    }}
                  />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText primary="ID" secondary={item.id} />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText
                    primary="Primeiro nome"
                    secondary={item.first_name}
                  />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText
                    primary="Ultimo nome"
                    secondary={item.last_name}
                  />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText primary="Documento" secondary={item.cpf} />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        ))}
    </div>
  );
};

export default CardMobile;
