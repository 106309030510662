import { AuthProviderProps } from 'react-oidc-context';

const oidcConfig: AuthProviderProps = {
  authority: `${process.env.REACT_APP_BASE_URL_KEYCLOAK}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}`,
  client_id: process.env.REACT_APP_CLIENT_ID_KEYCLOAK ?? '',
  client_secret: process.env.REACT_APP_SECRET_KEYCLOAK ?? '',
  scope: 'openid',
  response_type: 'code',
  onSigninCallback() {
    localStorage.clear();
    const urlWithoutParams = window.location.origin + window.location.pathname;
    window.history.replaceState({}, document.title, urlWithoutParams);
  },
  redirect_uri: process.env.REACT_APP_REDIRECT_URI ?? '',
  onRemoveUser() {
    const urlWithoutParams = window.location.origin + window.location.pathname;
    window.history.replaceState({}, document.title, urlWithoutParams);
  },
};

export default oidcConfig;
