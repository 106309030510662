/* eslint-disable prefer-const */
import { AxiosError } from 'axios';
import { ISebrae } from '../../core/interfaces';
import { get } from '../adapters/xhr';
import ErrorAPI from '../../core/models/ErrorAPI';

export const SebraesAPI = () => ({
  getAllSebrae: async () => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      const response = await get(`${BaseURL}/api/v1/search/sebraes`);
      const sebraesResponse = response?.data as ISebrae[];

      if (response.status !== 200) {
        return [] as Array<ISebrae>;
      }
      return sebraesResponse;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 403:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }

        return errorAPI;
      }
      return [] as ISebrae[];
    }
  },
});
