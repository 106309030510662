import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  CircularProgress,
  SelectChangeEvent,
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { getAllsebrae } from '../../../service/SebraeService';
import ErrorAPI from '../../../core/models/ErrorAPI';
import SnackBar from '../../components/SnackBar';
import { useNavigate } from 'react-router-dom';
import { getAllunit } from '../../../service/UnitService';
import { ISebrae, IUnit } from '../../../core/interfaces';
import {
  FilterData,
  FilterType,
} from '../../../core/types/TFilterTypeCalendar';

interface FilterComponentProps {
  onFilterChange: (filterData: FilterData) => void;
}

const FilterToolbar: React.FC<FilterComponentProps> = ({ onFilterChange }) => {
  const [filterType, setFilterType] = useState<FilterType>('todos');
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [sebraes, setSebraes] = useState<ISebrae[]>([]);
  const [units, setUnits] = useState<IUnit[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');

  const handleGetValuesSnack = (
    value: boolean,
    message: string,
    severity: string,
  ) => {
    setSnackbarOpen(value);
    setSnackbarSeverity(severity as 'success' | 'info' | 'warning' | 'error');
    setSnackbarMessage(message);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const responseSebraes = await getAllsebrae();
      if (responseSebraes instanceof ErrorAPI) {
        if (responseSebraes.statusCode === 401) {
          navigate('/logout');
        }

        if (
          responseSebraes.statusCode === 400 ||
          responseSebraes.statusCode === 404 ||
          responseSebraes.statusCode === 500
        ) {
          handleGetValuesSnack(
            true,
            'Não foi possível carregar filtros',
            'warning',
          );
        }
      } else {
        setSebraes(responseSebraes);
        setUnits([]);
      }
      const responseUnits = await getAllunit();
      setLoading(false);
      if (responseUnits instanceof ErrorAPI) {
        if (responseUnits.statusCode === 401) {
          navigate('/logout');
        }

        if (
          responseUnits.statusCode === 400 ||
          responseUnits.statusCode === 404 ||
          responseUnits.statusCode === 500
        ) {
          handleGetValuesSnack(
            true,
            'Não foi possível carregar filtros',
            'warning',
          );
        }
      } else {
        setUnits(responseUnits);
      }
    };
    getData();
  }, []);

  const handleFilterTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const newFilterType = event.target.value as FilterType;
    setFilterType(newFilterType);
    setSelectedValue('');

    if (newFilterType === 'todos') {
      onFilterChange({ type: newFilterType, value: '' });
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    const value = event.target.value;
    setSelectedValue(value);
    onFilterChange({ type: filterType, value });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        maxWidth: 500,
        margin: 'auto',
      }}
    >
      <FormControl component="fieldset">
        <FormLabel component="legend">Tipo de Filtro</FormLabel>
        <RadioGroup
          aria-label="filter type"
          name="filter-type-group"
          value={filterType}
          onChange={handleFilterTypeChange}
          row
          sx={{ justifyContent: 'space-between' }}
        >
          <FormControlLabel value="todos" control={<Radio />} label="Todos" />
          <FormControlLabel
            value="regional"
            control={<Radio />}
            label="Regional"
          />
          <FormControlLabel
            value="unidade"
            control={<Radio />}
            label="Unidade"
          />
        </RadioGroup>
      </FormControl>

      <AnimatePresence mode="wait">
        {filterType !== 'todos' && (
          <motion.div
            key={filterType}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
          >
            <FormControl fullWidth>
              <InputLabel id={`${filterType}-select-label`}>
                {filterType === 'regional' ? 'Regional' : 'Unidade'}
              </InputLabel>
              <Select
                labelId={`${filterType}-select-label`}
                id={`${filterType}-select`}
                value={selectedValue}
                label={filterType === 'regional' ? 'Regional' : 'Unidade'}
                onChange={handleSelectChange}
                disabled={loading}
              >
                {loading ? (
                  <MenuItem value="">
                    <CircularProgress size={24} />
                  </MenuItem>
                ) : (
                  (filterType === 'regional' ? sebraes : units).map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </motion.div>
        )}
      </AnimatePresence>
      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
    </Box>
  );
};

export default FilterToolbar;
