import React, { useState } from 'react';
import convertToWhatsAppFormat from './utils/convertToWhatsAppFormat';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

interface TextEditorProps {
  onTextChange: (formattedText: string, htmlText: string) => void;
  data?: string;
}

const TextEditor: React.FC<TextEditorProps> = ({ onTextChange, data = '' }) => {
  const [code, setCode] = useState(data);

  const handleProcedureContentChange = (content: string) => {
    const text = convertToWhatsAppFormat(content);

    setCode(content);
    if (onTextChange) {
      onTextChange(text, content); // Pass formatted text to the parent component
    }
  };
  return (
    <>
      <CKEditor
        editor={Editor}
        data={code}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onReady={(editor) => {}}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleProcedureContentChange(data);
        }}
      />
    </>
  );
};

export default TextEditor;
