import { ICheckinRequest } from '../core/interfaces';
import { studentAPI } from '../infra/api/StudentAPI';

export async function searchStudents(uuidClassroom: string) {
  const instaceInfra = studentAPI();
  const students = await instaceInfra.searchStudents(uuidClassroom);

  return students;
}

export async function checkinStudents(checkin: ICheckinRequest) {
  const instaceInfra = studentAPI();
  const apiDAta = await instaceInfra.checkinStudents(checkin);

  return apiDAta;
}

export async function searchUserForDocument(
  document: string,
  typeDocument: string,
) {
  const instaceInfra = studentAPI();
  const companies = await instaceInfra.searchUserForDocument(
    document,
    typeDocument,
  );

  return companies;
}

export async function studentUpdate(
  student_id: string | undefined,
  data: object,
) {
  const instaceInfra = studentAPI();
  const statusCode = await instaceInfra.studentUpdate(student_id, data);

  return statusCode;
}

export async function studentInfo(data: object) {
  const instaceInfra = studentAPI();
  const student = await instaceInfra.studentInfo(data);

  return student;
}

export async function sendPhoneQRCode(
  data: object,
  id_student?: string,
  id_classroom?: string,
) {
  const instaceInfra = studentAPI();
  const student = await instaceInfra.sendPhoneQRCode(
    data,
    id_student,
    id_classroom,
  );

  return student;
}

export async function sendEmailQRCode(
  id_student?: string,
  id_classroom?: string,
) {
  const instaceInfra = studentAPI();
  const student = await instaceInfra.sendEmailQRCode(id_student, id_classroom);

  return student;
}

export async function getCertificateStudent(id_certificate: string) {
  const instaceInfra = studentAPI();
  const certificiate = await instaceInfra.getCertificateStudent(id_certificate);

  return certificiate;
}

export async function sendCertificate(
  id_student?: string,
  id_classroom?: string,
) {
  const instaceInfra = studentAPI();
  const student = await instaceInfra.sendCertificate(id_student, id_classroom);

  return student;
}

export async function getCertificatesFromCPFAPI(cpf: string) {
  const data = {
    cpf: cpf,
  };
  const instaceInfra = studentAPI();
  const student = await instaceInfra.getCertificatesFromCPF(data);

  return student;
}
