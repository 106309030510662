import { TFilterCompositeEvent } from '../../../core/types';
import FilterPerName from './FilterPerName';
import FilterPerDate from './FilterPerDate';
import FilterPerStatus from './FilterPerStatus';

interface IPropsFilterCompositeEvent {
  typeFilter: TFilterCompositeEvent;
  getValueWithType: (value: string, typeFilter: TFilterCompositeEvent) => void;
}
const FilterCompositeEvent: React.FC<IPropsFilterCompositeEvent> = ({
  typeFilter,
  getValueWithType,
}) => {
  const componentFilter = {
    name: (
      <FilterPerName
        getValue={(value) => {
          getValueWithType(value, typeFilter);
        }}
      />
    ),
    date: (
      <FilterPerDate
        getValue={(value) => {
          getValueWithType(value, typeFilter);
        }}
      />
    ),
    status: (
      <FilterPerStatus
        getValue={(value) => {
          getValueWithType(value, typeFilter);
        }}
      />
    ),
  }[typeFilter];

  return <>{componentFilter}</>;
};

export default FilterCompositeEvent;
