import { Box } from '@mui/system';

interface IPropsQRcode {
  url_qrcode?: string;
}
const QRcode: React.FunctionComponent<IPropsQRcode> = ({ url_qrcode }) => {
  return (
    <>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <img
          id="QRCode"
          height={300}
          width={300}
          src={url_qrcode}
          alt="Qrcode"
        />
      </Box>
    </>
  );
};

export default QRcode;
