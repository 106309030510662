import { Backdrop, CircularProgress } from '@mui/material';

interface ILoadingProps {
  open: boolean;
}

const Loading: React.FunctionComponent<ILoadingProps> = ({ open }) => {
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 999999 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Loading;
