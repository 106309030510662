import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import { checkinStudents } from '../../../service/StudentService';
import type { ICheckinRequest } from '../../../core/interfaces';

import { useNavigate } from 'react-router-dom';
import ErrorAPI from '../../../core/models/ErrorAPI';
import CheckinErroDTO from '../../../infra/api/dto/CheckinErroDTO';
import SnackBar from '../../components/SnackBar';

import {
  Button,
  Modal,
  Box,
  Typography,
  ListItem,
  ListItemText,
  List,
} from '@mui/material';
import style from './style.module.css';
import { studentInfo } from '../../../service/StudentService';
import { IStudent } from '../../../core/interfaces';
import { useParams } from 'react-router-dom';
import { useGeolocated } from 'react-geolocated';

const Checkin: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id_class } = useParams();

  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');

  const [openLoading, setOpenLoading] = useState<boolean>(true);
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [student, setStudent] = useState<IStudent>({} as IStudent);

  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });

  const checkin: ICheckinRequest = {
    uuid_classroom: state.data.uuid_classroom,
    uuid_student: state.data.uuid_student,
    latitude: coords?.latitude,
    longitude: coords?.longitude,
  };

  const handleShareModalClose = () => {
    setShareModalOpen(false);
    setOpenLoading(false);

    if (state === null) {
      navigate(`/scanner/${checkin.uuid_classroom}`);
    }
    if (state.origin !== 'classroomInfo') {
      navigate(`/scanner/${checkin.uuid_classroom}`);
    } else {
      navigate(`/information/place/performance/${state.placePerformanceID}`); // Navegar para a página anterior
    }
  };

  const handleShareModalOpen = async () => {
    setShareModalOpen(true);
    if (checkin.uuid_classroom !== id_class) {
      setSnackbarOpen(true);
      setSnackbarSeverity('error');
      setSnackbarMessage('O código da sala não corresponde ao código lido!');

      if (state === null) {
        setTimeout(() => {
          setOpenLoading(false); // Fecha o componente de loading
          navigate(`/scanner/${id_class}`);
        }, 3000); // Atraso de 3000 milissegundos (3 segundos)

        return;
      }

      if (state.origin !== 'classroomInfo') {
        setTimeout(() => {
          setOpenLoading(false); // Fecha o componente de loading
          navigate(`/scanner/${id_class}`);
        }, 3000); // Atraso de 3000 milissegundos (3 segundos)
      } else {
        setTimeout(() => {
          setOpenLoading(false); // Fecha o componente de loading
          navigate(
            `/information/place/performance/${state.placePerformanceID}`,
          ); // Navegar para a página anterior
        }, 3000); // Atraso de 3000 milissegundos (3 segundos)
      }
    } else {
      const studentData = await studentInfo(checkin);
      if (studentData instanceof ErrorAPI) {
        setSnackbarOpen(true);
        setSnackbarSeverity('error');
        setSnackbarMessage('QR Code inválido ou sem dados correspondentes.');
        setOpenLoading(false); // Fecha o componente de loading

        if (state === null) {
          setTimeout(() => {
            setOpenLoading(false); // Fecha o componente de loading
            navigate(`/scanner/${id_class}`);
          }, 3000); // Atraso de 3000 milissegundos (3 segundos)

          return;
        }

        if (state.origin !== 'classroomInfo') {
          setTimeout(() => {
            setOpenLoading(false); // Fecha o componente de loading
            navigate(`/scanner/${id_class}`);
          }, 3000); // Atraso de 3000 milissegundos (3 segundos)
        } else {
          setTimeout(() => {
            setOpenLoading(false); // Fecha o componente de loading
            navigate(
              `/information/place/performance/${state.placePerformanceID}`,
            ); // Navegar para a página anterior
          }, 3000); // Atraso de 3000 milissegundos (3 segundos)
        }
      } else {
        setSnackbarOpen(true);
        setSnackbarSeverity('success');
        setSnackbarMessage('Aluno escaneado com sucesso!');
        setStudent(studentData);
        setOpenLoading(false); // Fecha o componente de loading
      }
    }
  };

  const performCheckin = async () => {
    setOpenLoading(true); // Abre o componente de loading

    const apiData = await checkinStudents(checkin);

    if (apiData instanceof ErrorAPI) {
      setSnackbarOpen(true);
      setSnackbarSeverity('error');
      setSnackbarMessage('QR Code inválido ou sem dados correspondentes.');

      if (state === null) {
        setTimeout(() => {
          setOpenLoading(false); // Fecha o componente de loading
          navigate(`/scanner/${id_class}`);
        }, 3000); // Atraso de 3000 milissegundos (3 segundos)

        return;
      }

      if (state.origin !== 'classroomInfo') {
        setTimeout(() => {
          setOpenLoading(false); // Fecha o componente de loading
          navigate(`/scanner/${id_class}`);
        }, 3000); // Atraso de 3000 milissegundos (3 segundos)
      } else {
        setTimeout(() => {
          setOpenLoading(false); // Fecha o componente de loading
          navigate(
            `/information/place/performance/${state.placePerformanceID}`,
          ); // Navegar para a página anterior
        }, 3000); // Atraso de 3000 milissegundos (3 segundos)
      }
      return;
    }

    if (apiData instanceof CheckinErroDTO) {
      setSnackbarOpen(true);
      setSnackbarSeverity('error');
      setSnackbarMessage('QR Code inválido ou sem dados correspondentes.');

      if (state === null) {
        setTimeout(() => {
          setOpenLoading(false); // Fecha o componente de loading
          navigate(`/scanner/${id_class}`);
        }, 3000); // Atraso de 3000 milissegundos (3 segundos)

        return;
      }

      if (state.origin !== 'classroomInfo') {
        setTimeout(() => {
          setOpenLoading(false); // Fecha o componente de loading
          navigate(`/scanner/${id_class}`);
        }, 3000); // Atraso de 3000 milissegundos (3 segundos)
      } else {
        setTimeout(() => {
          setOpenLoading(false); // Fecha o componente de loading
          navigate(
            `/information/place/performance/${state.placePerformanceID}`,
          ); // Navegar para a página anterior
        }, 3000); // Atraso de 3000 milissegundos (3 segundos)
      }
      return;
    }

    if (apiData.status === 201) {
      setSnackbarOpen(true);
      setSnackbarSeverity('success');
      setSnackbarMessage(apiData.data.message);

      if (state === null) {
        setTimeout(() => {
          setOpenLoading(false); // Fecha o componente de loading
          navigate(`/classroom/detail/${id_class}`, {
            state: {
              validateStudent: true,
            },
          });
        }, 3000); // Atraso de 3000 milissegundos (3 segundos)
        return;
      }
      if (state.origin !== 'classroomInfo') {
        setTimeout(() => {
          setOpenLoading(false); // Fecha o componente de loading
          navigate(`/classroom/detail/${id_class}`, {
            state: {
              validateStudent: true,
            },
          });
        }, 3000); // Atraso de 3000 milissegundos (3 segundos)
      } else {
        setTimeout(() => {
          setOpenLoading(false); // Fecha o componente de loading
          navigate(
            `/information/place/performance/${state.placePerformanceID}`,
          ); // Navegar para a página anterior
        }, 3000); // Atraso de 3000 milissegundos (3 segundos)
      }

      return;
    }

    setSnackbarOpen(true);
    setSnackbarSeverity('error');
    setSnackbarMessage('Aluno não foi validado!');

    if (state.origin !== 'classroomInfo') {
      setTimeout(() => {
        setOpenLoading(false); // Fecha o componente de loading
        navigate(`/scanner/${id_class}`);
      }, 3000); // Atraso de 3000 milissegundos (3 segundos)
    } else {
      setTimeout(() => {
        setOpenLoading(false); // Fecha o componente de loading
        navigate(`/information/place/performance/${state.placePerformanceID}`); // Navegar para a página anterior
      }, 3000); // Atraso de 3000 milissegundos (3 segundos)
    }
  };

  useEffect(() => {
    handleShareModalOpen();
  }, []);

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Loading open={openLoading} />

      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={handleCloseSnackBar}
      />

      <Modal open={shareModalOpen} onClose={handleShareModalClose}>
        <Box className={style.styleBox}>
          <Typography className={style.typograpyBox} variant="h6">
            {' '}
            Informações do Cliente{' '}
          </Typography>
          <List dense={true} className={style.styleList}>
            <ListItem disablePadding>
              <ListItemText primary="Nome" secondary={student.name} />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText primary="E-mail" secondary={student.email} />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Nome da Empresa"
                secondary={student.company_name}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText primary="Whatsapp" secondary={student.whatsapp} />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText primary="CPF" secondary={student.CPF} />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText primary="CNPJ" secondary={student.CNPJ} />
            </ListItem>
          </List>
          <Typography className={style.typograpyBox}>
            {' '}
            Confirme a presença no evento{' '}
          </Typography>
          <Box className={style.actionBtnBox}>
            <Button
              variant="contained"
              className={style.btnBox}
              onClick={handleShareModalClose}
            >
              {' '}
              Não{' '}
            </Button>
            <Button
              variant="contained"
              className={style.btnBox}
              onClick={performCheckin}
            >
              {' '}
              Sim{' '}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Checkin;
