// eslint-disable-next-line
import { DateRange } from 'rsuite/esm/DateRangePicker';
// eslint-disable-next-line
import { DateRangePicker } from 'rsuite';

type getValue = (value: string) => void;

const FilterPerDate: React.FC<{ getValue: getValue }> = ({ getValue }) => {
  const handleEventDate = (value: DateRange) => {
    const startDate = new Date(value[0]);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(value[1]);
    endDate.setHours(0, 0, 0, 0);
    getValue(
      `${startDate.toISOString().split('T')[0]},${
        endDate.toISOString().split('T')[0]
      }`,
    );
  };

  return (
    <>
      <DateRangePicker
        onOk={handleEventDate}
        editable={false}
        format={'dd/MM/yyyy'}
        placeholder={'Selecione um período'}
        showOneCalendar
      />
    </>
  );
};

export default FilterPerDate;
