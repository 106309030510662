import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Icon, IconButton } from '@mui/material';
import { IClassroom } from '../../../core/interfaces';
import {
  classroomFinalize,
  classroomClosed,
  reopenClass,
} from '../../../service/ClassroomService';
import ErrorAPI from '../../../core/models/ErrorAPI';
import { useNavigate } from 'react-router-dom';
import DialogAlert from '../DialogAlert';
import isDateTodayOrBefore from '../../../core/utils/isDateTodayOrBefore';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';

interface IPropsPositionedMenuOptionClassroom {
  classroom?: IClassroom;
  getValuesLoading?: (value: boolean) => void;
}

const PositionedMenuOptionClassroom: React.FunctionComponent<
  IPropsPositionedMenuOptionClassroom
> = ({ classroom, getValuesLoading }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();

  const handleCloseClassroom = async () => {
    getValuesLoading?.(true);
    const statusCode = await classroomClosed(classroom?.uuid);
    if (statusCode instanceof ErrorAPI) {
      navigate('/logout');
    } else {
      setAnchorEl(null);
      getValuesLoading?.(false);
      navigate(0);
    }
  };

  const handleDialogOpenOrClose = () => setOpenDialog(!openDialog);

  const handleDialog = async (sendFiles: boolean) => {
    if (classroom?.is_event_receipt) {
      if (classroom.number_files_event_receipt! > 0) {
        getValuesLoading?.(true);
        const statusCode = await classroomFinalize(classroom.uuid, sendFiles);
        if (statusCode instanceof ErrorAPI) {
          navigate('/logout');
        } else {
          setAnchorEl(null);
          getValuesLoading?.(false);
          navigate(0);
        }
        handleDialogOpenOrClose();

        return;
      } else {
        alert(
          'Por favor, lembre-se de incluir os anexos com os comprovantes de execução do evento.',
        );
        return;
      }
    }
    if (classroom) {
      getValuesLoading?.(true);
      const statusCode = await classroomFinalize(classroom.uuid, sendFiles);
      if (statusCode instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setAnchorEl(null);
        getValuesLoading?.(false);
        navigate(0);
      }
      handleDialogOpenOrClose();
    }
  };

  const handleDisableFinalize = () => {
    if (classroom?.from_origin === 'INTEGRA') {
      if (isDateTodayOrBefore(classroom.end_date_event)) {
        return classroom.finished_event;
      }
      return true;
    }
    return !!classroom?.finished_event;
  };

  const handleReopenClassroom = async () => {
    getValuesLoading?.(true);
    const response = await reopenClass(classroom?.uuid);
    if (response instanceof ErrorAPI) {
      if (response.statusCode === 401) {
        navigate('/logout');
      }
    } else {
      setAnchorEl(null);
      getValuesLoading?.(false);
      navigate(0);
    }
  };

  return (
    <>
      <DialogAlert
        titleDialog={'Confirmação da Sala: Deseja enviar os arquivos ?'}
        descriptionDialog={
          'Deseja enviar os arquivos desta turma para os alunos? Por favor, note que se a turma não possuir nenhum arquivo anexado, você pode ignorar esta mensagem sinalizando "Não".'
        }
        openDialog={openDialog}
        onCloseDialog={handleDialogOpenOrClose}
        confirmationButtonCallback={() => {
          handleDialog(true);
        }}
        cancelButtonCallback={() => {
          handleDialog(false);
        }}
      />
      {classroom?.from_origin === 'INTEGRA' && (
        <Tooltip
          title="Você pode concluir a turma do SAS somente após sua data final ter sido alcançada."
          placement="top"
        >
          <InfoIcon />
        </Tooltip>
      )}
      <IconButton
        onClick={handleClick}
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{ p: '0px' }}
      >
        <Icon style={{ padding: '0px !important' }}>more_vert</Icon>
      </IconButton>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          key="finalizarTurma"
          disabled={handleDisableFinalize()}
          onClick={handleDialogOpenOrClose}
        >
          Finalizar turma
        </MenuItem>
        <MenuItem
          key="encerrarInscricoes"
          disabled={
            classroom?.finished_event ? true : classroom?.is_the_class_closed
          }
          onClick={handleCloseClassroom}
        >
          Encerrar Inscrições
        </MenuItem>
        <MenuItem
          key="reabriEvento"
          disabled={!classroom?.finished_event}
          onClick={handleReopenClassroom}
        >
          Reabrir evento
        </MenuItem>
      </Menu>
    </>
  );
};

export default PositionedMenuOptionClassroom;
