interface ITabPanelProps {
  index: number;
  value: number;
  style?: React.CSSProperties | undefined;
}
export default function TabPanel(
  props: React.PropsWithChildren<ITabPanelProps>,
) {
  const { children, value, index, style } = props;

  return (
    <div style={style} role="tabpanel" hidden={value !== index}>
      {value === index && children}
    </div>
  );
}
