/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
} from '@mui/material';
import DBGrid from '../../../components/Grid';
import { IPlacePerformance, ISebrae } from '../../../../core/interfaces';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import SnackBar from '../../../components/SnackBar';
import {
  createPlacePerformance,
  deletePlacePerformance,
  getAllPlacePerformance,
} from '../../../../service/PlacePerformance';
import ErrorAPI from '../../../../core/models/ErrorAPI';
import { getAllsebrae } from '../../../../service/SebraeService';

const PlacePerformance: React.FC = () => {
  const [rowsPlacePerformance, setrowsPlacePerformance] = useState<
    IPlacePerformance[]
  >([] as IPlacePerformance[]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPlacePerformance, setSelectedPlacePerformance] = useState<
    IPlacePerformance[]
  >([] as IPlacePerformance[]);
  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const [sebraes, setSebraes] = useState<ISebrae[]>([]);
  const [sebrae, setSebrae] = useState<ISebrae>({} as ISebrae);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const navigate = useNavigate();
  const { id_class } = useParams();
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const handleDialog = (
    uuid?: string,
    validation_started?: boolean,
    is_collective?: boolean,
    is_free?: boolean,
  ) => {
    navigate(`/edit/place/performance/${uuid}`);
  };

  const columnsPlacePerformance = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'uuid', headerName: 'ID', width: 50 },
    { field: 'name', headerName: 'Nome local', width: 250 },
    { field: 'sebrae_origin', headerName: 'Sebrae', width: 550 },
    {
      field: 'actions',
      headerName: 'Opções',
      width: 200,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        const handleActionTable = () => {
          handleDialog(
            params.row.uuid,
            params.row.validation_started,
            params.row.is_free,
            params.row.is_collective,
          );
        };
        return (
          <>
            <Button
              onClick={handleActionTable}
              variant="contained"
              size="small"
            >
              Editar Modelo
            </Button>
          </>
        );
      },
    },
  ];

  const handleGetValue = (values: object[]) => {
    setDisableButton(true);
    if (values.length > 0) {
      setDisableButton(false);
    }
    setSelectedPlacePerformance(values as IPlacePerformance[]);
  };

  const handleSubmit = async (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();

    const form = new FormData(event.currentTarget);
    const placePerformance = form.get('place_performance')?.toString();
    const originSebrae = form.get('originSebrae')?.toString();

    if (!originSebrae) {
      setSnackbarMessage('Necessário o envio do Sebrae');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    if (!placePerformance) {
      setSnackbarMessage('Necessário o envio do nome da sala');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    const data: IPlacePerformance = {
      name: placePerformance,
      sebrae_origin: originSebrae,
    };
    const response = await createPlacePerformance(data);
    if (response instanceof ErrorAPI) {
      if (response.statusCode == 401) {
        navigate('/logout');
      }

      if (response.statusCode == 500) {
        setSnackbarMessage(response.message);
        setSnackbarSeverity('warning');
        setSnackbarOpen(true);
      }

      if (response.statusCode == 400) {
        setSnackbarMessage(response.message);
        setSnackbarSeverity('warning');
        setSnackbarOpen(true);
      }
    } else {
      setrowsPlacePerformance(response);
    }
    setOpenLoading(false);
  };

  const handleGetValuesSnack = (
    value: boolean,
    message: string,
    severity: string,
  ) => {
    setSnackbarOpen(value);
    setSnackbarSeverity(severity as 'success' | 'info' | 'warning' | 'error');
    setSnackbarMessage(message);
  };

  useEffect(() => {
    const getData = async () => {
      setOpenLoading(true);
      try {
        const [response, responseSebraes] = await Promise.all([
          getAllPlacePerformance(),
          getAllsebrae(),
        ]);

        if (response instanceof ErrorAPI) {
          if (response.statusCode === 401) {
            navigate('/logout');
            return;
          }

          if (response.statusCode === 500) {
            handleGetValuesSnack(true, response.message, 'warning');
            return;
          }
        } else {
          setrowsPlacePerformance(response);
        }

        if (responseSebraes instanceof ErrorAPI) {
          handleGetValuesSnack(true, responseSebraes.message, 'warning');
        } else {
          setSebraes(responseSebraes);
        }
      } catch (error) {
        handleGetValuesSnack(true, 'An unexpected error occurred.', 'error');
      } finally {
        setOpenLoading(false);
      }
    };

    getData();
  }, [navigate]); // Adicione dependências necessárias aqui, se houver

  const handleButtonDelete = async () => {
    setOpenLoading(true);
    const data = selectedPlacePerformance[0].id;
    const response = await deletePlacePerformance(data!);
    setOpenLoading(false);
    if (response instanceof ErrorAPI) {
      if (response.statusCode == 401) {
        navigate('/logout');
      }

      if (response.statusCode == 500) {
        setSnackbarMessage(response.message);
        setSnackbarSeverity('warning');
        setSnackbarOpen(true);
      }
    } else {
      setrowsPlacePerformance(response);
    }
  };

  const handleChangeOriginSebrae = (event: SelectChangeEvent) => {
    const typeSelect = event.target.value as string;
    const sebraeOrigin = sebraes?.find((value) => value.id === typeSelect);
    if (sebraeOrigin) {
      setSebrae(sebraeOrigin);
    }
  };

  return (
    <>
      <Box
        sx={{ m: '1rem' }}
        component="form"
        onSubmit={handleSubmit}
        noValidate
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // Alinha verticalmente
            gap: 2, // Aumenta o espaçamento entre os elementos
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="originSebrae">Sebrae de origem</InputLabel>
            <Select
              labelId="originSebrae"
              id="originSebrae"
              name="originSebrae"
              label="Sebrae de origem"
              onChange={handleChangeOriginSebrae}
              value={sebrae.id}
            >
              {sebraes?.map((value) => (
                <MenuItem key={value.id} value={value.id}>
                  {value.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="place_performance"
            name="place_performance"
            autoFocus
            label="Nome do local"
            variant="outlined"
            type="text"
            margin="dense"
            fullWidth
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', // Alinha verticalmente
            gap: 2, // Aumenta o espaçamento entre os elementos
          }}
        >
          <Button type="submit" variant="contained">
            Cadastrar
          </Button>
        </Box>
        <Box>
          <div
            style={{
              display: 'flex',
              marginTop: '1rem',
              marginBottom: '1rem',
              justifyContent: 'end',
              gap: '1rem',
            }}
          >
            <Button
              onClick={handleButtonDelete}
              disabled={disableButton}
              variant="contained"
            >
              Excluir
            </Button>
          </div>

          <DBGrid
            columnVisibilityModel={{ uuid: false, id: false }}
            // checkboxSelection={true}
            selectedValue={handleGetValue}
            columns={columnsPlacePerformance}
            rows={rowsPlacePerformance}
          />
        </Box>
      </Box>
      <Loading open={openLoading} />
      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
    </>
  );
};

export default PlacePerformance;
