import {
  Toolbar,
  AppBar,
  IconButton,
  Typography,
  CssBaseline,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useDrawerContext } from '../../contexts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { IInfoUser } from '../../../core/interfaces';
import {
  List,
  ListItem,
  ListItemText,
  Modal,
  CardContent,
} from '@mui/material';
import { useState, useEffect } from 'react';
import style from './style.module.css';

import ErrorAPI from '../../../core/models/ErrorAPI';
import { getInforUser } from '../../../service/AuthService';
import { useNavigate } from 'react-router-dom';

interface Props {
  title: string;
}

export default function Header({ title }: Props) {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<IInfoUser>({} as IInfoUser);

  const [userModal, setUserModal] = useState<boolean>(false);

  useEffect(() => {
    async function fetchInfoUser() {
      const infoUser = await getInforUser();
      if (infoUser instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setUserInfo(infoUser);
      }
    }
    fetchInfoUser();
  }, [navigate]);

  const handleModalOpen = () => {
    setUserModal(true);
  };

  const handleModalClose = () => {
    setUserModal(false);
  };

  const { toggleDrawerOpen } = useDrawerContext();
  return (
    <>
      <CssBaseline />
      <AppBar position="static" style={{ background: '#1976d2' }}>
        <Toolbar color="common.white">
          <IconButton style={{ color: 'white' }} onClick={toggleDrawerOpen}>
            <MenuIcon />
          </IconButton>
          <Typography
            style={{ color: 'white' }}
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            {title}
          </Typography>

          <IconButton onClick={handleModalOpen}>
            <AccountCircleIcon style={{ color: 'white' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Modal
        open={userModal}
        onClose={handleModalClose}
        style={{ zIndex: 999999 }}
      >
        <CardContent className={style.cardHeaderContent}>
          <List dense={true} className={style.styleHeaderList}>
            <ListItem disablePadding>
              <ListItemText
                primary={'Nome'}
                secondary={`${userInfo.supplier_name}`}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary={'CPF'}
                secondary={`${userInfo.document}`}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary={'E-mail'}
                secondary={`${userInfo.supplier_email}`}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary={'Telefone'}
                secondary={`${userInfo.supplier_phone}`}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary={'Turma Atual'}
                secondary={`${userInfo.current_event}`}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary={'Prox. Turma'}
                secondary={`${userInfo.next_event}`}
              />
            </ListItem>
          </List>
        </CardContent>
      </Modal>
    </>
  );
}
