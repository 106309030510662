import { useNavigate, useParams } from 'react-router-dom';
import SideBar from '../../components/Drawer';
import Header from '../../components/Header';
import HeaderDescription from '../../components/HeaderDescription';
import { ICompositeEvent } from '../../../core/interfaces';

import { DrawerProvider } from '../../contexts';
import SnackBar from '../../components/SnackBar';
import { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import CardsClassroomCompositeEvent from '../../components/CardsClassroomCompositeEvent';
import { getCompositeEventClassroom } from '../../../service/CompositeEventOperationService';
import ErrorAPI from '../../../core/models/ErrorAPI';

const CompositeEvent: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { id_composite_event } = useParams();

  const [compositeEvent, setCompositeEvent] = useState<ICompositeEvent>(
    {} as ICompositeEvent,
  );
  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');

  const handleDefinedError = (menssage: string) => {
    setSnackbarMessage(menssage);
    setSnackbarOpen(true);
    setSnackbarSeverity('warning');
  };

  useEffect(() => {
    async function searchClassrooms() {
      setOpenLoading(true);
      const response = await getCompositeEventClassroom(id_composite_event);
      setOpenLoading(false);
      if (response instanceof ErrorAPI) {
        if (response.statusCode == 401) {
          navigate('/logout');
        }
        handleDefinedError(response.message);
      } else {
        setCompositeEvent(response);
      }
    }
    searchClassrooms();
  }, []);

  return (
    <>
      <DrawerProvider>
        <SideBar>
          <Header title="Grande eventos" />
          <HeaderDescription
            title="Salas do eventos."
            subtitle="Entre dentro da sala para fazer a verificação dos alunos."
          />
          <CardsClassroomCompositeEvent compositeEvent={compositeEvent} />
          <SnackBar
            open={snackBarOpen}
            type={snackbarSeverity}
            message={snackBarMessage}
            onClose={() => {
              setSnackbarOpen(false);
            }}
          />
          <Loading open={openLoading} />
        </SideBar>
      </DrawerProvider>
    </>
  );
};

export default CompositeEvent;
