/* eslint-disable no-useless-escape */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { useState, useEffect, useRef } from 'react';
import {
  getCertificate,
  updateCertificates,
} from '../../../../service/ClassroomService';
import { useNavigate } from 'react-router-dom';
import ErrorAPI from '../../../../core/models/ErrorAPI';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import { IClassroomCertificate } from '../../../../core/interfaces';
import parse from 'html-react-parser';
import base64toFile from '../../../../core/utils/base64toFile';
import { FileUploader } from 'react-drag-drop-files';
import convertImageToBase64 from '../../../../core/utils/convertImageToBase64';
import SnackBar from '../../../components/SnackBar';
type severity = 'success' | 'info' | 'warning' | 'error';
import QrcodeExample from './qrcode_example.png';
import Loading from '../../../components/Loading';

const EditCertificate: React.FC = () => {
  const navigate = useNavigate();
  const [certificates, setCertificates] = useState<IClassroomCertificate[]>([]);
  const [certificate, setCertificate] = useState<IClassroomCertificate>(
    {} as IClassroomCertificate,
  );
  const [imageFront, setImageFront] = useState<string | null>(null);
  const [fileFront, setFileFront] = useState<File | null>(null);
  const [contentFront, setContentFront] = useState<string>('');
  const containerFrontRef = useRef<HTMLDivElement | null>(null);

  const [contentBack, setContentBack] = useState<string>('');
  const [imageBack, setImageBack] = useState<string | null>(null);
  const [fileBack, setFileBack] = useState<File | null>(null);
  const containerBackRef = useRef<HTMLDivElement | null>(null);
  const [verseActive, setVerseActive] = useState(certificate.verse_active);

  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<severity>('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVerseActive(event.target.checked);
  };
  const handleEditorChangeFront = (newContent: string) => {
    setContentFront(newContent);
  };
  const handleEditorChangeBack = (newContent: string) => {
    setContentBack(newContent);
  };

  const handleOpenSnackBar = (msg: string, severity: severity) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(msg);
    setSnackbarOpen(true);
  };
  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  function renderRow(props: ListChildComponentProps) {
    const { index, style } = props;
    const data = certificates[index];
    return (
      <ListItem
        style={style}
        key={index}
        secondaryAction={
          <IconButton
            onClick={() => {
              setCertificate(data);
              setVerseActive(data.verse_active);
              setContentFront(data.content_editor_front);
              setContentBack(data.content_editor_back);
            }}
            edge="end"
            aria-label="edit"
          >
            <EditIcon />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <Avatar>
            <PictureAsPdfIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={data.certificate_name} />
      </ListItem>
    );
  }

  useEffect(() => {
    async function getData() {
      setOpenLoading(true);
      const data = await getCertificate();
      setOpenLoading(false);
      if (data instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setCertificates(data);
      }
    }
    getData();
  }, []);

  const handleFileBackgroundFront = (file: File) => {
    if (file) {
      setFileFront(file);
      convertImageToBase64(file, (base64String) => {
        setImageFront(base64String);
      });
    }
  };

  const handleFileBackgroundBack = (file: File) => {
    if (file) {
      setFileBack(file);
      convertImageToBase64(file, (base64String) => {
        setImageBack(base64String);
      });
    }
  };

  const updateCertificate = async () => {
    const htmlFront = containerFrontRef.current;
    const htmlBack = containerBackRef.current;
    let fileFrontFile: File | null = null;
    let fileBackFile: File | null = null;

    if (!htmlFront) {
      return;
    }

    if (!htmlBack && verseActive) {
      return;
    }

    const htmBackValidate = htmlBack != null ? htmlBack.outerHTML : '';

    if (fileFront === null) {
      const fileNameMatch =
        certificate.path_file_front.match(/\/([^\/?#]+)[^\/]*$/);
      const fileName = fileNameMatch ? fileNameMatch[1] : 'arquivo.png'; // Se não encontrar o nome, usa 'arquivo.png'

      fileFrontFile = base64toFile(certificate.path_file_front, fileName);
    } else {
      fileFrontFile = fileFront;
    }

    if (certificate.path_file_back) {
      const fileNameMatch =
        certificate.path_file_back.match(/\/([^\/?#]+)[^\/]*$/);
      const fileName = fileNameMatch ? fileNameMatch[1] : 'arquivo.png'; // Se não encontrar o nome, usa 'arquivo.png'

      fileBackFile = base64toFile(certificate.path_file_back, fileName);
    } else {
      fileBackFile = fileBack;
    }

    if (fileFrontFile || fileBackFile) {
      const response = await updateCertificates(
        certificate.uuid,
        verseActive,
        certificate.certificate_name,
        htmlFront.outerHTML,
        htmBackValidate,
        fileFrontFile,
        fileBackFile,
        contentFront,
        contentBack,
      );

      if (response instanceof ErrorAPI) {
        if (response.statusCode === 401) {
          navigate('/logout');
        }

        if (response.statusCode == 500) {
          setSnackbarMessage(response.message);
          setSnackbarSeverity('warning');
          setSnackbarOpen(true);
        }
      } else {
        handleOpenSnackBar(
          'Os dados foram atualizados com sucesso.',
          'success',
        );
      }
    }
  };

  return (
    <>
      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={handleCloseSnackBar}
      />
      <div
        style={{
          margin: '1rem',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        }}
      >
        <Button
          disableElevation
          onClick={updateCertificate}
          variant="contained"
        >
          Atualizar
        </Button>
      </div>
      <Box>
        <FixedSizeList
          height={200}
          width={'100%'}
          itemSize={46}
          itemCount={certificates.length}
          overscanCount={5}
        >
          {renderRow}
        </FixedSizeList>
      </Box>
      <div style={{ margin: '1rem' }}>
        {certificate.path_file_front && (
          <>
            <Box
              style={{ margin: '1rem', display: 'flex', alignItems: 'center' }}
            >
              <FormControlLabel
                required
                control={
                  <Switch
                    checked={verseActive}
                    onChange={handleChangeSwitch}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Habilitar o verso do certificado"
              />
            </Box>
            <Typography variant="h6" gutterBottom>
              Configure o verso do seu certificado
            </Typography>
            <Divider />
            <CKEditor
              editor={Editor}
              data={certificate.content_editor_front}
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              onReady={(editor) => {}}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleEditorChangeFront(data);
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '2rem',
              }}
            >
              <FileUploader
                handleChange={handleFileBackgroundFront}
                name="file"
                multiple={false}
                hoverTitle="Solte o arquivo aqui"
                label="Carregue o arquivo aqui"
                types={['JPEG', 'PNG', 'GIF']}
              />
            </div>
            <div
              id="container"
              ref={containerFrontRef}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                position: 'relative',
                alignItems: 'flex-start',
                backgroundImage: `url('${
                  imageFront !== null ? imageFront : certificate.path_file_front
                }')`,
                backgroundSize: 'cover',
                backgroundColor: 'black',
                overflow: 'hidden',
                width: '1123px',
                height: '794px',
                margin: '0 auto',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  margin: '2rem',
                  width: '150px',
                  height: '150px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'black',
                }}
              >
                <img
                  id="qrcode"
                  src={QrcodeExample}
                  alt="Girl in a jacket"
                  width="150"
                  height="150"
                />
              </div>
              <div
                style={{
                  width: '100%',
                }}
              >
                {parse(contentFront)}
              </div>
            </div>
          </>
        )}

        {verseActive && (
          <>
            <Typography variant="h6" gutterBottom>
              Configure o verso do seu certificado
            </Typography>
            <Divider />
            <CKEditor
              editor={Editor}
              data={certificate.content_editor_back}
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              onReady={(editor) => {}}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleEditorChangeBack(data);
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '2rem',
              }}
            >
              <FileUploader
                handleChange={handleFileBackgroundBack}
                name="file"
                multiple={false}
                hoverTitle="Solte o arquivo aqui"
                label="Carregue o arquivo aqui"
                types={['JPEG', 'PNG', 'GIF']}
              />
            </div>

            <div
              id="container"
              ref={containerBackRef}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                position: 'relative',
                alignItems: 'flex-start',
                backgroundImage: `url('${
                  imageBack !== null ? imageBack : certificate.path_file_back
                }')`,
                backgroundSize: 'cover',
                backgroundColor: 'black',
                overflow: 'hidden',
                width: '1123px',
                height: '794px',
                margin: '0 auto',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  margin: '2rem',
                  width: '150px',
                  height: '150px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'black',
                }}
              >
                <img
                  id="qrcode"
                  src={QrcodeExample}
                  alt="Girl in a jacket"
                  width="150"
                  height="150"
                />
              </div>

              <div
                style={{
                  width: '100%',
                }}
              >
                {parse(contentBack)}
              </div>
            </div>
            <Loading open={openLoading} />
          </>
        )}
      </div>
    </>
  );
};

export default EditCertificate;
