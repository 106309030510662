/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { Card, CardContent, Divider } from '@material-ui/core';
import {
  Button,
  CardActions,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

import { IClassroom } from '../../../core/interfaces';
import style from './style.module.css';
import { useNavigate } from 'react-router-dom';
import React from 'react';

import Loading from '../Loading';
import daysjs from 'dayjs';

interface IBasicCardClassroom {
  classroom: IClassroom;
}
const CardClassroomInBuild: React.FunctionComponent<IBasicCardClassroom> = ({
  classroom,
}) => {
  const navigate = useNavigate();

  const {
    uuid,
    is_collective,
    solution_name,
    number_of_students,
    is_free,
    place,
    code_classroom,
    start_date_event,
    end_date_event,
  } = classroom;

  return (
    <Grid item xs={12} sm={6} md={3} key={uuid} className={style.styleGrid}>
      <Card className={style.card}>
        <CardContent className={style.cardContent}>
          <List dense={true} className={style.styleList}>
            <ListItem disablePadding>
              <ListItemText
                primary="Código da turma"
                secondary={code_classroom}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Tipo de solução"
                secondary={is_collective ? 'Coletiva' : 'Individual'}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Nome da solução"
                secondary={solution_name}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Quantidade de inscritos"
                secondary={number_of_students}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="É grátis ?"
                secondary={is_free ? 'Sim' : 'Não'}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Local"
                secondary={place ? place : 'Não informado'}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Data e hora de início"
                secondary={daysjs(start_date_event).format(
                  'DD/MM/YYYY HH:mm:ss',
                )}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Data e hora de fim"
                secondary={daysjs(end_date_event).format('DD/MM/YYYY HH:mm:ss')}
              />
            </ListItem>
          </List>

          <Divider />
          <CardActions disableSpacing className={style.cardAction}>
            <Button
              onClick={() => {
                navigate(`/finalize/classroom/${uuid}`);
              }}
              aria-label="Finalizar construção"
            >
              <span>Finalizar construção</span>
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CardClassroomInBuild;
