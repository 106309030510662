/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Fab from '@mui/material/Fab';
import { ListItemIcon, ListItemText, ListItemButton } from '@mui/material';
import { Icon } from '@mui/material';
import style from './style.module.css';

export interface IInstallBannerProps {
  isListItem?: boolean;
}

const InstallBanner: React.FunctionComponent<IInstallBannerProps> = ({
  isListItem,
}) => {
  const [showBanner, setShowBanner] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    checkAppInstalled();

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt,
      );
    };
  }, []);

  const handleBeforeInstallPrompt = (event: any) => {
    event.preventDefault();
    setDeferredPrompt(event);
    setShowBanner(true);
  };

  const checkAppInstalled = () => {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      );
    const isAppInstalled = window.matchMedia(
      '(display-mode: standalone)',
    ).matches;
    setShowBanner(isMobile && !isAppInstalled);
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('O usuário aceitou a instalação');
        } else {
          console.log('O usuário recusou a instalação');
        }
        setDeferredPrompt(null);
        setShowBanner(false);
      });
    }
  };

  if (!showBanner) {
    return null;
  }

  return (
    <>
      {isListItem ? (
        <ListItemButton
          className={style.btn_lista}
          onClick={handleInstallClick}
        >
          <ListItemIcon className={style.icon_lista}>
            <Icon>{'download'}</Icon>
          </ListItemIcon>
          <ListItemText
            className={style.text_lista}
            primary="Download APP"
            secondary={'Baixe o APP!'}
          />
        </ListItemButton>
      ) : (
        <Fab color="primary" variant="extended" onClick={handleInstallClick}>
          Instale nosso app
        </Fab>
      )}
    </>
  );
};

export default InstallBanner;
