import {
  TextField,
  Icon,
  InputAdornment,
  IconButton,
  Box,
} from '@mui/material';
import { useState } from 'react';
import style from './style.module.css';

type getValue = (value: string) => void;
const FilterPerCode: React.FC<{ getValue: getValue }> = ({ getValue }) => {
  const [textFieldValue, setTextFieldValue] = useState<string>('');

  const handleSubmit = (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const codeClass = data.get('code_class')?.toString();
    if (codeClass && codeClass.length > 0) {
      getValue(codeClass);
    }
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        className={style.containerBox}
      >
        <TextField
          value={textFieldValue}
          onChange={(e) => setTextFieldValue(e.target.value)}
          id="code_class"
          name="code_class"
          autoFocus
          label="Código da turma"
          variant="standard"
          type="text"
          margin="dense"
          className={style.select}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit">
                  <Icon>{'search'}</Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default FilterPerCode;
