/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Grid,
  Typography,
  Card,
  CardContent,
  Snackbar,
  Alert,
  FormHelperText,
  TextField,
  Button,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { IPlacePerformance, ISebrae, IUnit } from '../../../../core/interfaces';
import HeaderDescription from '../../../components/HeaderDescription';
import ErrorAPI from '../../../../core/models/ErrorAPI';
import CompositeEventSelect from '../../Generate/SearchClassroom/CompositeEventSelect';
import { getAllPlacePerformance } from '../../../../service/PlacePerformance';
import { getAllsebrae } from '../../../../service/SebraeService';
import { getAllunit } from '../../../../service/UnitService';

const StepOne: React.FC = () => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const [loading, setLoading] = useState(true);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');

  const [placePerformance, setPlacePerformance] = useState<IPlacePerformance[]>(
    [] as IPlacePerformance[],
  );
  const [allPlacePerformance, setAllPlacePerformance] = useState<
    IPlacePerformance[]
  >([] as IPlacePerformance[]);
  const [sebraes, setSebraes] = useState<ISebrae[]>([]);
  const [unidades, setUnidades] = useState<IUnit[]>([]);
  const [classCapacity, setClassCapacity] = useState<number | ''>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const mainEvent = watch('main_event');
  const navigate = useNavigate();
  const sebrae_origin = watch('sebrae_origin');
  const unit_origin = watch('unit_origin');
  const place_performance = watch('place_performance');
  const free_class_capacity = watch('free_class_capacity');
  const isLimitedEnrollment = watch('isLimitedEnrollment', false);
  const belongsToABigEvent = watch('belongsToABigEvent', false);
  const eventComposite = watch('eventComposite', false);

  const switch_labels = [
    {
      label: 'Validar o QRCode na entrada do evento?',
      id: 'supplierPermission',
    },
    {
      label: 'Quer limitar a quantidade de alunos nesta turma?',
      id: 'isLimitedEnrollment',
    },
    {
      label: 'Necessária evidência da realização do evento?',
      id: 'eventReceipt',
    },
    {
      label: 'Necessário certificado para o evento?',
      id: 'requireCertificate',
    },
    { label: 'Faz parte de um evento principal?', id: 'belongsToABigEvent' },
    { label: 'É evento principal?', id: 'eventComposite' },
  ];

  const switch_states = switch_labels.map((item) => {
    const key = item.id;
    return { label: item.label, key, value: watch(key) };
  });

  const handleGetValuesSnack = (
    value: boolean,
    message: string,
    severity: 'success' | 'info' | 'warning' | 'error',
  ) => {
    setSnackbarOpen(value);
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
  };

  useEffect(() => {
    if (!belongsToABigEvent) setValue('main_event', null);
  }, [belongsToABigEvent, setValue]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [placeResponse, sebraeResponse, unitResponse] = await Promise.all(
          [getAllPlacePerformance(), getAllsebrae(), getAllunit()],
        );

        if (
          placeResponse instanceof ErrorAPI ||
          sebraeResponse instanceof ErrorAPI ||
          unitResponse instanceof ErrorAPI
        ) {
          navigate('/logout');
          handleGetValuesSnack(true, 'Erro ao carregar dados', 'warning');
        } else {
          setPlacePerformance(placeResponse);
          setAllPlacePerformance(placeResponse); // Salva todos os locais
          setSebraes(sebraeResponse);
          setUnidades(unitResponse);
        }
      } catch (error) {
        console.error('Erro ao buscar os dados:', error);
        handleGetValuesSnack(true, 'Erro ao buscar os dados', 'error');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [setValue, navigate]);

  useEffect(() => {
    if (sebrae_origin) {
      const filteredPlaces = allPlacePerformance.filter(
        (place) => place.sebrae_obj?.id === sebrae_origin,
      );
      setPlacePerformance(filteredPlaces);
    } else {
      setPlacePerformance(allPlacePerformance);
    }
  }, [sebrae_origin, allPlacePerformance]);

  const handleSwitchChange =
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(key, event.target.checked);

      if (key === 'belongsToABigEvent' && event.target.checked) {
        setValue('eventComposite', false);
      }

      if (key === 'eventComposite' && event.target.checked) {
        setValue('belongsToABigEvent', false);
      }

      if (key === 'isLimitedEnrollment') {
        if (!event.target.checked) {
          setClassCapacity('');
          setValue('free_class_capacity', '');
        }
      }
    };

  useEffect(() => {
    if (free_class_capacity) setClassCapacity(free_class_capacity);
  }, [free_class_capacity]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSelectMainEvent = (value: object[]) => {
    setValue('main_event', value[0]);
    handleCloseModal();
  };

  if (loading)
    return (
      <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
    );

  return (
    <div>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HeaderDescription
            title="Configuração inicial"
            subtitle="Aqui, você especifica informações como o local de realização, a unidade de origem ou o SEBRAE de origem, juntamente com as regras estabelecidas."
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="dense" error={!!errors.sebrae_origin}>
            <InputLabel id="sebrae-origin-label">Sebrae de origem</InputLabel>
            <Select
              labelId="sebrae-origin-label"
              label="Sebrae de origem"
              {...register('sebrae_origin', {
                required: 'Sebrae de origem é obrigatório',
              })}
              value={sebrae_origin || ''}
            >
              {sebraes.map((sebrae) => (
                <MenuItem key={sebrae.id} value={sebrae.id}>
                  {sebrae.name}
                </MenuItem>
              ))}
            </Select>
            {errors.sebrae_origin && (
              <FormHelperText>
                {String(errors.sebrae_origin?.message)}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="dense" error={!!errors.unit_origin}>
            <InputLabel id="unit-origin-label">Unidade de origem</InputLabel>
            <Select
              labelId="unit-origin-label"
              label="Unidade de origem"
              {...register('unit_origin', {
                required: 'Unidade de origem é obrigatória',
              })}
              value={unit_origin || ''}
            >
              {unidades.map((unit) => (
                <MenuItem key={unit.id} value={unit.id}>
                  {unit.name}
                </MenuItem>
              ))}
            </Select>
            {errors.unit_origin && (
              <FormHelperText>
                {String(errors.unit_origin?.message)}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            margin="dense"
            error={!!errors.place_performance}
          >
            <InputLabel id="place-performance-label">Sala</InputLabel>
            <Select
              labelId="place-performance-label"
              label="Sala"
              {...register('place_performance', {
                required: 'Sala é obrigatória',
              })}
              value={place_performance || ''}
            >
              {placePerformance.map((place) => (
                <MenuItem key={place.uuid} value={place.uuid}>
                  {place.name}
                </MenuItem>
              ))}
            </Select>
            {errors.place_performance && (
              <FormHelperText>
                {String(errors.place_performance?.message)}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Opções
              </Typography>
              <Grid container spacing={2}>
                {switch_states.map(({ label, key, value }, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <FormControlLabel
                      control={
                        <Switch
                          {...register(key)}
                          checked={value || false}
                          onChange={handleSwitchChange(key)}
                          color="primary"
                        />
                      }
                      label={label}
                    />
                  </Grid>
                ))}
              </Grid>
              {belongsToABigEvent && (
                <Grid container spacing={2} style={{ marginTop: 16 }}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpenModal}
                    >
                      Selecionar Evento Principal
                    </Button>
                    {mainEvent && (
                      <Typography variant="body1" style={{ marginTop: 8 }}>
                        Evento Principal Selecionado: {mainEvent.name}
                      </Typography>
                    )}
                    <CompositeEventSelect
                      getValue={handleSelectMainEvent}
                      isOpen={isModalOpen}
                      onClose={handleCloseModal}
                    />
                  </Grid>
                </Grid>
              )}
              {isLimitedEnrollment && (
                <Grid container spacing={2} style={{ marginTop: 16 }}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Capacidade de Alunos"
                      type="number"
                      inputProps={{ min: 1, max: 999 }}
                      {...register('free_class_capacity', {
                        required: 'Capacidade de alunos é obrigatória',
                        min: {
                          value: 1,
                          message: 'A capacidade deve ser no mínimo 1',
                        },
                        max: {
                          value: 999,
                          message: 'A capacidade deve ser no máximo 999',
                        },
                      })}
                      value={classCapacity}
                      onChange={(e) => {
                        const value = e.target.value
                          ? parseInt(e.target.value, 10)
                          : '';
                        setValue('free_class_capacity', value);
                        setClassCapacity(value);
                      }}
                      error={!!errors.free_class_capacity}
                      helperText={
                        errors.free_class_capacity &&
                        String(errors.free_class_capacity.message)
                      }
                    />
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default StepOne;
