import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Icon, IconButton } from '@mui/material';
import { ICheckinRequest } from '../../../core/interfaces';
import ErrorAPI from '../../../core/models/ErrorAPI';
import { useNavigate } from 'react-router-dom';
import {
  sendEmailQRCode,
  sendPhoneQRCode,
  checkinStudents,
} from '../../../service/StudentService';
import Cookies from 'js-cookie';
import { hasRole } from '../../../core/utils';
import ManagerMenu from './ManagerMenu';
import SupplierMenu from './SupplierMenu';

interface IPropsPositionedMenu {
  uuidClass?: string;
  studentNumber?: string;
  uuidStudent?: string;
  getValuesLoading?: (value: boolean) => void;
  getValuesSnack?: (value: boolean, message: string, severity: string) => void;
}

const PositionedMenu: React.FunctionComponent<IPropsPositionedMenu> = ({
  uuidClass,
  uuidStudent,
  studentNumber,
  getValuesLoading,
  getValuesSnack,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const roles = Cookies.get('roles');

  let rolesFromCookie = JSON.parse('[]');

  if (roles !== 'undefined' && roles !== undefined) {
    rolesFromCookie = JSON.parse(roles);
  }

  const hasManagerRole = hasRole(rolesFromCookie, 'MANAGER');
  // const hasSupplierRole = hasRole(rolesFromCookie, 'SUPPLIER');

  const handleSendQRCodeClick = async () => {
    getValuesLoading?.(true);
    const data = {
      phone: studentNumber,
    };
    const response = await sendPhoneQRCode(data, uuidStudent, uuidClass);
    if (response instanceof ErrorAPI) {
      navigate('/logout');
    }
    getValuesLoading?.(false);
    getValuesSnack?.(true, 'QR Code reenviado com sucesso!', 'success');
  };

  const handleSendByEmail = async () => {
    getValuesLoading?.(true);
    const response = await sendEmailQRCode(uuidStudent, uuidClass);
    if (response instanceof ErrorAPI) {
      navigate('/logout');
    }
    getValuesLoading?.(false);
    getValuesSnack?.(true, 'QR Code reenviado pelo e-mail!', 'success');
  };

  const handleCheckin = async () => {
    if (!uuidStudent || !uuidClass) {
      return;
    }

    getValuesLoading?.(true);

    const checkin: ICheckinRequest = {
      uuid_classroom: uuidClass,
      uuid_student: uuidStudent,
    };

    const response = await checkinStudents(checkin);

    if (response instanceof ErrorAPI) {
      if (response.statusCode === 401) {
        navigate('/logout');
      }

      if (response.statusCode === 400) {
        getValuesSnack?.(true, response.message, 'warning');
      }

      if (response.statusCode === 404) {
        /* empty */
      }
      if (response.statusCode === 500) {
        /* empty */
      }

      if (response.statusCode === 503) {
        /* empty */
      }
    } else {
      if (response) {
        getValuesSnack?.(true, response.data?.message, 'success');
      }
    }
    getValuesLoading?.(false);
  };

  const MenutItem = hasManagerRole ? ManagerMenu : SupplierMenu;
  return (
    <>
      <IconButton
        onClick={handleClick}
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{ p: '0px' }}
      >
        <Icon style={{ padding: '0px !important' }}>more_vert</Icon>
      </IconButton>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenutItem
          onClickSendQRCode={handleSendQRCodeClick}
          onClickSendEmail={handleSendByEmail}
          onClickSendCheckin={handleCheckin}
        />
      </Menu>
    </>
  );
};

export default PositionedMenu;
