/* eslint-disable prefer-const */
import { get, post, patch } from '../adapters/xhr';
import { IFiles } from '../../core/interfaces';
import { AxiosError, AxiosProgressEvent, AxiosResponse } from 'axios';
import ErrorAPI from '../../core/models/ErrorAPI';

export const ClassroomOperationFile = () => ({
  classSendFiles: async (
    classroom_id: string | undefined,
    files: File[],
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ) => {
    try {
      const fd = new FormData();
      files.forEach((file) => {
        fd.append('file', file);
      });

      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/classroom/operation/files/upload/${classroom_id}`,
        fd,
        undefined,
        onUploadProgress,
        'multipart/form-data',
      );
      return response.data as IFiles[];
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return [] as IFiles[];
    }
  },
  classDownloadFile: async (url: string, name: string) => {
    try {
      const response: AxiosResponse<Blob> = await get(
        url,
        undefined,
        undefined,
        {
          responseType: 'blob',
        },
      );
      const href: string = window.URL.createObjectURL(response.data);

      const anchorElement: HTMLAnchorElement = document.createElement('a');

      anchorElement.href = href;
      anchorElement.download = name;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return 500;
    }
  },
  classDeleteFiles: async (classroom_id: string | undefined, files: File) => {
    try {
      const data = {
        file: files,
      };

      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await patch(
        `${BaseURL}/api/v1/classroom/operation/files/upload/${classroom_id}`,
        data,
      );
      return response.data as IFiles[];
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return [] as IFiles[];
    }
  },
  classRenameFiles: async (classroom_id: string | undefined, files: File) => {
    try {
      const data = {
        file: files,
      };

      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await patch(
        `${BaseURL}/api/v1/classroom/live/files/upload/${classroom_id}/rename`,
        data,
      );
      return response.data as IFiles[];
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return [] as IFiles[];
    }
  },
  classSendLink: async (classroom_id: string | undefined, link: string) => {
    try {
      const data = {
        link: link,
      };

      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/classroom/create/link/upload/${classroom_id}`,
        data,
      );
      return response.data as IFiles[];
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return [] as IFiles[];
    }
  },
  classSendEventProof: async (
    classroom_id: string | undefined,
    files: File[],
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ) => {
    try {
      const fd = new FormData();
      files.forEach((file) => {
        fd.append('file', file);
      });

      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/classroom/operation/event/proof/${classroom_id}`,
        fd,
        undefined,
        onUploadProgress,
        'multipart/form-data',
      );
      return response.data as IFiles[];
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return [] as IFiles[];
    }
  },
  classDeleteEventProof: async (
    classroom_id: string | undefined,
    uuid_file_proof: string,
  ) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      const data = {
        uuid_file_proof: uuid_file_proof,
      };
      let response = await patch(
        `${BaseURL}/api/v1/classroom/operation/event/proof/${classroom_id}`,
        data,
      );
      return response.data as IFiles[];
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI: ErrorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }
        return errorAPI;
      }
      return [] as IFiles[];
    }
  },
});
