/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, ChangeEvent } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import { IStudent, IClassroom, IFiles } from '../../../core/interfaces';
import { searchStudents } from '../../../service/StudentService';

import BasicCardStudent from '../BasicCardStudent';
import ErrorAPI from '../../../core/models/ErrorAPI';
import style from './style.module.css';

import Loading from '../Loading';
import SnackBar from '../SnackBar';

import ListActionSpeed from './ListActionSpeed';
import FilesClassroom from './FilesClassroom';
import linkToFile from '../../../core/utils/linkToFile';
import { FileWithPreview } from '../../../core/types/TFilesWithPreview';
import base64toFile from '../../../core/utils/base64toFile';

interface FilterComponentProps {
  onFilterChange: (filter: string, filterType: string) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  onFilterChange,
}) => {
  const [filter, setFilter] = useState<string>('');
  const [filterType, setFilterType] = useState<string>('name'); // default filter type

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFilter = event.target.value;
    setFilter(newFilter);
    onFilterChange(newFilter, filterType);
  };

  const handleFilterTypeChange = (event: SelectChangeEvent<string>) => {
    const newFilterType = event.target.value as string;
    setFilterType(newFilterType);
    onFilterChange(filter, newFilterType);
  };

  return (
    <Box
      mb={2}
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      justifyContent="space-between"
      alignItems="center"
      gap={2}
    >
      <FormControl variant="outlined" style={{ minWidth: 150 }}>
        <InputLabel id="filter-type-label">Filtrar por</InputLabel>
        <Select
          labelId="filter-type-label"
          value={filterType}
          onChange={handleFilterTypeChange}
          label="Filtrar por"
        >
          <MenuItem value="name">Nome</MenuItem>
          <MenuItem value="phone">Telefone</MenuItem>
          <MenuItem value="cpf">CPF</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Pesquisar"
        variant="outlined"
        value={filter}
        onChange={handleInputChange}
        fullWidth
      />
    </Box>
  );
};

const InformationClassroom: React.FunctionComponent = () => {
  const { id_class = '/' } = useParams<'id_class'>();
  const navigate = useNavigate();

  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');

  const [openLoading, setOpenLoading] = useState<boolean>(false);

  const [rowsFiles, setRowsFiles] = useState<IFiles[]>([]);
  const [filesProve, setFilesProve] = useState<FileWithPreview[]>([]);
  const [students, setStudent] = useState<Array<IStudent>>([]);
  const [filteredStudents, setFilteredStudents] = useState<Array<IStudent>>([]);
  const [classroom, setClassroom] = useState<IClassroom>({} as IClassroom);

  const [filter, setFilter] = useState<string>('');
  const [filterType, setFilterType] = useState<string>('name'); // default filter type

  const handleGetValuesLoading = (value: boolean) => {
    setOpenLoading(value);
  };

  const handleGetValuesSnack = (
    value: boolean,
    message: string,
    severity: string,
  ) => {
    setSnackbarOpen(value);
    setSnackbarSeverity(severity as 'success' | 'info' | 'warning' | 'error');
    setSnackbarMessage(message);
  };

  useEffect(() => {
    async function serchDataAPI() {
      const dataAPI = await searchStudents(id_class);

      if (dataAPI instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setStudent(dataAPI.students);
        setFilteredStudents(dataAPI.students); // Default to all students
        setClassroom(dataAPI.classroom);
        setRowsFiles(dataAPI.classroom.files!);
        const filesPromises = dataAPI.classroom_event_proof_file.map(
          async (value) => {
            return await base64toFile(value.path_file, value.midia_name);
          },
        );

        const files = await Promise.all(filesPromises);
        setFilesProve(files as FileWithPreview[]);
      }
    }
    serchDataAPI();
  }, [id_class, navigate]);

  useEffect(() => {
    const filteredData = students.filter((student) => {
      if (filter === '') {
        return true; // No filter applied, return all students
      }
      if (filterType === 'name') {
        return student.name.toLowerCase().includes(filter.toLowerCase());
      }
      if (filterType === 'phone') {
        return student.whatsapp?.toString().includes(filter);
      }
      if (filterType === 'cpf') {
        return student.CPF?.toString().includes(filter);
      }
      return false;
    });
    setFilteredStudents(filteredData);
  }, [filter, filterType, students]);

  const handleFilterChange = (newFilter: string, newFilterType: string) => {
    setFilter(newFilter);
    setFilterType(newFilterType);
  };
  return (
    <>
      <Box className={style.sectionHeader} mb={3}>
        <Card className={style.cardHeader}>
          <CardContent>
            <List dense={true} className={style.styleHeaderList}>
              <ListItem disablePadding>
                <ListItemText
                  primary="Código da turma"
                  secondary={classroom.code_classroom}
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary="Tipo de solução"
                  secondary={
                    classroom.is_collective ? 'Coletiva' : 'Individual'
                  }
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary="Nome da solução"
                  secondary={classroom.solution_name}
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary="Estudantes são elegíveis para o certificado se tiverem um número de check-ins maior ou igual ao valor abaixo"
                  secondary={classroom.checkins_threshold}
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary="Quantidade de inscritos"
                  secondary={classroom.number_of_students}
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary="Quantidade de Check-in realizado"
                  secondary={classroom.num_students_checked_in}
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary="É grátis ?"
                  secondary={classroom.is_free ? 'Sim' : 'Não'}
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary="Local"
                  secondary={
                    classroom.place ? classroom.place : 'Não informado'
                  }
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Box>
      <ListActionSpeed
        students={students}
        url_qrcode={classroom.url_qrcode}
        defaultFileProofWithPreview={filesProve}
      />
      <FilesClassroom filesDefault={rowsFiles} />
      <Box m={2}>
        <FilterComponent onFilterChange={handleFilterChange} />
      </Box>
      <Grid container spacing={3} className={style.gridStudent}>
        {filteredStudents.map((element) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={element.id}>
            <BasicCardStudent
              uuidClassroom={id_class}
              classroomRequireCertificate={
                classroom.require_certificate
                  ? classroom.require_certificate
                  : false
              }
              classroomFinished={classroom.finished_event}
              student={element}
              getValuesLoading={handleGetValuesLoading}
              getValuesSnack={handleGetValuesSnack}
              checkin_required={
                classroom.checkins_threshold ? classroom.checkins_threshold : 0
              }
            />
          </Grid>
        ))}
      </Grid>
      <Loading open={openLoading} />
      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
    </>
  );
};

export default InformationClassroom;
