import { Routes, Route } from 'react-router-dom';
import Certificate from './ui/pages/Certificate';
import ListCertificate from './ui/pages/ListCertificate';

const PublicRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/certificate/:id_certificate"
        element={<Certificate />}
      ></Route>
      <Route path="/certificado" element={<ListCertificate />}></Route>
    </Routes>
  );
};

export default PublicRoutes;
