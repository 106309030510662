/* eslint-disable prefer-const */
import { AxiosError } from 'axios';
import { IMessageDefault } from '../../core/interfaces';
import { get, patch } from '../adapters/xhr';
import ErrorAPI from '../../core/models/ErrorAPI';

export const messageDefaultAPI = () => ({
  getMessage: async () => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await get(`${BaseURL}/api/v1/get/message/default`);
      if (response.status !== 200) {
        return [] as IMessageDefault[];
      }
      return response.data as IMessageDefault[];
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      console.error('error', error);
      return [] as IMessageDefault[];
    }
  },
  updateMessage: async (data: object) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await patch(`${BaseURL}/api/v1/get/message/default`, data);
      if (response.status !== 200) {
        return {} as IMessageDefault;
      }
      return response.data as IMessageDefault;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      console.error('error', error);
      return {} as IMessageDefault;
    }
  },
});
