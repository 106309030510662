import { IPlacePerformance } from '../core/interfaces';
import { PlacePerformanceAPI } from '../infra/api/PlacePerformance';

export async function getAllPlacePerformance() {
  const instaceInfra = PlacePerformanceAPI();
  const placePerformance = await instaceInfra.getAllPlacePerformance();

  return placePerformance;
}

export async function createPlacePerformance(data: IPlacePerformance) {
  const instaceInfra = PlacePerformanceAPI();
  const placePerformance = await instaceInfra.createPlacePerformance(data);

  return placePerformance;
}

export async function deletePlacePerformance(id: string) {
  const instaceInfra = PlacePerformanceAPI();
  const placePerformance = await instaceInfra.deletePlacePerformance(id);

  return placePerformance;
}

export async function getPlacePerformanceCurrentClassroom(id: string) {
  const instaceInfra = PlacePerformanceAPI();
  const placePerformance =
    await instaceInfra.getPlacePerformanceCurrentClassroom(id);

  return placePerformance;
}

export async function getPlacePerformanceByID(id: string) {
  const instaceInfra = PlacePerformanceAPI();
  const placePerformance = await instaceInfra.getPlacePerformanceByID(id);

  return placePerformance;
}

export async function placePerformanceUpdateByID(id: string, data: object) {
  const instaceInfra = PlacePerformanceAPI();
  const placePerformance = await instaceInfra.placePerformanceUpdateByID(
    id,
    data,
  );

  return placePerformance;
}
