import { userAPI } from '../infra/api/userAPI';

export async function getAlluser() {
  const instaceInfra = userAPI();
  const users = await instaceInfra.getAlluser();

  return users;
}

export async function updateUser(user_id: number, data: object) {
  const instaceInfra = userAPI();
  const user = await instaceInfra.updateUser(user_id, data);

  return user;
}

export async function getInforUser() {
  const instaceInfra = userAPI();
  const user = await instaceInfra.getInforUser();

  return user;
}

export async function getPermissionsUser() {
  const instaceInfra = userAPI();
  const user = await instaceInfra.getPermissionsUser();

  return user;
}

export async function createUser(data: object) {
  const instaceInfra = userAPI();
  const user = await instaceInfra.createUser(data);

  return user;
}
