/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import SnackBar from '../SnackBar';
import ErrorAPI from '../../../core/models/ErrorAPI';
import { useNavigate } from 'react-router-dom';
import { getAllPlacePerformance } from '../../../service/PlacePerformance';
import { IPlacePerformance, ISebrae } from '../../../core/interfaces';
import Loading from '../Loading';
import { getAllsebrae } from '../../../service/SebraeService';

interface ModalSelectLocationProps {
  open: boolean;
  onClose: (selectedLocation: string) => void;
}

const ModalSelectLocation: React.FC<ModalSelectLocationProps> = ({
  open,
  onClose,
}) => {
  const [location, setLocation] = useState<string>('');
  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [sebraes, setSebraes] = useState<ISebrae[]>([]);
  const [sebrae, setSebrae] = useState<ISebrae>({} as ISebrae);
  const navigate = useNavigate();
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setLocation(event.target.value as string);
  };
  const [placePerformance, setPlacePerformance] = useState<IPlacePerformance[]>(
    [] as IPlacePerformance[],
  );
  const [allPlacePerformance, setallPlacePerformance] = useState<
    IPlacePerformance[]
  >([] as IPlacePerformance[]);
  const handleConfirm = () => {
    onClose(location);
    setLocation('');
    navigate(`/information/place/performance/${location}`);
  };

  const handleGetValuesSnack = (
    value: boolean,
    message: string,
    severity: string,
  ) => {
    setSnackbarOpen(value);
    setSnackbarSeverity(severity as 'success' | 'info' | 'warning' | 'error');
    setSnackbarMessage(message);
  };

  useEffect(() => {
    const getData = async () => {
      setOpenLoading(true);
      try {
        const [response, responseSebraes] = await Promise.all([
          getAllPlacePerformance(),
          getAllsebrae(),
        ]);

        if (response instanceof ErrorAPI) {
          if (response.statusCode === 401) {
            navigate('/logout');
            return;
          }

          if (response.statusCode === 500) {
            handleGetValuesSnack(true, response.message, 'warning');
            return;
          }
        } else {
          setPlacePerformance(response);
          setallPlacePerformance(response);
        }

        if (responseSebraes instanceof ErrorAPI) {
          handleGetValuesSnack(true, responseSebraes.message, 'warning');
        } else {
          setSebraes(responseSebraes);
        }
      } catch (error) {
        handleGetValuesSnack(true, 'An unexpected error occurred.', 'error');
      } finally {
        setOpenLoading(false);
      }
    };

    getData();
  }, [location]);

  const handleChangeOriginSebrae = (event: SelectChangeEvent) => {
    const typeSelect = event.target.value as string;
    const sebraeOrigin = sebraes?.find((value) => value.id === typeSelect);
    if (sebraeOrigin) {
      const place_performance = allPlacePerformance?.filter(
        (value) => value.sebrae_obj?.id === typeSelect,
      );
      setSebrae(sebraeOrigin);
      setPlacePerformance(place_performance);
    }
  };

  return (
    <>
      <Modal open={open} onClose={() => onClose('')}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={2}
          bgcolor="background.paper"
          borderRadius={1}
          maxWidth={400}
          width="90%" // Responsivo
          position="absolute"
          top="50%"
          left="50%"
          sx={{ transform: 'translate(-50%, -50%)' }}
        >
          <Typography variant="h6" gutterBottom>
            Selecione o Local que Deseja Monitorar
          </Typography>
          <FormControl style={{ margin: '1rem' }} fullWidth>
            <InputLabel id="originSebrae">Sebrae de origem</InputLabel>
            <Select
              labelId="originSebrae"
              id="originSebrae"
              name="originSebrae"
              label="Sebrae de origem"
              onChange={handleChangeOriginSebrae}
              value={sebrae.id}
            >
              {sebraes?.map((value) => (
                <MenuItem key={value.id} value={value.id}>
                  {value.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Select value={location} onChange={handleSelectChange} fullWidth>
            {placePerformance.map((value, index) => (
              <MenuItem key={index} value={value.uuid}>
                {value.name}
              </MenuItem>
            ))}
          </Select>
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
          >
            Confirmar
          </Button>
        </Box>
      </Modal>
      <Loading open={openLoading} />
      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
    </>
  );
};

export default ModalSelectLocation;
