export default function base64toFile(
  base64: string,
  fileName: string,
): File | null {
  const regex = /^data:(.+);base64,(.+)/;
  const match = base64.match(regex);

  if (!match || match.length !== 3) {
    console.error('String base64 inválida.');
    return null;
  }

  const mimeType = match[1];
  const base64Data = match[2];
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });

  // Extrair o nome do arquivo do tipo MIME (exemplo: "image/png" -> "imagem.png")
  //const fileExtension = mimeType.split('/')[1];
  // const fileName = `arquivo.${fileExtension}`;

  return new File([blob], fileName, { type: mimeType });
}
