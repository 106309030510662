import style from './style.module.css';
import { Link } from 'react-router-dom';
const NotFoundPage = () => {
  return (
    <>
      <div className={style.not_found_context}>
        <div className={style.not_found_container}>
          <h1>404</h1>
          <h2>Página não encontrada</h2>
          <p>
            A página que você procura pode ter sido removida, teve seu nome
            alterado ou está temporariamente indisponível.
          </p>
          <Link className={style.home_page_button} to="/">
            Página inicial
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
