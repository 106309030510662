import { useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DrawerProvider } from '../../contexts';
import Header from '../../components/Header';
import SideBar from '../../components/Drawer';
import TabPanel from '../../components/TabPanel';
import ListClassroom from '../ListClassroom';
import ListCompositeEvent from '../ListCompositeEvent';
import ListClassroomsIntegraInBuild from '../ListClassroomsIntegraInBuild';

export default function ListEvents() {
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <DrawerProvider>
      <SideBar>
        <Header title="Meus eventos" />
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <Tabs
            allowScrollButtonsMobile
            scrollButtons
            variant="scrollable"
            value={value}
            onChange={handleChange}
          >
            <Tab label="Minhas salas" />
            <Tab label="Grandes eventos" />
            <Tab label="Eventos do SAS em construção" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <ListClassroom />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ListCompositeEvent />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ListClassroomsIntegraInBuild />
          </TabPanel>
        </Box>
      </SideBar>
    </DrawerProvider>
  );
}
