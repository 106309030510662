/* eslint-disable prefer-const */
import { AxiosError } from 'axios';
import { IShiftsOperations } from '../../core/interfaces';
import { get, patch, post, remove } from '../adapters/xhr';
import ErrorAPI from '../../core/models/ErrorAPI';

export const shiftsAPI = () => ({
  getAllShifts: async () => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await get(`${BaseURL}/api/v1/operation/shifts`);
      if (response.status !== 200) {
        return [] as Array<IShiftsOperations>;
      }
      return response.data as Array<IShiftsOperations>;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      console.error('error', error);
      return [] as Array<IShiftsOperations>;
    }
  },
  updateShifts: async (data: object) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await patch(`${BaseURL}/api/v1/operation/shifts`, data);
      if (response.status !== 200) {
        return [] as Array<IShiftsOperations>;
      }
      return response.data as Array<IShiftsOperations>;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      console.error('error', error);
      return [] as Array<IShiftsOperations>;
    }
  },
  postShifts: async (data: object) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(`${BaseURL}/api/v1/operation/shifts`, data);
      if (response.status !== 200) {
        return {} as IShiftsOperations;
      }
      return response.data as IShiftsOperations;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      console.error('error', error);
      return {} as IShiftsOperations;
    }
  },
  deleteShifts: async (id: string) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await remove(`${BaseURL}/api/v1/?id=${id}`);
      if (response.status !== 204) {
        return {} as IShiftsOperations;
      }
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
        return error.response?.status;
      }
      console.error('error', error);
      return {} as IShiftsOperations;
    }
  },
});
