import style from './style.module.css';
import Select, { SingleValue } from 'react-select';
type getValue = (value: string) => void;
const FilterPerStatus: React.FC<{ getValue: getValue }> = ({ getValue }) => {
  const options = [
    { value: 'validation_not_started', label: 'Não Iniciada' },
    { value: 'validation_started', label: 'Iniciada' },
    { value: 'finished_event', label: 'Finalizada' },
  ];
  const handleChangeFilter = (
    newValue: SingleValue<{ value: string; label: string }>,
  ) => {
    if (newValue === null) {
      getValue('');
    } else {
      getValue(newValue.value);
    }
  };

  return (
    <>
      <div className={style.container}>
        <Select
          options={options}
          className={style.select}
          isSearchable={false}
          onChange={handleChangeFilter}
          defaultValue={{
            label: 'Não Iniciada',
            value: 'validation_not_started',
          }}
        />
      </div>
    </>
  );
};

export default FilterPerStatus;
