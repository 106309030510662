import { messageDefaultAPI } from '../infra/api/MessageDefault';

export async function getAllMessage() {
  const instaceInfra = messageDefaultAPI();
  const message = await instaceInfra.getMessage();

  return message;
}

export async function updateMessage(data: object) {
  const instaceInfra = messageDefaultAPI();
  const message = await instaceInfra.updateMessage(data);

  return message;
}
