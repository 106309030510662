/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Button,
} from '@mui/material';

import SnackBar from '../../../components/SnackBar';
import Loading from '../../../components/Loading';

import { ISupplier } from '../../../../core/interfaces';
import { getDataUser } from '../../../../service/SupplierService';
import ErrorAPI from '../../../../core/models/ErrorAPI';
import { isEmpty } from '../../../../core/utils';
import TextMaskCustomCPF from '../../../components/TextMaskCustomCPF';

interface IPropsSearchSupplier {
  getValue: (value: ISupplier) => void;
  openDialogSupplier: boolean;
  onClose: () => void;
}
type Serverity = 'success' | 'info' | 'warning' | 'error';

const SearchSupplier: React.FC<IPropsSearchSupplier> = ({
  openDialogSupplier,
  onClose,
  getValue,
}) => {
  const navigate = useNavigate();
  const [cpf, setCPF] = useState<string>('');
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<Serverity>('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const [openLoading, setOpenLoading] = useState<boolean>(false);

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCPF(event.target.value as string);
  };

  const handleSaveCpf = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);

    const cpf = formData.get('cpf')?.toString();

    if (!cpf) {
      return;
    }

    setOpenLoading(true);
    const responseSupplier = await getDataUser(cpf);
    setOpenLoading(false);
    if (responseSupplier instanceof ErrorAPI) {
      navigate('/logout');
    } else {
      const newDataSupplier: ISupplier = responseSupplier;
      if (isEmpty(newDataSupplier)) {
        setSnackbarOpen(true);
        setSnackbarMessage('CPF Inválido ou Dados Não Encontrados.');
        setSnackbarSeverity('warning');
        return;
      }
      setCPF('');
      getValue(newDataSupplier);
    }
  };

  return (
    <>
      <Loading open={openLoading} />
      <Dialog open={openDialogSupplier} onClose={onClose}>
        <DialogTitle>
          Informe o CPF para prosseguir com as etapas seguintes
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para que possamos complementar os dados referentes ao consultor, é
            necessário que você forneça o CPF dele. Por gentileza, informe-nos o
            CPF do usuário
          </DialogContentText>
          <Box component="form" onSubmit={handleSaveCpf} noValidate>
            <TextField
              id="cpf"
              name="cpf"
              autoFocus
              label="CPF"
              variant="outlined"
              type="text"
              margin="dense"
              fullWidth
              onChange={handleChange}
              value={cpf}
              InputProps={{
                inputComponent: TextMaskCustomCPF as any,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit">
                      <Icon>{'search'}</Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={handleCloseSnackBar}
      />
    </>
  );
};

export default SearchSupplier;
