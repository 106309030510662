/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextField, Button, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import * as z from 'zod';
import styles from './styles.module.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Definição do esquema de validação para datas
const dateSchema = z
  .string()
  .refine((val) => dayjs(val).isValid(), { message: 'Data inválida' });

// Definição do esquema de validação para o formulário
const formSchema = z.object({
  name: z.string().optional(),
  dates: z
    .object({
      initial_date: dateSchema,
      final_date: dateSchema,
    })
    .refine(
      (data) =>
        dayjs(data.initial_date).isSame(dayjs(data.final_date)) ||
        dayjs(data.initial_date).isBefore(dayjs(data.final_date)),
      {
        message: 'A data inicial não pode ser maior que a data final',
        path: ['dates', 'initial_date'], // Ajusta a mensagem de erro conforme necessário
      },
    ),
});
type IFormInput = z.infer<typeof formSchema>;

// Definição da interface para as props do componente
interface IPropsListEventIntegra {
  onSendCallback: (data: IFormInput) => void;
}

// Componente HeaderListEventIntegra
const HeaderListEventIntegra: React.FC<IPropsListEventIntegra> = ({
  onSendCallback,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      dates: {
        initial_date: dayjs().format('YYYY-MM-DD'),
        final_date: dayjs().format('YYYY-MM-DD'),
      },
    },
  });

  const [dataInitError, setDataInitError] = React.useState(false);
  const [dataEndError, setDataEndError] = React.useState(false);

  // Função para submissão do formulário
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    onSendCallback(data);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
        <Grid container spacing={2}>
          {/* Input de texto */}
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nome do evento"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="dates.initial_date"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Data Inicial"
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      error={dataInitError || !!errors.dates?.initial_date}
                      helperText={
                        dataInitError
                          ? 'Data inválida ou ano menor que 1900'
                          : errors.dates?.initial_date?.message
                      }
                    />
                  )}
                  onChange={(
                    newValue:
                      | string
                      | number
                      | Date
                      | dayjs.Dayjs
                      | null
                      | undefined,
                  ) => {
                    const isValidDate = newValue && dayjs(newValue).isValid();
                    const isYearValid = dayjs(newValue).year() >= 1900;

                    if (isValidDate && isYearValid) {
                      // Formata e armazena a data em "YYYY-MM-DD"
                      field.onChange(dayjs(newValue).format('YYYY-MM-DD'));
                      setDataInitError(false);
                    } else {
                      setDataInitError(true);
                    }
                  }}
                />
              )}
            />
            <Controller
              name="dates.final_date"
              control={control}
              render={({ field: { onChange, value, ...fieldProps } }) => (
                <DatePicker
                  {...fieldProps}
                  value={value}
                  label="Data Final"
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      error={!!errors.dates?.final_date || dataEndError}
                      helperText={
                        dataEndError
                          ? 'Data Final deve ser após a Data Inicial'
                          : errors.dates?.final_date?.message
                      }
                    />
                  )}
                  onChange={(
                    newValue:
                      | string
                      | number
                      | Date
                      | dayjs.Dayjs
                      | null
                      | undefined,
                  ) => {
                    const isValidDate = newValue && dayjs(newValue).isValid();
                    if (isValidDate) {
                      onChange(dayjs(newValue).format('YYYY-MM-DD'));
                      setDataEndError(false);
                    } else {
                      setDataEndError(true);
                    }
                  }}
                />
              )}
            />
          </Grid>

          {/* Botão de submit */}
          <Grid item xs={12} className={styles.buttonContainer}>
            <Button variant="contained" type="submit">
              Buscar turmas
            </Button>
          </Grid>
        </Grid>
      </form>
    </LocalizationProvider>
  );
};

export default HeaderListEventIntegra;
