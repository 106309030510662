/* eslint-disable prefer-const */
import { post } from '../adapters/xhr';
import { IClassroom } from '../../core/interfaces';
import { AxiosError } from 'axios';
import ErrorAPI from '../../core/models/ErrorAPI';

export const ClassroomOperationGenerate = () => ({
  generateClassroomAPI: async (classroom: IClassroom) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/classroom/create`,
        classroom,
      );
      if (response.status !== 201) {
        return {} as IClassroom;
      }
      return response.data as IClassroom;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      console.error('error', error);
      return {} as IClassroom;
    }
  },
});
