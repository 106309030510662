import TextEditor from './../TextEditor';
import { Typography } from '@mui/material';
import Button from '../../../../components/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { IMessageDefault } from '../../../../../core/interfaces';
import React, { useState } from 'react';
import Item from '../Item';
import { useNavigate } from 'react-router-dom';
import SnackBar from '../../../../components/SnackBar';
import { updateMessage } from '../../../../../service/MessageDefaultService';
import ErrorAPI from '../../../../../core/models/ErrorAPI';
interface IProposMessageItem {
  id: string;
  title: string;
  description: string;
  messageType: string;
  onTextChange: (text: string) => void;
  data: string;
}

const MessageItem: React.FC<IProposMessageItem> = ({
  id,
  title,
  description,
  messageType,
  onTextChange,
  data,
}) => {
  const [messages, setMessages] = useState<IMessageDefault>(
    {} as IMessageDefault,
  );
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const navigate = useNavigate();
  const handleChange = (newText: string, htmlText: string) => {
    setDisableButton(false);
    onTextChange(newText);
    const data: IMessageDefault = {
      uuid: id,
      message_email: htmlText,
      message_whatsapp: newText,
      message_type: messageType,
    };
    setMessages(data);
  };
  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  const handleUpdateText = async () => {
    const newMessage = await updateMessage(messages);

    if (newMessage instanceof ErrorAPI) {
      navigate('/logout');
    } else {
      setSnackbarOpen(true);
      setSnackbarSeverity('success');
      setSnackbarMessage('Message Atualizada com sucesso');
    }
  };

  return (
    <Item>
      <Box
        sx={{
          p: 2,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
        </Stack>
        <Typography color="text.secondary" variant="body2">
          {description}
        </Typography>
      </Box>
      <TextEditor data={data} onTextChange={handleChange} />
      <Box
        sx={{
          m: '1rem',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Button
          variant="contained"
          label="Atualizar"
          disabled={disableButton}
          onClick={handleUpdateText}
        />
      </Box>
      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={handleCloseSnackBar}
      />
    </Item>
  );
};

export default MessageItem;
