import { Grid } from '@mui/material';
import BasicCardClassroom from '../BasicCardClassroom';
import { IClassroom } from '../../../core/interfaces';
interface IPropsCardsClassroom {
  classroom: IClassroom[];
}
const CardsClassroom: React.FunctionComponent<IPropsCardsClassroom> = ({
  classroom,
}) => {
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {classroom.map((element) => (
          <BasicCardClassroom
            classroom={element}
            key={classroom.indexOf(element)}
          />
        ))}
      </Grid>
    </>
  );
};

export default CardsClassroom;
