import { useState } from 'react';
import { Card, CardContent, Divider } from '@material-ui/core';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  CardActions,
  Button,
  Icon,
  IconButton,
  Modal,
  Box,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import style from './style.module.css';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { IClassroom } from '../../../../core/interfaces';
import { classroomInitValidate } from '../../../../service/ClassroomService';
import ErrorAPI from '../../../../core/models/ErrorAPI';
import QRcode from '../../QRcode';
import PositionedMenuOptionClassroom from '../../PositionedMenuOptionClassroom';
import Loading from '../../Loading';

interface IBasicardClassroomCompositeEvent {
  classroom: IClassroom;
  is_main: boolean;
}
const BasicardClassroomCompositeEvent: React.FunctionComponent<
  IBasicardClassroomCompositeEvent
> = ({ classroom, is_main }) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [openLoading, setOpenLoading] = useState<boolean>(false);

  const {
    uuid,
    is_collective,
    solution_name,
    url_qrcode,
    validation_started,
    suppliers,
    number_of_students,
    is_free,
    place,
    num_students_checked_in,
    finished_event,
    is_the_class_closed,
    code_classroom,
  } = classroom;

  const copyLink = () => {
    const hostname = window.location.hostname;
    navigator.clipboard.writeText(
      `${hostname}/classroom/detail/${classroom.uuid}`,
    );
    handleShareModalClose();
  };

  const handleModal = () => setOpenModal(!openModal);

  const handleDialog = () => {
    if (classroom.validation_started) {
      navigate(`/classroom/detail/${uuid}`, {
        state: {
          is_free: is_free,
          is_collective: is_collective,
          is_the_class_closed: is_the_class_closed,
        },
      });
    }
    setOpenDialog(!openDialog);
  };

  const handleDialogConfirm = async () => {
    const statusCode = await classroomInitValidate(classroom.uuid, true);
    if (statusCode instanceof ErrorAPI) {
      navigate('/logout');
    } else {
      navigate(`/classroom/detail/${uuid}`, {
        state: {
          is_free: is_free,
          is_collective: is_collective,
        },
      });
    }
  };

  const handleShareLink = React.useCallback(() => {
    setShareModalOpen(true);
  }, []);

  const handleShareModalClose = () => {
    setShareModalOpen(false);
  };

  return (
    <Grid item xs={12} sm={6} md={3} key={uuid} className={style.styleGrid}>
      <Dialog
        open={openDialog}
        onClose={handleDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Confirmação da Sala: Tem certeza que deseja continuar?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem certeza de que deseja iniciar a confirmação da sala?
            Lembre-se de que, uma vez iniciado o processo, não será possível
            voltar atrás. Caso esteja seguro(a) de sua decisão, prossiga com a
            confirmação.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialog}>Não</Button>
          <Button onClick={handleDialogConfirm} autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <Modal open={openModal} onClose={handleModal}>
        <Box className={style.styleBox}>
          <QRcode url_qrcode={url_qrcode} />
        </Box>
      </Modal>

      <Modal open={shareModalOpen} onClose={handleShareModalClose}>
        <Box className={style.modalBox}>
          <Box className={style.shareWithBox}>
            <Typography className={style.shareWithTitle} variant="h6">
              {' '}
              Compartilhar{' '}
            </Typography>
            <Button
              variant="outlined"
              className={`${style.shareBtn} ${style.shareBtnCopy}`}
              onClick={copyLink}
              startIcon={<Icon className={style.shareBtnIcon}>shared</Icon>}
            >
              {' '}
              Copiar link{' '}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Card className={style.card}>
        <CardContent className={style.cardContent}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PositionedMenuOptionClassroom
              getValuesLoading={(value) => {
                setOpenLoading(value);
              }}
              classroom={classroom}
            />
            <span
              className={style.spanValidate}
              style={
                finished_event
                  ? { color: 'blue' }
                  : validation_started
                    ? { color: 'green' }
                    : { color: 'red' }
              }
            >
              {finished_event
                ? 'Turma Finalizada'
                : validation_started
                  ? 'Inicializada'
                  : 'Não Inicializada'}
            </span>
          </div>

          <List dense={true} className={style.styleList}>
            {is_main && (
              <ListItem disablePadding>
                <ListItemText primary="Evento Principal" />
              </ListItem>
            )}

            <ListItem disablePadding>
              <ListItemText
                primary="Código da turma"
                secondary={code_classroom}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Tipo de solução"
                secondary={is_collective ? 'Coletiva' : 'Individual'}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Nome da solução"
                secondary={solution_name}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Fornecedores Responsáveis pela Turma"
                secondary={suppliers.reduce((acc: string[], suppliers) => {
                  return acc.concat(`${suppliers.name} | `);
                }, [])}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Quantidade de inscritos"
                secondary={number_of_students}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Quantidade de Check-in realizado"
                secondary={num_students_checked_in}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="É grátis ?"
                secondary={is_free ? 'Sim' : 'Não'}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Local"
                secondary={place ? place : 'Não informado'}
              />
            </ListItem>
          </List>

          <Divider />

          <CardActions disableSpacing className={style.cardAction}>
            <IconButton
              onClick={handleModal}
              aria-label="Mostrar o QRCode da truma"
            >
              <Icon style={{ marginLeft: '0px' }}>qr_code</Icon>{' '}
              <span>QR Code</span>
            </IconButton>
            <IconButton
              aria-label="Compartilhar o QRCode da turma"
              onClick={handleShareLink}
              style={{ width: '50%' }}
            >
              <Icon>shared</Icon> <span>Compartilhar</span>
            </IconButton>
            <div className={style.divBttn}>
              <Button
                id={style.bttnAction}
                onClick={handleDialog}
                variant="contained"
                size="small"
              >
                {validation_started
                  ? 'Visualizar alunos'
                  : 'Iniciar confirmação'}
              </Button>
            </div>
          </CardActions>
        </CardContent>
      </Card>
      <Loading open={openLoading} />
    </Grid>
  );
};

export default BasicardClassroomCompositeEvent;
