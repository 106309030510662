/* eslint-disable prefer-const */
import { AxiosError } from 'axios';
import {
  IStudentsClassroom,
  ICheckinRequest,
  IStudent,
  IStudentCertificate,
} from '../../core/interfaces';
import { get, post, patch } from '../adapters/xhr';
import ErrorAPI from '../../core/models/ErrorAPI';

export const studentAPI = () => ({
  searchStudents: async (uuidClassroom: string) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await get(
        `${BaseURL}/api/v1/classroom/by/${uuidClassroom}`,
      );
      if (response.status !== 200) {
        return {} as IStudentsClassroom;
      }
      return response.data as IStudentsClassroom;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      console.error('error', error);
      return {} as IStudentsClassroom;
    }
  },
  checkinStudents: async (checkin: ICheckinRequest) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/operation/student/checkin`,
        checkin,
      );
      return response;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI: ErrorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.message);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }
        return errorAPI;
      }
      return {} as ErrorAPI;
    }
  },
  searchUserForDocument: async (document: string, typeDocument: string) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;

      const data = {
        document: document,
        typeDocument: typeDocument,
      };

      let response = await post(
        `${BaseURL}/api/v1/operation/student/list/user`,
        data,
      );
      if (response.status !== 200) {
        return [] as Array<IStudent>;
      }

      return response.data as Array<IStudent>;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }

      return [] as Array<IStudent>;
    }
  },
  studentUpdate: async (student_id: string | undefined, data: object) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await patch(
        `${BaseURL}/api/v1/operation/student/edit/${student_id}`,
        data,
      );
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
    }
  },
  studentInfo: async (data: object) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/operation/student/classroom/information`,
        data,
      );
      return response.data as IStudent;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          try {
            const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
            let response = await post(
              `${BaseURL}/api/v1/operation/student/classroom/information`,
              data,
            );
            return response.data as IStudent;
          } catch (error) {
            if (error instanceof AxiosError) {
              if (error.response?.status === 401) {
                const response = new ErrorAPI(
                  error.response?.status,
                  error.response.statusText,
                );
                return response;
              }
            }
            return {} as IStudent;
          }
          return response;
        }
      }
      return {} as IStudent;
    }
  },
  sendPhoneQRCode: async (
    data: object,
    id_student?: string,
    id_classroom?: string,
  ) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/operation/student/create/qrcode/phone/${id_student}/${id_classroom}`,
        data,
      );
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return 500;
    }
  },
  sendEmailQRCode: async (id_student?: string, id_classroom?: string) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/operation/student/create/qrcode/email/${id_student}/${id_classroom}`,
      );
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return 500;
    }
  },
  getCertificateStudent: async (id_certificate: string) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await get(
        `${BaseURL}/api/v1/operation/student/certificate/${id_certificate}`,
      );

      return response.data as IStudentCertificate;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return {} as IStudentCertificate;
    }
  },
  sendCertificate: async (id_student?: string, id_classroom?: string) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/operation/student/create-certificate/${id_student}/${id_classroom}`,
      );
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return 500;
    }
  },
  getCertificatesFromCPF: async (data: object) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/operation/student/certificates/list`,
        data,
      );
      return response.data as IStudentCertificate[];
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI: ErrorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 409:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }

        return errorAPI;
      }

      return [] as IStudentCertificate[];
    }
  },
});
