/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Button, TextField, InputAdornment } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PersonIcon from '@mui/icons-material/Person';
import TextMaskCustomCPF from '../TextMaskCustomCPF';

// Definição do esquema Zod
const schema = z.object({
  firstName: z.string().min(1, 'Este campo é obrigatório'),
  lastName: z.string().min(1, 'Este campo é obrigatório'),
  cpf: z
    .string()
    .regex(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
    .min(1, 'Este campo é obrigatório'),
  email: z.string().email('E-mail inválido').min(1, 'Este campo é obrigatório'),
  username: z.string().min(1, 'Este campo é obrigatório'),
  password: z.string().min(1, 'Este campo é obrigatório'),
});

// Props do Componente
interface IFormInput {
  firstName: string;
  lastName: string;
  cpf: string;
  email: string;
  username: string;
  password: string;
}

const FormCreateUser: React.FC<{ onChange: (data: IFormInput) => void }> = ({
  onChange,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    onChange(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginBottom: '1rem',
          alignContent: 'space-evenly',
        }}
      >
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              style={{
                marginLeft: '1rem',
                marginRight: '1rem',
                marginTop: '1rem',
              }}
              label="Primeiro nome"
              variant="standard"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PermIdentityIcon />
                  </InputAdornment>
                ),
              }}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
            />
          )}
        />

        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Segundo nome"
              variant="standard"
              fullWidth
              style={{
                marginLeft: '1rem',
                marginRight: '1rem',
                marginTop: '1rem',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PermIdentityIcon />
                  </InputAdornment>
                ),
              }}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
            />
          )}
        />

        <Controller
          name="cpf"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="CPF"
              variant="standard"
              fullWidth
              style={{
                marginLeft: '1rem',
                marginRight: '1rem',
                marginTop: '1rem',
              }}
              InputProps={{
                inputComponent: TextMaskCustomCPF as any,
                startAdornment: (
                  <InputAdornment position="start">
                    <AssignmentIndIcon />
                  </InputAdornment>
                ),
              }}
              error={!!errors.cpf}
              helperText={errors.cpf?.message}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="E-mail"
              variant="standard"
              fullWidth
              style={{
                marginLeft: '1rem',
                marginRight: '1rem',
                marginTop: '1rem',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
            />
          )}
        />
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Nome de usuário"
              variant="standard"
              fullWidth
              style={{
                marginLeft: '1rem',
                marginRight: '1rem',
                marginTop: '1rem',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
              error={!!errors.cpf}
              helperText={errors.cpf?.message}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Senha temporaria"
              variant="standard"
              fullWidth
              style={{
                marginLeft: '1rem',
                marginRight: '1rem',
                marginTop: '1rem',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PasswordIcon />
                  </InputAdornment>
                ),
              }}
              error={!!errors.cpf}
              helperText={errors.cpf?.message}
            />
          )}
        />
      </div>
      <div
        style={{
          display: 'flex',
          margin: '1rem',
          justifyContent: 'flex-end',
        }}
      >
        <Button variant="contained" type="submit">
          Criar usuário
        </Button>
      </div>
    </form>
  );
};

export default FormCreateUser;
