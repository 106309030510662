import { Grid } from '@mui/material';
import { ICompositeEvent } from '../../../core/interfaces';
import BasicardClassroomCompositeEvent from './BasicardClassroomCompositeEvent';
interface IPropsCardsClassroomCompositeEvent {
  compositeEvent: ICompositeEvent;
}
const CardsClassroomCompositeEvent: React.FunctionComponent<
  IPropsCardsClassroomCompositeEvent
> = ({ compositeEvent }) => {
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {compositeEvent.classrooms?.map((element, index) => (
          <>
            <BasicardClassroomCompositeEvent
              classroom={element.classroom}
              is_main={element.is_main}
              key={`classroom-${index}`}
            />
          </>
        ))}
      </Grid>
    </>
  );
};

export default CardsClassroomCompositeEvent;
