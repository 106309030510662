import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { IShift } from '../../../core/interfaces';

interface IPropsCheckmarks {
  items: IShift[];
  onChange: (event: SelectChangeEvent<string[]>) => void;
  value: string[];
  style: React.CSSProperties | undefined;
  // disabled?: boolean;
}

const MAX_DISPLAYED_ITEMS = 3;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Checkmarks: React.FC<IPropsCheckmarks> = ({
  items,
  value,
  onChange,
  style,
  // disabled,
}) => {
  const hasMoreItems = value.length > MAX_DISPLAYED_ITEMS;

  const renderSelectedItems = (selected: string[]) => {
    if (selected.length === 0) {
      return <em>Horário da Turma</em>;
    }

    let displayedItemsText = selected.slice(0, MAX_DISPLAYED_ITEMS).join(', ');

    if (hasMoreItems) {
      displayedItemsText = displayedItemsText + '...';
    }

    return displayedItemsText;
  };

  return (
    <>
      <FormControl>
        <Select
          multiple
          displayEmpty
          value={value}
          onChange={onChange}
          input={<OutlinedInput />}
          renderValue={renderSelectedItems}
          style={style}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem disabled value="">
            <em>Horário da Turma</em>
          </MenuItem>
          {items &&
            items.map((value) => (
              <MenuItem key={value.name} disabled={false} value={value.name}>
                <Checkbox checked={value.active} />
                <ListItemText primary={value.name} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

export default Checkmarks;
