import { Snackbar, Stack } from '@mui/material';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { forwardRef } from 'react';

interface IPropsSnackBar {
  open: boolean;
  type: AlertColor | undefined;
  message: string;
  onClose: () => void;
}

const SnackBar: React.FunctionComponent<IPropsSnackBar> = ({
  open = false,
  type,
  message,
  onClose,
}) => {
  const Alert = forwardRef<HTMLDivElement, AlertProps>(
    function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    },
  );

  return (
    <>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={onClose}
          sx={{
            zIndex: '999999',
          }} /*anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}*/
        >
          <Alert severity={type} sx={{ width: '100%' }} onClose={onClose}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default SnackBar;
