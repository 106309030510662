import Dialog from '@mui/material/Dialog';
import Transition from '../Transition';

interface IFullScreenDIalogProps {
  open?: boolean;
  onClose: (() => void) | undefined;
}

const FullScreenDialog = (
  props: React.PropsWithChildren<IFullScreenDIalogProps>,
) => {
  const { open = false, onClose, children } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
        TransitionComponent={Transition}
      >
        {children}
      </Dialog>
    </>
  );
};

export default FullScreenDialog;
