import { GridRenderCellParams } from '@mui/x-data-grid';
import { hasRole } from '../../../../core/utils';
import DBGrid from '../../Grid';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MuiFileInput } from 'mui-file-input';
import Box from '@mui/material/Box';
import {
  classDeleteFiles,
  classDownloadFile,
  classSendFiles,
  classRenameFiles,
  classSendLink,
} from '../../../../service/ClassroomService';
import { useEffect, useState } from 'react';
import ErrorAPI from '../../../../core/models/ErrorAPI';
import LinearProgressWithLabel from '../../LinearProgressWithLabel';
import { Button, IconButton, TextField } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { IFiles } from '../../../../core/interfaces';
import Cookies from 'js-cookie';
import style from './style.module.css';
import SnackBar from '../../SnackBar';

interface IPropsFilesClassroom {
  filesDefault: IFiles[];
}
const FilesClassroom: React.FC<IPropsFilesClassroom> = ({ filesDefault }) => {
  const { id_class = '/' } = useParams<'id_class'>();
  const navigate = useNavigate();
  const [files, setFiles] = useState<File[] | undefined>(undefined);
  const [progress, setProgress] = useState<number>(0);
  const [rowsFiles, setRowsFiles] = useState<IFiles[]>(filesDefault);

  useEffect(() => {
    setRowsFiles(filesDefault);
  }, [filesDefault]);
  const roles = Cookies.get('roles');
  let rolesFromCookie = JSON.parse('[]');
  if (roles !== 'undefined' && roles !== undefined) {
    rolesFromCookie = JSON.parse(roles);
  }
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');

  const handleGetValuesSnack = (
    value: boolean,
    message: string,
    severity: string,
  ) => {
    setSnackbarOpen(value);
    setSnackbarSeverity(severity as 'success' | 'info' | 'warning' | 'error');
    setSnackbarMessage(message);
  };

  const handleSendFiles = async () => {
    if (files) {
      const response = await classSendFiles(
        id_class,
        files,
        (progressEvent) => {
          if (progressEvent) {
            if (progressEvent.progress) {
              setProgress(progressEvent.progress * 100);
            }
          }
        },
      );

      if (response instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setProgress(0);
        setFiles(undefined);
        setRowsFiles(response);
      }
    }
  };

  const handleSubmit = async (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const link = data.get('link')?.toString();
    if (link) {
      const response = await classSendLink(id_class, link);
      if (response instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        data.set('link', '');
        setRowsFiles(response);
      }
    }
  };

  const actions = (params: GridRenderCellParams<IFiles>) => {
    return (
      <>
        <IconButton
          onClick={async () => {
            const response = await classDeleteFiles(id_class, params.row);

            if (response instanceof ErrorAPI) {
              navigate('/logout');
            } else {
              setRowsFiles(response);
            }
          }}
        >
          <DeleteIcon />
        </IconButton>

        <IconButton
          onClick={async () => {
            await classDownloadFile(
              params.row.path_file,
              params.row.midia_name,
            );
          }}
        >
          <FileDownloadIcon />
        </IconButton>
      </>
    );
  };

  const columnsFiles = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'uuid', headerName: 'UUID', width: 50 },
    {
      field: 'midia_name',
      headerName: 'Nome da midia',
      width: 300,
      editable: true,
    },
    { field: 'path_file', headerName: 'Nome do arquivo', width: 300 },
    {
      field: 'actions',
      headerName: 'Opções',
      width: 250,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: actions,
    },
  ];

  return (
    <>
      {(hasRole(rolesFromCookie, 'SUPPLIER') ||
        hasRole(rolesFromCookie, 'MANAGER')) && (
        <div
          style={{
            padding: '20px',
          }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <AttachFileIcon />
                <Typography variant="button" display="block" gutterBottom>
                  Arquivos do evento
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {progress > 1 && <LinearProgressWithLabel value={progress} />}
              <Box
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  gap: '10px',
                  marginBottom: '1rem',
                }}
              >
                <MuiFileInput
                  multiple
                  getInputText={(value) =>
                    value ? `${value.length} arquivo carregado` : ''
                  }
                  placeholder="Clique aqui para inserir o arquivo"
                  value={files}
                  translate="yes"
                  onChange={(files) => {
                    setFiles(files);
                  }}
                />
                <Button
                  onClick={handleSendFiles}
                  disabled={files === undefined ? true : files.length == 0}
                  variant="contained"
                >
                  Enviar Arquivo
                </Button>
              </Box>
              <Box
                component={'form'}
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  gap: '10px',
                  marginBottom: '1rem',
                }}
                onSubmit={handleSubmit}
              >
                <TextField
                  id="link"
                  name="link"
                  label="Link"
                  variant="outlined"
                  sx={{ '&:focus': { borderColor: 'blue' }, width: '27.5rem' }}
                />
                <Button
                  onClick={handleSendFiles}
                  variant="contained"
                  type="submit"
                >
                  Adicionar Link
                </Button>
              </Box>
              <DBGrid
                className={style.gridTable}
                columns={columnsFiles}
                rows={rowsFiles}
                loading={false}
                processRowUpdate={async (newRow, oldRow) => {
                  if (newRow === oldRow) {
                    return;
                  }

                  if (newRow.midia_name.trim() == '') {
                    return oldRow;
                  }

                  const response = await classRenameFiles(id_class, newRow);

                  if (response instanceof ErrorAPI) {
                    navigate('/logout');
                  } else {
                    handleGetValuesSnack(
                      true,
                      'Nome alterado com sucesso',
                      'success',
                    );
                  }
                  return newRow;
                }}
                columnVisibilityModel={{
                  id: false,
                  uuid: false,
                  path_file: false,
                  finished_event: false,
                }}
              />
            </AccordionDetails>
          </Accordion>
          <SnackBar
            open={snackBarOpen}
            type={snackbarSeverity}
            message={snackBarMessage}
            onClose={() => {
              setSnackbarOpen(false);
            }}
          />
        </div>
      )}
    </>
  );
};

export default FilesClassroom;
