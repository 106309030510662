const isDateTodayOrBefore = (dateString?: string) => {
  if (!dateString) {
    return false;
  }

  const currentDate = new Date();
  const providedDate = new Date(dateString);
  if (currentDate.getTime() === providedDate.getTime()) {
    return true;
  } else if (currentDate > providedDate) {
    return true;
  } else {
    return false;
  }
};

export default isDateTodayOrBefore;
