import FormCheckin from '../../../components/FormCheckin';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { IClassroom, IFormCheckinData } from '../../../../core/interfaces';
import { useState } from 'react';

interface IPRopsCreateClass {
  openDialogInit: boolean;
  initialData: IClassroom;
  getValue?: (value: string & IFormCheckinData) => void;
}
const CreateClass: React.FC<IPRopsCreateClass> = ({
  getValue,
  openDialogInit,
  initialData,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(openDialogInit);
  const handleonChange = (value: IFormCheckinData) => {
    getValue?.(value as string & IFormCheckinData);
    setOpenDialog(false);
  };
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
      >
        <DialogTitle>Configuração Inicial</DialogTitle>
        <DialogContent>
          <FormCheckin initialData={initialData} onChange={handleonChange} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateClass;
