/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Button,
  SelectChangeEvent,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import SideBar from '../../components/Drawer';
import Header from '../../components/Header';
import HeaderDescription from '../../components/HeaderDescription';
import { DrawerProvider } from '../../contexts';
import { useState, useEffect } from 'react';
import style from './style.module.css';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import TextMaskCustomCPF from '../../components/TextMaskCustomCPF';
import TextMaskCustomCNPJ from '../../components/TextMaskCustomCNPJ';
import { searchUserForDocument } from '../../../service/StudentService';
import { IStudent } from '../../../core/interfaces';
import DBGrid from '../../components/Grid';
import ErrorAPI from '../../../core/models/ErrorAPI';
import SnackBar from '../../components/SnackBar';

import { GridRowModel } from '@mui/x-data-grid';
import CardMobile from './CardMobile';
import { classroomAddStudent } from '../../../service/ClassroomService';
const StudentAdd: React.FunctionComponent = () => {
  const { id_class } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [typeDocument, setTypeDocument] = useState<string>('PF');
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedItems, setSelectedItems] = useState<IStudent[]>([]);
  const [rowsStudents, setRowsStudents] = useState<IStudent[]>(
    [] as IStudent[],
  );
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');

  const columnsStudent = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'name', headerName: 'Nome', width: 250 },
    { field: 'CPF', headerName: 'CPF', width: 150 },
    { field: 'email', headerName: 'E-mail', width: 150, editable: true },
    { field: 'whatsapp', headerName: 'Whatsapp', width: 150, editable: true },
    { field: 'CNPJ', headerName: 'CNPJ', width: 150, editable: true },
    {
      field: 'company_name',
      headerName: 'Nome da empresa',
      width: 150,
      editable: true,
    },
  ];

  const isMobile = screenWidth <= 700;

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setSelectedItems([]);
    }
  }, [isMobile]);

  const handleSelectDocument = (
    event: SelectChangeEvent<typeof typeDocument>,
  ) => {
    setTypeDocument(event.target.value);
  };

  const handleOnSubmit = async (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const document = data.get('document')?.toString();
    if (document) {
      setLoading(true);
      const response = await searchUserForDocument(document, typeDocument);
      setLoading(false);
      if (response instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setRowsStudents(response);
        if (response.length === 0) {
          setSnackbarOpen(true);
          setSnackbarSeverity('warning');
          setSnackbarMessage('Documento inválido ou nenhum usuário encontrado');
        }
      }
    }
  };

  const handleGetValueGrid = (obj: object[]) => {
    if (obj.length == 0) {
      setDisableButton(true);
      return;
    }
    setDisableButton(false);
    setSelectedItems(obj as IStudent[]);
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    setRowsStudents(
      rowsStudents.map((row) =>
        row.id === newRow.id ? (updatedRow as IStudent) : row,
      ),
    );
    setSelectedItems(
      rowsStudents.map((row) =>
        row.id === newRow.id ? (updatedRow as IStudent) : row,
      ),
    );
    return updatedRow;
  };
  const handleAddStudent = async () => {
    const { CNPJ, CPF, CodParceiro, whatsapp, email, name, company_name } =
      selectedItems[0];

    const commonData = {
      classroom_id: id_class,
      student_cpf: CPF,
      from_send: 'checkin',
      cod_parceiro: CodParceiro,
      phone: whatsapp,
      student_email: email,
      student_name: name,
      type_document: typeDocument,
    };

    let specificData = {};

    if (typeDocument !== 'PF') {
      specificData = {
        student_cnpj: CNPJ,
        student_company_name: company_name,
      };
    }

    const data = { ...commonData, ...specificData };
    setLoading(true);
    const dataAPI = await classroomAddStudent(data);
    setLoading(false);
    if (dataAPI instanceof ErrorAPI) {
      if (dataAPI.statusCode == 401) {
        navigate('/logout');
      }
      setSnackbarOpen(true);
      setSnackbarSeverity('warning');
      setSnackbarMessage(dataAPI.message);
    } else {
      setSnackbarOpen(true);
      setSnackbarSeverity('success');
      setSnackbarMessage('Aluno enviado com sucesso.');
    }
  };
  return (
    <>
      <DrawerProvider>
        <SideBar>
          <Header title="Cadastro de aluno" />
          <HeaderDescription
            title="Busca de empresas."
            subtitle="
              Para prosseguir com o processo,
              selecione o tipo de documento e logo em seguida
              o informe o documento do usuário no campo abaixo 
              para que possamos buscar as empresas vinculadas.
              "
          />
          <Box className={style.container_input}>
            <FormControl fullWidth>
              <InputLabel id="typeDocumentLabel">Tipo de documento</InputLabel>
              <Select
                labelId="typeDocument"
                id="typeDocument"
                value={typeDocument}
                label="Tipo de documento"
                onChange={handleSelectDocument}
              >
                <MenuItem value={'PF'}>CPF</MenuItem>
                <MenuItem value={'PJ'}>CNPJ</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            className={style.container_input}
            component="form"
            onSubmit={handleOnSubmit}
            noValidate
          >
            {typeDocument && (
              <TextField
                id="document"
                name="document"
                style={{ margin: '1rem' }}
                fullWidth
                variant="outlined"
                label="Documento"
                InputProps={{
                  inputComponent:
                    typeDocument == 'PF'
                      ? (TextMaskCustomCPF as any)
                      : (TextMaskCustomCNPJ as any),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit">
                        <Icon>{'search'}</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Box>
          <div className={style.addBttn}>
            <Button
              onClick={handleAddStudent}
              disabled={disableButton}
              variant="contained"
            >
              Adicionar
            </Button>
          </div>
          {isMobile ? (
            <CardMobile
              setSelectedItems={handleGetValueGrid}
              dataAPI={rowsStudents}
            />
          ) : (
            <DBGrid
              columns={columnsStudent}
              rows={rowsStudents}
              loading={false}
              selectedValue={handleGetValueGrid}
              processRowUpdate={processRowUpdate}
              checkboxSelection={false}
              columnVisibilityModel={{
                id: false,
                uuid: false,
                CNPJ: false,
                company_name: false,
              }}
              className={style.gridTable}
            />
          )}

          <Loading open={loading} />
          <SnackBar
            open={snackBarOpen}
            type={snackbarSeverity}
            message={snackBarMessage}
            onClose={() => {
              setSnackbarOpen(false);
            }}
          />
        </SideBar>
      </DrawerProvider>
    </>
  );
};

export default StudentAdd;
