import { useEffect, useState } from 'react';
import { IClassroom } from '../../../core/interfaces';
import { generateClassroomAPI } from '../../../service/ClassroomService';
import HeaderDescription from '../../components/HeaderDescription';
import QRcode from '../../components/QRcode';
import Loading from '../../components/Loading';
import style from './style.module.css';
import { Icon, IconButton } from '@mui/material';
import SnackBar from '../../components/SnackBar';
import ErrorAPI from '../../../core/models/ErrorAPI';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

interface IPropsQRCodePage {
  value: IClassroom;
}

const QRCodePage: React.FunctionComponent<IPropsQRCodePage> = ({ value }) => {
  const [url_qrcode, setURLCode] = useState<string | undefined>('');
  const [classId, setClassId] = useState<string | undefined>('');
  const [openLoading, setOpenLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    navigate(`/classroom/detail/${classId}`);
  };

  useEffect(() => {
    const newClassroom = async (value: IClassroom) => {
      const newClassroom = await generateClassroomAPI(value);

      if (newClassroom instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setOpenLoading(false);
        setURLCode(newClassroom.url_qrcode);
        setClassId(newClassroom.uuid);
      }
    };
    newClassroom(value);
  }, [navigate, value]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const copyLink = () => {
    if (!url_qrcode) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Não é possível copiar o link');
      setSnackbarOpen(true);
      return;
    }
    const hostname = window.location.hostname;
    const linkShared = `${hostname}/classroom/detail/${classId}`;

    navigator.clipboard
      .writeText(linkShared)
      .then(() => {
        setSnackbarSeverity('success');
        setSnackbarMessage('Link copiado com sucesso!');
        setSnackbarOpen(true);
      })
      .catch((error) => {
        if (error.name === 'NotAllowedError') {
          setSnackbarSeverity('error');
          setSnackbarMessage('Erro de permissão ao copiar o link');
        } else if (error.name === 'NotFoundError') {
          setSnackbarSeverity('error');
          setSnackbarMessage('Nenhum dado encontrado para copiar');
        } else if (error.name === 'SecurityError') {
          setSnackbarSeverity('error');
          setSnackbarMessage('Erro de segurança ao copiar o link');
        } else {
          setSnackbarSeverity('error');
          setSnackbarMessage('Erro ao copiar o link');
        }
        setSnackbarOpen(true);
      });
  };

  return (
    <>
      <Loading open={openLoading} />
      <HeaderDescription
        title="QRcode gerado com sucesso"
        subtitle="Faça o download do QRCode ou compartilhe o link com o responsável da sala"
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ backgroundColor: 'background.paper', boxShadow: 24, p: 4 }}>
          {url_qrcode && <QRcode url_qrcode={url_qrcode} />}
          <div className={style.actions}>
            <div>
              <IconButton
                onClick={() => copyLink()}
                aria-label="Compartilhar"
                className={style.bttn}
              >
                <Icon>shared</Icon>
                <span className={style.spanAction}>Compartilhe a Turma</span>
              </IconButton>
              <IconButton aria-label="Download" className={style.bttn}>
                <Icon>download</Icon>
                <a href={`${url_qrcode}`} download>
                  Download QRCode
                </a>
              </IconButton>
            </div>
          </div>
        </Box>
      </Modal>
      <SnackBar
        open={snackbarOpen}
        type={snackbarSeverity}
        message={snackbarMessage}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

export default QRCodePage;
