import { Button } from '@mui/material';
import { useCallback } from 'react';
import { Icon } from '@mui/material';

interface ITextButtonProps {
  label?: string;
  id?: string;
  disabled?: boolean;
  onClick: (() => void) | undefined;
  type?: 'button' | 'submit' | 'reset' | undefined;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  className?: string;
  style?: object;
  isIcon?: boolean;
}

const TextButton: React.FunctionComponent<ITextButtonProps> = ({
  label,
  disabled,
  onClick,
  type,
  variant,
  className,
  style,
  id,
  isIcon,
}) => {
  const handleClick = useCallback(() => {
    onClick?.();
  }, [onClick]);

  return (
    <>
      <Button
        id={id}
        style={style}
        className={className}
        variant={variant}
        type={type}
        disabled={disabled}
        onClick={handleClick}
      >
        {!isIcon ? label : <Icon>add</Icon>}
      </Button>
    </>
  );
};

export default TextButton;
