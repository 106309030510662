import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormContext } from 'react-hook-form';
import { IClassroomCertificate } from '../../../../core/interfaces';
import HeaderDescription from '../../../components/HeaderDescription';
import ErrorAPI from '../../../../core/models/ErrorAPI';
import { CircularProgress } from '@mui/material';
import { getCertificate } from '../../../../service/ClassroomService';
import { useNavigate } from 'react-router-dom';

interface StepFourProps {
  initialCertificateUuid?: string;
}

const StepFour: React.FC<StepFourProps> = ({ initialCertificateUuid }) => {
  const navigate = useNavigate();
  const [certificates, setCertificates] = useState<IClassroomCertificate[]>([]);
  const [loading, setLoading] = useState(true);
  const { setValue, watch } = useFormContext();
  const [selectedCertificate, setSelectedCertificate] = useState<string | null>(
    null,
  );

  // Observar o valor do campo 'certificate_uuid' no contexto do formulário
  const watchedCertificateUuid = watch(
    'certificate_uuid',
    initialCertificateUuid,
  );

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const data = await getCertificate();
      setLoading(false);
      if (data instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setCertificates(data);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    if (watchedCertificateUuid) {
      setSelectedCertificate(watchedCertificateUuid);
    }
  }, [watchedCertificateUuid]);

  const handleCheckboxChange = (certificate: IClassroomCertificate) => {
    const uuid = certificate.uuid;
    setSelectedCertificate(uuid);
    setValue('certificate_uuid', uuid);
  };

  if (loading) {
    return (
      <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
    );
  }

  return (
    <div>
      <HeaderDescription
        title="Defina o certificado desse curso."
        subtitle="Por favor, selecione um certificado para dar continuidade à criação da aula. Se a base de origem for o SAS, é necessário encontrar uma solução adequada."
      />
      <div>
        {certificates.map((certificate) => (
          <div key={certificate.uuid}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedCertificate === certificate.uuid}
                  onChange={() => handleCheckboxChange(certificate)}
                />
              }
              label={certificate.certificate_name}
            />
            <br />
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepFour;
