import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import HeaderDescription from '../../../components/HeaderDescription';
import SearchSupplier from '../../SupplierAvailable/SearchSupplier';
import { ISupplier } from '../../../../core/interfaces';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { DataGrid, GridSelectionModel } from '@mui/x-data-grid';
import { Button, Container, Paper } from '@mui/material';

const StepFive = () => {
  const { setValue, watch } = useFormContext();

  // Recarrega o estado salvo
  const formData = watch();
  const [searchResults, setSearchResults] = useState<ISupplier[]>(
    formData.searchResults || [],
  );
  const [selectedSupplierObjects, setSelectedSupplierObjects] = useState<
    ISupplier[]
  >(formData.selectedSuppliers || []);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  // Atualiza o estado e assegura que não há duplicatas
  const handleSearch = (value: ISupplier) => {
    if (!searchResults.find((supplier) => supplier.id === value.id)) {
      const updatedResults = [...searchResults, value];
      setSearchResults(updatedResults);
      setValue('searchResults', updatedResults, { shouldValidate: true });
      setOpenDialog(false);
    }
  };

  useEffect(() => {
    setValue('searchResults', searchResults, { shouldValidate: true });
    setValue('selectedSuppliers', selectedSupplierObjects, {
      shouldValidate: true,
    });
  }, [searchResults, selectedSupplierObjects, setValue]);

  // Atualiza os fornecedores selecionados
  const handleSelectionChange = (newSelection: GridSelectionModel) => {
    const selectedObjects = newSelection
      .map((id) => searchResults.find((supplier) => supplier.id === id))
      .filter(Boolean) as ISupplier[];

    setSelectedSupplierObjects(selectedObjects);
    setValue('selectedSuppliers', selectedObjects, { shouldValidate: true });
  };

  const columns = [
    { field: 'uuid', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Nome', width: 200 },
    { field: 'cpf', headerName: 'CPF', width: 200 },
    { field: 'cnpj_empresa', headerName: 'CNPJ Empresa', width: 200 },
    { field: 'company', headerName: 'Empresa', width: 200 },
    { field: 'phone', headerName: 'Telefone', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
  ];

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <HeaderDescription
          title="Fornecedores disponíveis para o tipo de solução"
          subtitle="Selecione o(s) fornecedor(es) que desejar."
        />
        <Button
          variant="contained"
          startIcon={<PersonAddIcon />}
          style={{ marginTop: '20px', marginBottom: '20px' }}
          onClick={() => setOpenDialog(true)}
        >
          Adicionar fornecedor de forma manual
        </Button>

        <SearchSupplier
          getValue={handleSearch}
          openDialogSupplier={openDialog}
          onClose={() => setOpenDialog(false)}
        />

        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={searchResults}
            columns={columns}
            pageSize={5}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) =>
              handleSelectionChange(newSelectionModel)
            }
          />
        </div>
      </Paper>
    </Container>
  );
};

export default StepFive;
