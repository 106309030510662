import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import DBGrid from '../../../../components/Grid';
import { ICompositeEvent } from '../../../../../core/interfaces';
import { useNavigate } from 'react-router-dom';
import ErrorAPI from '../../../../../core/models/ErrorAPI';
import { getAllCompositeEvent } from '../../../../../service/CompositeEventOperationService';

const style = {
  position: 'fixed' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  maxWidth: '90%', // Usando uma largura máxima para manter a responsividade
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  margin: 'auto', // Centralizar horizontalmente
};

interface IPropsCompositeEventSelect {
  getValue: (value: object[]) => void;
  isOpen: boolean; // Simplifiquei para isOpen para controlar diretamente o estado do Modal
  onOpen?: () => void; // Opcional, pode ser usado para realizar ações quando o modal abre
  onClose?: () => void; // Opcional, pode ser usado para realizar ações quando o modal fecha
}

export default function CompositeEventSelect({
  getValue,
  isOpen, // Usar diretamente o estado externo para controlar a visibilidade
  onOpen = () => {}, // Se não for passado, não faz nada
  onClose = () => {}, // Se não for passado, não faz nada
}: IPropsCompositeEventSelect) {
  const navigate = useNavigate();
  const [rows, setRows] = useState<Array<ICompositeEvent>>([]);
  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'name', headerName: 'Nome do evento', width: 150 },
    { field: 'place', headerName: 'Local', width: 120 },
    { field: 'init_period', headerName: 'Inicio do evento', width: 120 },
    { field: 'end_period', headerName: 'Fim do evento', width: 120 },
  ];

  useEffect(() => {
    async function searchDataAPI() {
      const compositeEvent = await getAllCompositeEvent();

      if (compositeEvent instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setRows(compositeEvent);
      }
    }

    searchDataAPI();
  }, []);

  const handleGetValeu = (value: object[]) => {
    getValue(value);
    onClose(); // Chama onClose quando um valor é selecionado, indicando que o Modal deve ser fechado
  };

  useEffect(() => {
    isOpen ? onOpen() : onClose();
  }, [isOpen, onOpen, onClose]);

  return (
    <div style={{ marginLeft: '1rem' }}>
      <Modal
        open={isOpen}
        onClose={onClose} // Altera para chamar onClose diretamente
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <DBGrid
            selectedValue={handleGetValeu}
            columnVisibilityModel={{ id: false }}
            columns={columns}
            rows={rows}
          />
        </Box>
      </Modal>
    </div>
  );
}
