/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card, CardContent, Divider } from '@material-ui/core';
import {
  List,
  ListItem,
  ListItemText,
  CardActions,
  Button,
} from '@mui/material';
import SideBar from '../../components/Drawer';
import DBGrid from '../../components/Grid';
import Header from '../../components/Header';
import { DrawerProvider } from '../../contexts';
import style from './style.module.css';
import { IClassroom } from '../../../core/interfaces';
import { useEffect, useState } from 'react';
import { searchClassrooms } from '../../../service/ClassroomService';
import Loading from '../../components/Loading';
import ErrorAPI from '../../../core/models/ErrorAPI';
import { useNavigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { classroomInitValidate } from '../../../service/ClassroomService';
import InstallBanner from '../../components/InstallBanner';
import maskDate from '../../../core/utils/Masks/maskDate';

const Home: React.FunctionComponent = () => {
  const [classroom, setClassroom] = useState<Array<IClassroom>>([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [openLoading, setOpenLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [classID, setClassID] = useState<string | undefined>('');
  const [isCollective, setIsCollective] = useState<boolean>(false);
  const [isFree, setIsFree] = useState<boolean>(false);

  const params = new URLSearchParams();
  useEffect(() => {
    async function fetchData() {
      params.append('finished_event', 'False');
      const classroom = await searchClassrooms(params);
      if (classroom instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setOpenLoading(false);
        setClassroom(classroom);
      }
    }
    fetchData();
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [navigate]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'uuid', headerName: 'UUID', width: 50 },
    { field: 'solution_code', headerName: 'Código da solução', width: 150 },
    { field: 'code_classroom', headerName: 'Código da turma', width: 150 },
    { field: 'proposal_code', headerName: 'Código da proposta', width: 150 },
    { field: 'classname', headerName: 'Nome da Sala', width: 300 },
    { field: 'status', headerName: 'Status', width: 150 },
    {
      field: 'is_collective',
      headerName: 'É Coletivo?',
      type: 'boolean',
      width: 100,
    },
    {
      field: 'is_free',
      headerName: 'É gratuito?',
      type: 'boolean',
      width: 100,
    },
    { field: 'number_contract', headerName: 'Número de Contrato', width: 150 },
    {
      field: 'validation_started',
      headerName: 'Inicializada',
      type: 'boolean',
      width: 100,
    },
    {
      field: 'finished_event',
      headerName: 'Finalizada',
      type: 'boolean',
      width: 100,
    },
    { field: 'start_date_event', headerName: 'Data de Inicio', width: 150 },
    {
      field: 'actions',
      headerName: 'Opções',
      width: 120,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        const handleActionTable = () => {
          handleDialog(
            params.row.uuid,
            params.row.validation_started,
            params.row.is_free,
            params.row.is_collective,
          );
        };
        return (
          <>
            <Button
              id={style.bttnActionTable}
              onClick={handleActionTable}
              variant="contained"
              size="small"
            >
              {params.row.validation_started ? 'Visualizar' : 'Iniciar'}
            </Button>
          </>
        );
      },
    },
  ];

  const rows = classroom.map((item: IClassroom, index: number) => {
    return {
      id: index,
      uuid: item.uuid,
      solution_code: item.solution_code,
      proposal_code: item.proposal_code,
      code_classroom: item.code_classroom,
      classname: item.classname,
      is_collective: item.is_collective,
      is_free: item.is_free,
      number_contract: item.number_contract,
      validation_started: item.validation_started,
      finished_event: item.finished_event,
      status: item.status,
      start_date_event: item.start_date_event
        ? maskDate(item.start_date_event)
        : '',
    };
  });

  const handleDialog = (
    uuid?: string,
    validation_started?: boolean,
    is_collective?: boolean,
    is_free?: boolean,
  ) => {
    if (validation_started) {
      navigate(`/classroom/detail/${uuid}`, {
        state: {
          is_free: is_free,
          is_collective: is_collective,
        },
      });
    }

    setOpenDialog(!openDialog);
    setClassID(uuid);
    setIsCollective(!!is_collective);
    setIsFree(!!is_free);
  };

  const handleDialogConfirm = async () => {
    const statusCode = await classroomInitValidate(classID, true);
    if (statusCode instanceof ErrorAPI) {
      navigate('/logout');
    } else {
      navigate(`/classroom/detail/${classID}`, {
        state: {
          is_free: isFree,
          is_collective: isCollective,
        },
      });
    }
  };

  const isMobile = screenWidth <= 700;

  return (
    <>
      <Loading open={openLoading} />
      <DrawerProvider>
        <SideBar>
          <Header title="Início" />

          <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(!openDialog)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Confirmação da Sala: Tem certeza que deseja continuar?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Você tem certeza de que deseja iniciar a confirmação da sala?
                Lembre-se de que, uma vez iniciado o processo, não será possível
                voltar atrás. Caso esteja seguro(a) de sua decisão, prossiga com
                a confirmação.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(!openDialog)}>Não</Button>
              <Button onClick={handleDialogConfirm} autoFocus>
                Sim
              </Button>
            </DialogActions>
          </Dialog>

          {isMobile ? (
            rows.map((item) => (
              <Card key={item.id} className={style.cardTable}>
                <CardContent className={style.cardTableContent}>
                  <span
                    className={style.spanValidate}
                    style={
                      item.finished_event
                        ? { color: 'blue' }
                        : item.validation_started
                          ? { color: 'green' }
                          : { color: 'red' }
                    }
                  >
                    {item.finished_event
                      ? 'Turma Finalizada'
                      : item.validation_started
                        ? 'Inicializada'
                        : 'Não Inicializada'}
                  </span>
                  <List dense={true} className={style.styleTableList}>
                    <ListItem disablePadding>
                      <ListItemText
                        primary="N° de Contrato"
                        secondary={item.number_contract}
                      />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemText
                        primary="Cód. de proposta"
                        secondary={item.proposal_code}
                      />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemText
                        primary="Tipo de solução"
                        secondary={
                          item.is_collective ? 'Coletiva' : 'Individual'
                        }
                      />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemText
                        primary="Nome da solução"
                        secondary={item.classname}
                      />
                    </ListItem>
                  </List>

                  <Divider />

                  <CardActions disableSpacing className={style.cardAction}>
                    <Button
                      id={style.bttnAction}
                      onClick={() =>
                        handleDialog(item.uuid, item.validation_started)
                      }
                      variant="contained"
                      size="small"
                    >
                      {item.validation_started
                        ? 'Visualizar alunos'
                        : 'Iniciar confirmação'}
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>
            ))
          ) : (
            <DBGrid
              className={style.gridTable}
              columns={columns}
              rows={rows}
              loading={false}
              columnVisibilityModel={{
                id: false,
                uuid: false,
                finished_event: false,
              }}
            />
          )}
          <div className={style.fabApp}>
            <InstallBanner isListItem={false} />
          </div>
        </SideBar>
      </DrawerProvider>
    </>
  );
};

export default Home;
