/* eslint-disable prefer-const */
import { get, post } from '../adapters/xhr';
import { ICompositeEvent } from '../../core/interfaces';
import { AxiosError } from 'axios';
import ErrorAPI from '../../core/models/ErrorAPI';

export const CompositeEventOperation = () => ({
  generateCompositeEventAPI: async (compositeEvent: ICompositeEvent) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/composite/composite-event`,
        compositeEvent,
      );
      if (response.status !== 201) {
        return {} as ICompositeEvent;
      }
      return response.data as ICompositeEvent;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI: ErrorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 409:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }

        return errorAPI;
      }

      return 500;
    }
  },
  getAllCompositeEventAPI: async (params?: URLSearchParams) => {
    const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
    try {
      let response = await get(
        `${BaseURL}/api/v1/composite/composite-event/get/all`,
        undefined,
        params,
      );
      return response.data as Array<ICompositeEvent>;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }

      return [] as Array<ICompositeEvent>;
    }
  },
  getCompositeEventClassroomAPI: async (id_composite_event?: string) => {
    const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
    try {
      let response = await get(
        `${BaseURL}/api/v1/composite/composite-event/get/classroom/${id_composite_event}`,
      );
      return response.data as ICompositeEvent;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 403:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }

        return errorAPI;
      }
      return {} as ICompositeEvent;
    }
  },
});
