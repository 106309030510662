import { Routes, Route } from 'react-router-dom';
import Home from './ui/pages/Home';
import Generate from './ui/pages/Generate';
import Scanner from './ui/pages/Scanner';
// import ListClassroom from './ui/pages/ListClassroom';
import ListStudent from './ui/pages/ListStudent';
import PrivateRoute from './PrivateRoute';
import { useAuth } from 'react-oidc-context';
import Loading from './ui/components/Loading';
import Login from './ui/pages/Login';
import Checkin from './ui/pages/Checkin/Index';
import Logout from './ui/pages/Logout';
import StudentAdd from './ui/pages/StudentAdd';
import Settings from './ui/pages/Settings';
import AssistantAdd from './ui/pages/AssistantAdd';
import CompositeEvent from './ui/pages/CompositeEvent';
import ListCompositeEvent from './ui/pages/ListCompositeEvent';
import ListEvents from './ui/pages/ListEvents';
import NotFoundPage from './ui/pages/NotFound';
import EditDefaultMessages from './ui/pages/Settings/EditDefaultMessages';
import CalendarEvents from './ui/pages/CalendarEvents';
// import PageDetailClassroom from './ui/pages/PageDetailClassroom';
import EditClassroomInfo from './ui/pages/EditClassroomInfo';
import ClassroomInfo from './ui/pages/ClassroomInfo';
import MultiStepForm from './ui/pages/MultiStep';
import PlaceInformation from './ui/pages/PlaceInformation';

const Main = () => {
  const { isLoading } = useAuth();

  if (isLoading) {
    return <Loading open={true} />;
  }

  const routePermissions = {
    '/settings': ['Acessar Configurações'],
    '/generate': ['Cadastrar evento'],
    '/classroom': ['Visualizar evento'],
    '/composite-event': ['Visualizar evento'],
    '/composite-event/list': ['Visualizar evento'],
    '/classrooms/event/list': ['Visualizar Calendário'],
    '/edit/message': ['Configuração de mensagem'],
  };

  return (
    <PrivateRoute routePermissions={routePermissions}>
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/login" element={<Login />}></Route>
        <Route path="/logout" element={<Logout />}></Route>
        <Route path="/" element={<Home />}></Route>
        <Route path="/generate" element={<Generate />}></Route>
        <Route path="/scanner/:id_class" element={<Scanner />}></Route>
        <Route path="/checkin/:id_class" element={<Checkin />}></Route>
        <Route path="/assistant/:id_class" element={<AssistantAdd />}></Route>
        <Route path="/settings" element={<Settings />}></Route>
        <Route path="/classroom" element={<ListEvents />}></Route>
        <Route
          path="/composite-event/detail/:id_composite_event"
          element={<CompositeEvent />}
        ></Route>
        <Route
          path="/composite-event/list"
          element={<ListCompositeEvent />}
        ></Route>
        <Route
          path="/classroom/detail/:id_class"
          element={<ListStudent />}
        ></Route>
        <Route
          path="/classroom/:id_class/add/student"
          element={<StudentAdd />}
        ></Route>
        <Route path="/certificate" element={<StudentAdd />}></Route>
        <Route path="/edit/message" element={<EditDefaultMessages />}></Route>
        <Route
          path="/classrooms/event/list"
          element={<CalendarEvents />}
        ></Route>
        <Route
          path="/edit/place/performance/:id_place"
          element={<EditClassroomInfo />}
        ></Route>
        <Route
          path="/information/place/performance/:id_place"
          element={<ClassroomInfo />}
        ></Route>
        <Route path="/place/performance" element={<PlaceInformation />}></Route>
        <Route
          path="/finalize/classroom/:id_class"
          element={<MultiStepForm />}
        ></Route>
      </Routes>
    </PrivateRoute>
  );
};

export default Main;
