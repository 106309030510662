import style from './style.module.css';

interface IPropsHeaderDescription {
  title?: string;
  subtitle?: string;
  className?: string;
}
const HeaderDescription: React.FunctionComponent<IPropsHeaderDescription> = ({
  title,
  subtitle,
}) => {
  return (
    <div className={style.head}>
      <h3 className={style.title}>{title}</h3>
      <p className={style.subtitle}>{subtitle}</p>
    </div>
  );
};

export default HeaderDescription;
