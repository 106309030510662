import SideBar from '../../components/Drawer';
import Header from '../../components/Header';
import HeaderDescription from '../../components/HeaderDescription';
import { DrawerProvider } from '../../contexts';
import { useState, useEffect, useCallback } from 'react';
import style from './style.module.css';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { IUser } from '../../../core/interfaces';
import SnackBar from '../../components/SnackBar';
import DBGrid from '../../components/Grid';
import { getAssistant, sendAssistant } from '../../../service/AssistantService';
import ErrorAPI from '../../../core/models/ErrorAPI';
import CardMobile from './CardMobile';
import { Button } from '@mui/material';
import CreateUser from '../Settings/PermissionSettings/CreateUser';

const AssistantAdd: React.FunctionComponent = () => {
  const { id_class = '' } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [rowsAssistant, setRowsAssistant] = useState<IUser[]>([] as IUser[]);
  const [selectedAssistant, setSelectedAssistant] = useState<IUser[]>(
    [] as IUser[],
  );
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const [updateRows, setUpdateRows] = useState<boolean>(false);
  const columnsAssistant = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'first_name', headerName: 'Primeiro nome', width: 250 },
    { field: 'last_name', headerName: 'Ultimo nome', width: 250 },
    { field: 'cpf', headerName: 'CPF', width: 150 },
    { field: 'email', headerName: 'E-mail', width: 250, editable: true },
  ];
  const isMobile = screenWidth <= 700;
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [openDialogCreatUser, setOpenDialogCreatUser] =
    useState<boolean>(false);

  const handleUpdateUser = useCallback((send: boolean) => {
    setUpdateRows(send);
  }, []);

  useEffect(() => {
    async function getAllData() {
      setLoading(true);
      const data = await getAssistant();
      setLoading(false);
      if (data instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setRowsAssistant(data);
      }
    }
    getAllData();
  }, [updateRows]);

  const handleGetValue = (values: object[]) => {
    setDisableButton(true);
    if (values.length > 0) {
      setDisableButton(false);
    }
    setSelectedAssistant(values as IUser[]);
  };

  const handleSendAssistant = async () => {
    const data = {
      users: selectedAssistant,
    };
    setLoading(true);
    const response = await sendAssistant(id_class, data);
    if (response instanceof ErrorAPI) {
      navigate('/logout');
    } else {
      if (response === 202) {
        setSnackbarMessage('Novo assistente adicionado à sala com sucesso!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage(
          'Ops! Algo deu errado ao adicionar o assistente. Por favor, tente novamente mais tarde.',
        );
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
    setLoading(false);
  };
  return (
    <>
      <DrawerProvider>
        <SideBar>
          <Header title="Cadastro de assistentes" />
          <HeaderDescription
            title="Gerenciamento de Assistentes"
            subtitle="
                Adicione e gerencie os assistentes neste espaço.
                Visualize a lista de assistentes disponíveis para adição
                e personalize conforme necessário."
          />
          <CreateUser
            hasCreateAssistant={handleUpdateUser}
            setOpenDialogCreate={setOpenDialogCreatUser}
            openDialogCreatUser={openDialogCreatUser}
            assistant={true}
          />
          <div className={style.addBttn}>
            <Button
              onClick={() => {
                setOpenDialogCreatUser(true);
              }}
              variant="contained"
            >
              Cadastrar assistente
            </Button>
          </div>
          <div className={style.addBttn}>
            <Button
              onClick={handleSendAssistant}
              disabled={disableButton}
              variant="contained"
            >
              Adicionar
            </Button>
          </div>
          {isMobile ? (
            <CardMobile
              setSelectedItems={handleGetValue}
              dataAPI={rowsAssistant}
            />
          ) : (
            <DBGrid
              checkboxSelection={true}
              selectedValue={handleGetValue}
              defaultValue={selectedAssistant}
              columnVisibilityModel={{ id: false }}
              columns={columnsAssistant}
              rows={rowsAssistant}
            />
          )}
          <Loading open={loading} />
          <SnackBar
            open={snackBarOpen}
            type={snackbarSeverity}
            message={snackBarMessage}
            onClose={() => {
              setSnackbarOpen(false);
            }}
          />
        </SideBar>
      </DrawerProvider>
    </>
  );
};

export default AssistantAdd;
