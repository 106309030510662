/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { IClassroom } from '../../../core/interfaces';
import DBGrid from '../../components/Grid';
import HeaderListEventIntegra from '../../components/HeaderListEventIntegra';
import style from './style.module.css';
import { searchClassroomsFromIntegraAPI } from '../../../service/ClassroomService';
import ErrorAPI from '../../../core/models/ErrorAPI';
import { useNavigate } from 'react-router-dom';
import maskDate from '../../../core/utils/Masks/maskDate';
import Loading from '../../components/Loading';
import SnackBar from '../../components/SnackBar';

interface IListClassroomsIntegra {
  onSelectCallback: (obj: object) => void;
}
const ListClassroomsIntegra: React.FC<IListClassroomsIntegra> = ({
  onSelectCallback,
}) => {
  const [classrooms, setClassrooms] = useState<object[]>([]);
  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');

  const navigate = useNavigate();

  const searchData = async (
    initial_data: string,
    final_data: string,
    name?: string,
  ) => {
    const params = new URLSearchParams();
    params.append('name', name ? name : '');
    params.append('initial_data', initial_data);
    params.append('final_data', final_data);
    setOpenLoading(true);
    const response = await searchClassroomsFromIntegraAPI(params);
    setOpenLoading(false);

    if (response instanceof ErrorAPI) {
      if (response.statusCode == 401) {
        navigate('/logout');
      }

      if (response.statusCode == 500) {
        setSnackbarMessage(response.message);
        setSnackbarSeverity('warning');
        setSnackbarOpen(true);
      }
    } else {
      const rows = response.map((item: IClassroom, index: number) => {
        return {
          id: index,
          classname: item.classname,
          solution_name: item.solution_name,
          code_search: item.code_search,
          is_collective: item.is_collective,
          is_free: item.is_free,
          start_date_event: item.start_date_event
            ? maskDate(item.start_date_event)
            : '',
          end_date_event: item.end_date_event
            ? maskDate(item.end_date_event)
            : '',
        };
      });

      if (response.length === 0) {
        setSnackbarMessage('Não há registros de eventos para este período.');
        setSnackbarSeverity('warning');
        setSnackbarOpen(true);
        setClassrooms([]);
        return;
      }
      setClassrooms(rows);
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID do evento', width: 50 },
    { field: 'code_search', headerName: 'Código do evento', width: 130 },
    { field: 'classname', headerName: 'Nome da turma', width: 300 },
    { field: 'solution_name', headerName: 'Nome da solução', width: 300 },
    // {
    //   field: 'is_collective',
    //   headerName: 'É coletiva ? ',
    //   width: 100,
    //   type: 'boolean',
    // },
    { field: 'is_free', headerName: 'É grátis', width: 100, type: 'boolean' },
    { field: 'start_date_event', headerName: 'Inicio do evento', width: 150 },
    { field: 'end_date_event', headerName: 'Fim do evento', width: 150 },
  ];

  return (
    <div>
      <HeaderListEventIntegra
        onSendCallback={async ({ dates, name }) => {
          await searchData(dates.initial_date, dates.final_date, name);
        }}
      />
      <DBGrid
        className={style.gridTable}
        columns={columns}
        rows={classrooms}
        loading={false}
        selectedValue={(obj) => {
          onSelectCallback(obj[0]);
        }}
        columnVisibilityModel={{
          id: false,
          uuid: false,
          finished_event: false,
        }}
      />
      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
      <Loading open={openLoading} />
    </div>
  );
};

export default ListClassroomsIntegra;
