import { shiftsAPI } from '../infra/api/ShiftsOperations';

export async function getAllshifts() {
  const instaceInfra = shiftsAPI();
  const shifts = await instaceInfra.getAllShifts();

  return shifts;
}

export async function updateShifts(data: object) {
  const instaceInfra = shiftsAPI();
  const shifts = await instaceInfra.updateShifts(data);

  return shifts;
}

export async function postShifts(data: object) {
  const instaceInfra = shiftsAPI();
  const shifts = await instaceInfra.postShifts(data);

  return shifts;
}

export async function deleteShifts(id: string) {
  const instaceInfra = shiftsAPI();
  const status = await instaceInfra.deleteShifts(id);

  return status;
}
