import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

interface TimeRangeSwitchProps {
  onTimeRangeChange: (timeRange: [string?, string?]) => void;
  defaultValues?: [string?, string?];
  isDisabled?: boolean;
}

const TimeRangeSwitch: React.FC<TimeRangeSwitchProps> = ({
  onTimeRangeChange,
  defaultValues,
  isDisabled,
}) => {
  const [startTime, setStartTime] = useState<string | undefined>('');
  const [endTime, setEndTime] = useState<string | undefined>('');

  const useStyles = makeStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
    },
    TextField: {
      margin: '1rem',
      color: 'black',
    },
  });

  const classes = useStyles();

  useEffect(() => {
    if (defaultValues && defaultValues.length === 2) {
      const [defaultStartTime, defaultEndTime] = defaultValues;
      setStartTime(defaultStartTime);
      setEndTime(defaultEndTime);
      onTimeRangeChange([defaultStartTime, defaultEndTime]);
    }
  }, []);

  const handleStartTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newStartTime = event.target.value;
    setStartTime(newStartTime);
    onTimeRangeChange([newStartTime, endTime]);
  };

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEndTime = event.target.value;
    setEndTime(newEndTime);
    onTimeRangeChange([startTime, newEndTime]);
  };

  return (
    <div className={classes.container}>
      <TextField
        className={classes.TextField}
        id="startTime"
        label="Início"
        onChange={handleStartTimeChange}
        type="time"
        value={startTime}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        disabled={isDisabled}
      />
      <TextField
        className={classes.TextField}
        id="endTime"
        label="Fim"
        type="time"
        onChange={handleEndTimeChange}
        value={endTime}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        disabled={isDisabled}
      />
    </div>
  );
};

export default TimeRangeSwitch;
