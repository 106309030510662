import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import { PropsWithChildren } from 'react';

type IPropsDialogAlert = PropsWithChildren & {
  nameButtonConfirm?: string;
  nameButtonCancel?: string;
  titleDialog: string;
  descriptionDialog: string;
  openDialog: boolean;
  onCloseDialog: () => void;
  confirmationButtonCallback: () => void;
  cancelButtonCallback: () => void;
};

const DialogAlert: React.FC<IPropsDialogAlert> = ({
  titleDialog,
  descriptionDialog,
  openDialog,
  onCloseDialog,
  confirmationButtonCallback,
  cancelButtonCallback,
  nameButtonConfirm = 'SIM',
  nameButtonCancel = 'NÃO',
  children,
}) => {
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={onCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{titleDialog}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {descriptionDialog}
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelButtonCallback}>{nameButtonCancel}</Button>
          <Button onClick={confirmationButtonCallback} autoFocus>
            {nameButtonConfirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogAlert;
