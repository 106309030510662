import {
  TextField,
  Icon,
  InputAdornment,
  IconButton,
  Box,
} from '@mui/material';

import style from './style.module.css';
import { IFormCheckinData } from '../../../core/interfaces';

interface IPropsSearchProposal {
  defaultValue: string;
  getValue?: (value: string & IFormCheckinData) => void;
  hintError?: boolean;
  hintErrorDescription?: string;
}
const SearchProposal: React.FunctionComponent<IPropsSearchProposal> = ({
  defaultValue,
  getValue,
  hintError,
  hintErrorDescription,
}) => {
  const handleSubmit = (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const codeProposal = data.get('code_proposal')?.toString();

    if (codeProposal) {
      getValue?.(codeProposal as string & IFormCheckinData);
    } else {
      getValue?.('' as string & IFormCheckinData);
    }
  };

  return (
    <>
      <Box
        className={style.container_input}
        component="form"
        onSubmit={handleSubmit}
        noValidate
      >
        <TextField
          id="code_proposal"
          name="code_proposal"
          autoFocus
          className={style.input}
          label="Código da proposta"
          error={hintError}
          helperText={hintErrorDescription}
          variant="outlined"
          type="text"
          defaultValue={defaultValue}
          margin="dense"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit">
                  <Icon>{'search'}</Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default SearchProposal;
