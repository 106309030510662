import { IClassroom } from '../../../core/interfaces';
import daysjs from 'dayjs';
import style from './style.module.css';

interface IDescriptionListProps {
  value: IClassroom;
}

const DescriptionList: React.FunctionComponent<IDescriptionListProps> = ({
  value,
}) => {
  return (
    <>
      <div className={style.body}>
        <div className={style.titleline}>
          <dl>
            <div className={style.information}>
              <dt className={style.key}>Tipo de solução</dt>
              <dd className={style.value}>
                {value.is_collective ? 'Coletiva' : 'Individual'}
              </dd>
            </div>

            <div className={style.information_white}>
              <dt className={style.key}>Numero do contrato</dt>
              <dd className={style.value}>{value.number_contract}</dd>
            </div>

            <div className={style.information}>
              <dt className={style.key}>Código da proposta</dt>
              <dd className={style.value}>{value.proposal_code}</dd>
            </div>
            {value.is_collective && (
              <div className={style.information_white}>
                <dt className={style.key}>Turma</dt>
                <dd className={style.value}>{value.classname}</dd>
              </div>
            )}
            <div className={style.information}>
              <dt className={style.key}>Código da solução</dt>
              <dd className={style.value}>{value.solution_code}</dd>
            </div>
            <div className={style.information_white}>
              <dt className={style.key}>Nome da solução</dt>
              <dd className={style.value}>{value.solution_name}</dd>
            </div>
            {value.start_date_event && (
              <div className={style.information}>
                <dt className={style.key}>Inicio do evento</dt>
                <dd className={style.value}>
                  {daysjs(value.start_date_event).format('DD/MM/YYYY HH:mm:ss')}
                </dd>
              </div>
            )}
            {value.composite_event && (
              <div className={style.information_white}>
                <dt className={style.key}>Nome do evento composto</dt>
                <dd className={style.value}>{value.composite_event.name}</dd>
              </div>
            )}
            {value.end_date_event && (
              <div className={style.information_white}>
                <dt className={style.key}>Fim do evento</dt>
                <dd className={style.value}>
                  {daysjs(value.end_date_event).format('DD/MM/YYYY HH:mm:ss')}
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    </>
  );
};

export default DescriptionList;
