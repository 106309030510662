import { Card, CardContent, Divider } from '@material-ui/core';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  CardActions,
  Button,
} from '@mui/material';

import { ICompositeEvent } from '../../../core/interfaces';
import style from './style.module.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface IBasicCardCompositeEvent {
  compositeEvent: ICompositeEvent;
}
const BasicCardCompositeEvent: React.FunctionComponent<
  IBasicCardCompositeEvent
> = ({ compositeEvent }) => {
  const navigate = useNavigate();

  const { id, name, place, finished, init_period, end_period } = compositeEvent;

  const handleDialog = () => {
    navigate(`/composite-event/detail/${id}`);
  };

  return (
    <Grid item xs={12} sm={6} md={3} key={id} className={style.styleGrid}>
      <Card className={style.card}>
        <CardContent className={style.cardContent}>
          <List dense={true} className={style.styleList}>
            <ListItem disablePadding>
              <ListItemText primary="Nome do evento" secondary={name} />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText primary="Local" secondary={place} />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Evento finalizado"
                secondary={finished ? 'Sim' : 'Não'}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Datas"
                secondary={`Inicio:${init_period} / Fim: ${end_period}`}
              />
            </ListItem>
          </List>

          <Divider />

          <CardActions disableSpacing className={style.cardAction}>
            <Button onClick={handleDialog} variant="contained" color="primary">
              Entrar
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default BasicCardCompositeEvent;
