/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import {
  TextField,
  Box,
  Button,
  Typography,
  MenuItem,
  Select,
  SelectChangeEvent,
  Alert,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowId,
} from '@mui/x-data-grid';
import { useFormContext, useWatch, Controller } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import HeaderDescription from '../../../components/HeaderDescription';

const StepThree: React.FC = () => {
  const {
    control,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const items = useWatch({ name: 'checkin_certificate', defaultValue: [] });
  const [threshold, setThreshold] = useState<number | string>(1);
  const [comparison, setComparison] = useState('>');

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'aprove', headerName: 'Está', width: 200 },
    { field: 'threshold', headerName: 'Número de check-in', width: 150 },
    {
      field: 'actions',
      headerName: 'Ações',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          onClick={() => handleDelete(params.id)}
        >
          Deletar
        </Button>
      ),
    },
  ];

  const handleAddItem = () => {
    if (items.length > 0) return; // Apenas um item é permitido
    if (typeof threshold !== 'number' || threshold < 0) return; // Não adicionar se o valor for inválido

    const newItem = {
      id: items.length + 1,
      threshold: threshold,
      aprove: 'Apto para o certificado',
    };

    setValue('checkin_certificate', [...items, newItem]);
  };

  const handleDelete = (id: GridRowId) => {
    setValue(
      'checkin_certificate',
      items.filter((item: { id: GridRowId }) => item.id !== id),
    );
    clearErrors('checkin_certificate');
  };

  const handleComparisonChange = (event: SelectChangeEvent<string>) => {
    setComparison(event.target.value);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <HeaderDescription
        title="Configuração da quantidade de check-ins necessários para obter o certificado."
        subtitle="Aqui, você determina a quantidade de check-ins necessários para que o aluno obtenha o certificado, ou a quantidade que resultará na não obtenção do certificado."
      />
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        mb={2}
        sx={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}
      >
        <div
          style={{
            marginTop: '0.5rem',
          }}
        >
          <Select
            value={comparison}
            onChange={handleComparisonChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Comparison' }}
            sx={{ minWidth: 140 }}
          >
            <MenuItem value=">">{`Maior ou igual que (>=)`}</MenuItem>
          </Select>
        </div>
        <TextField
          type="number"
          value={threshold}
          defaultValue={1}
          onChange={(e) => setThreshold(Number(e.target.value))}
          margin="normal"
          sx={{ width: 100 }}
          inputProps={{ min: 1 }}
          error={typeof threshold !== 'number' || threshold < 0}
        />
        <Typography color="green" sx={{ whiteSpace: 'nowrap' }}>
          APTO PARA O CERTIFICADO
        </Typography>
        <Box flexGrow={1} />
        <Button
          variant="contained"
          onClick={handleAddItem}
          disabled={
            typeof threshold !== 'number' || threshold < 0 || items.length > 0
          }
        >
          ADICIONAR
        </Button>
      </Box>
      {typeof threshold !== 'number' || threshold < 0 ? (
        <Alert severity="error">Insira um número maior ou igual a 0</Alert>
      ) : null}
      {errors.checkin_certificate && (
        <Alert severity="error">
          {String(errors.checkin_certificate.message)}
        </Alert>
      )}
      <Box>
        <DataGrid rows={items} columns={columns} autoHeight />
      </Box>
    </Box>
  );
};

export default StepThree;
