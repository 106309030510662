/* eslint-disable @typescript-eslint/no-explicit-any */
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { IStudent } from '../interfaces';

interface Headers {
  CPF: string;
  name: string;
  email: string;
  whatsapp: string;
  checkin: string;
  percentage: string;
}

// Função para aplicar máscara no CPF
const maskCPF = (cpf: string): string => {
  // Remove caracteres não numéricos
  cpf = cpf.replace(/\D/g, '');

  // Aplica a máscara no CPF
  if (cpf.length >= 3) {
    return `${cpf.substring(0, 3)}.***.***-${cpf.substring(9)}`;
  } else {
    return cpf; // Retorna o CPF original se não for possível aplicar a máscara
  }
};

export const exportToExcel = (nameFile: string, data: IStudent[]): void => {
  // Mapeamento das colunas para português
  const headers: Headers = {
    CPF: 'CPF',
    name: 'Nome',
    email: 'Email',
    whatsapp: 'Whatsapp',
    checkin: 'Check-in',
    percentage: 'Frequência',
  };

  // Converte os dados e mapeia os nomes das colunas para português
  const formattedData = data.map((item) => {
    return {
      CPF: maskCPF(item.CPF), // Aplica a máscara no CPF
      Nome: item.name,
      Email: item.email,
      Whatsapp: item.whatsapp,
      'Check-in': item.checkin,
      Frequência: `${item.percentage}%`,
    };
  });

  // Cria a planilha com os dados formatados
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(formattedData);

  // Adiciona os cabeçalhos traduzidos
  XLSX.utils.sheet_add_aoa(worksheet, [Object.values(headers)], {
    origin: 'A1',
  });

  // Ajusta o tamanho das colunas
  const maxLengths: { [key: string]: number } = {};
  formattedData.forEach((row) => {
    Object.keys(row).forEach((key) => {
      const value = row[key as keyof typeof row];
      if (value != null) {
        // Check for null or undefined
        const length = value.toString().length;
        maxLengths[key] = Math.max(maxLengths[key] || 0, length);
      }
    });
  });

  // Ajusta o tamanho das colunas, garantindo que "Check-in" e "Frequência" tenham tamanhos fixos
  worksheet['!cols'] = Object.keys(headers).map((header) => {
    if (header === 'checkin') {
      return { wch: 'Check-in'.length }; // tamanho fixo para a coluna "Check-in"
    }
    if (header === 'percentage') {
      return { wch: 'Frequência'.length }; // tamanho fixo para a coluna "Frequência"
    }
    return { wch: maxLengths[headers[header as keyof Headers]] || 10 };
  });

  // Cria o workbook e adiciona a planilha
  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Converte para buffer e salva o arquivo
  const excelBuffer: any = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  });
  const blob: Blob = new Blob([excelBuffer], {
    type: 'application/octet-stream',
  });
  saveAs(blob, `${nameFile}.xlsx`);
};
