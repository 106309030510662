/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridSelectionModel,
  ptBR,
  GridColumnVisibilityModel,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { useState } from 'react';
import { IStudent, ISupplier, IUser } from '../../../core/interfaces';

export interface IGridProps {
  columns: GridColDef[];
  rows: GridRowsProp;
  checkboxSelection?: boolean;
  selectedValue?: (value: Array<object>) => void;
  selectedNewValue?: (value: Array<object>) => void;
  defaultValue?: ISupplier[] | IStudent[] | IUser[];
  loading?: boolean;
  className?: string;
  columnVisibilityModel?: GridColumnVisibilityModel;
  processRowUpdate?: (newRow: any, oldRow: any) => GridValidRowModel;
}

const DBGrid: React.FunctionComponent<IGridProps> = ({
  columns,
  rows,
  checkboxSelection,
  selectedValue,
  selectedNewValue,
  defaultValue,
  loading,
  className,
  columnVisibilityModel,
  processRowUpdate,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialRows, setInitialRows] = useState(rows);

  const values: Array<number> = [];
  if (defaultValue) {
    for (let i = 0; i < defaultValue.length; i++) {
      values.push(defaultValue[i].id);
    }
  }

  const [selectionModel, setSelectionModel] =
    useState<GridSelectionModel>(values);
  return (
    <Box sx={{ height: 440, width: '100%' }} className={className}>
      <DataGrid
        loading={loading}
        rows={rows}
        columns={columns}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processRowUpdate}
        editMode="row"
        checkboxSelection={checkboxSelection}
        onSelectionModelChange={(ids) => {
          setSelectionModel(ids);
          const selectedIDs = new Set(ids);
          const selectedRows = rows.filter((row) => selectedIDs.has(row.id));
          selectedValue?.(selectedRows);
          selectedNewValue?.(selectedRows);
        }}
        selectionModel={selectionModel}
        columnVisibilityModel={columnVisibilityModel}
      />
    </Box>
  );
};

export default DBGrid;
