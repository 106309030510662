/* eslint-disable prefer-const */
import { AxiosError } from 'axios';
import { patch, get, post } from '../adapters/xhr';
import ErrorAPI from '../../core/models/ErrorAPI';
import { IUser, IInfoUser, ISupplier } from '../../core/interfaces';

export const userAPI = () => ({
  getAlluser: async () => {
    const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
    try {
      let response = await get(`${BaseURL}/api/v1/auth/list`);
      return response.data as Array<IUser>;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }

      return [] as Array<IUser>;
    }
  },
  getInforUser: async () => {
    const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
    try {
      let response = await get(
        `${BaseURL}/api/v1/operation/supplier/information`,
      );
      return response.data as IInfoUser;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }

      return {} as IInfoUser;
    }
  },
  getDataUser: async (document: string) => {
    const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
    try {
      const data = {
        cpf: document,
      };

      let response = await post(
        `${BaseURL}/api/v1/operation/supplier/data/integra`,
        data,
      );
      return response.data as ISupplier;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }

      return {} as ISupplier;
    }
  },
  getPermissionsUser: async () => {
    const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
    try {
      let response = await get(`${BaseURL}/api/v1/auth/authorization`);
      return response.data as IUser;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }

      return {} as IUser;
    }
  },
  updateUser: async (user_id: number, data: object) => {
    const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
    try {
      let response = await patch(
        `${BaseURL}/api/v1/auth/live/${user_id}`,
        data,
      );
      return response.data as IUser;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }

      return {} as IUser;
    }
  },
  createUser: async (data: object) => {
    const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
    try {
      let response = await post(`${BaseURL}/api/v1/auth/create/user`, data);
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI: ErrorAPI;

        switch (response?.status) {
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 409:
            errorAPI = new ErrorAPI(response.status, response.data?.messenger);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.messenger);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(500, 'Ocorreu um erro inesperado.');
            break;
        }

        return errorAPI;
      }

      return 500;
    }
  },
  getAssistant: async () => {
    const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
    try {
      let response = await get(`${BaseURL}/api/v1/assistant/list`);
      return response.data as IUser[];
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }

      return {} as IUser;
    }
  },
});
