import { Navigate, useLocation } from 'react-router-dom';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { getPermissionsUser } from './service/AuthService';
import ErrorAPI from './core/models/ErrorAPI';
import Loading from './ui/components/Loading';

const PrivateRouter = ({
  children,
  routePermissions,
}: {
  children: JSX.Element;
  routePermissions: Record<string, string[]>;
}) => {
  const auth = useAuth();
  const isLoggedIn = auth.isAuthenticated;
  const [userPermissions, setUserPermissions] = useState<string[]>([]);
  const [initialized, setInitialized] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    async function getPermissionsUserAPI() {
      const userInfo = await getPermissionsUser();

      if (userInfo instanceof ErrorAPI) {
        void auth.removeUser();
        void auth.signinRedirect();
      } else {
        const newPermissions = userInfo.permission.map(
          (permission) => permission.name,
        );

        const newRoles = userInfo.roles.map((roles) => roles);

        Cookies.set('roles', JSON.stringify(newRoles), {
          expires: 1,
        });
        Cookies.set('permissions', JSON.stringify(newPermissions), {
          expires: 1,
        });
        setUserPermissions(newPermissions);
        setInitialized(true);
      }
    }

    getPermissionsUserAPI();

    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      auth.signinRedirect();
    }

    return auth.events.addAccessTokenExpired(() => {
      void auth.removeUser();
      void auth.signinRedirect();
    });
  }, [auth]);

  const hasRequiredPermissions = (route: string) => {
    const requiredPermissions = routePermissions[route] || [];
    return requiredPermissions.every((permission) =>
      userPermissions.includes(permission),
    );
  };

  if (!initialized) {
    return <Loading open={true} />;
  }

  if (currentPath === '/settings') {
    return isLoggedIn && hasRequiredPermissions(currentPath) ? (
      children
    ) : (
      <Navigate to="/login" />
    );
  }

  return isLoggedIn && hasRequiredPermissions(currentPath) ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRouter;
