/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  TextField,
  Box,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';
import Loading from '../../components/Loading';
import { searchClassroomsBuildInIntegra } from '../../../service/ClassroomService';
import ErrorAPI from '../../../core/models/ErrorAPI';
import { IClassroom } from '../../../core/interfaces';
import CardClassroomInBuild from '../../components/CardClassroomInBuild';

interface FilterComponentProps {
  onFilterChange: (filter: string, filterType: string) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  onFilterChange,
}) => {
  const [filter, setFilter] = useState<string>('');
  const [filterType, setFilterType] = useState<string>('code'); // default filter type

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFilter = event.target.value;
    setFilter(newFilter);
    onFilterChange(newFilter, filterType);
  };

  const handleFilterTypeChange = (event: SelectChangeEvent<string>) => {
    const newFilterType = event.target.value as string;
    setFilterType(newFilterType);
    onFilterChange(filter, newFilterType);
  };

  return (
    <Box
      mb={2}
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      justifyContent="space-between"
      alignItems="center"
      gap={2}
    >
      <FormControl variant="outlined" style={{ minWidth: 150 }}>
        <InputLabel id="filter-type-label">Filtrar por</InputLabel>
        <Select
          labelId="filter-type-label"
          value={filterType}
          onChange={handleFilterTypeChange}
          label="Filtrar por"
        >
          <MenuItem value="code">Código</MenuItem>
          <MenuItem value="name">Nome</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Pesquisar"
        variant="outlined"
        value={filter}
        onChange={handleInputChange}
        fullWidth
      />
    </Box>
  );
};

const ListClassroomsIntegraInBuild: React.FC = () => {
  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const [classrooms, setClassrooms] = useState<IClassroom[]>([]);
  const [filteredClassrooms, setFilteredClassrooms] = useState<IClassroom[]>(
    [],
  );
  const [filter, setFilter] = useState<string>('');
  const [filterType, setFilterType] = useState<string>('code'); // default filter type
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchDataAPI() {
      setOpenLoading(true);
      const classroomData = await searchClassroomsBuildInIntegra();
      setOpenLoading(false);

      if (classroomData instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setClassrooms(classroomData);
        setFilteredClassrooms(classroomData);
      }
    }
    fetchDataAPI();
  }, [navigate]);

  useEffect(() => {
    const filteredData = classrooms.filter((classroom) => {
      if (filter === '') {
        return true; // No filter applied, return all classrooms
      }
      if (filterType === 'code') {
        return classroom.code_classroom
          ?.toString()
          .toLowerCase()
          .includes(filter.toLowerCase());
      }
      if (filterType === 'name') {
        return classroom.solution_name
          .toLowerCase()
          .includes(filter.toLowerCase());
      }
      return false;
    });
    setFilteredClassrooms(filteredData);
  }, [filter, filterType, classrooms]);

  const handleFilterChange = (newFilter: string, newFilterType: string) => {
    setFilter(newFilter);
    setFilterType(newFilterType);
  };

  return (
    <>
      <Box m={2} display="flex" flexDirection="column" alignItems="center">
        <FilterComponent onFilterChange={handleFilterChange} />
      </Box>

      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justifyContent="center"
      >
        {filteredClassrooms.map((classroom) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={classroom.code_classroom}
          >
            <CardClassroomInBuild classroom={classroom} />
          </Grid>
        ))}
      </Grid>
      <Loading open={openLoading} />
    </>
  );
};

export default ListClassroomsIntegraInBuild;
