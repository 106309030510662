import { assistantAPI } from '../infra/api/AssistantAPI';

export async function getAssistant() {
  const instaceInfra = assistantAPI();
  const assistant = await instaceInfra.getAssistant();

  return assistant;
}

export async function sendAssistant(id_classroom: string, data: object) {
  const instaceInfra = assistantAPI();
  const assistant = await instaceInfra.sendAssistant(id_classroom, data);

  return assistant;
}

export async function getAssistantDisabled() {
  const instaceInfra = assistantAPI();
  const assistant = await instaceInfra.getAssistantDisabled();

  return assistant;
}

export async function activateUser(data: object) {
  const instaceInfra = assistantAPI();
  const assistant = await instaceInfra.activateUser(data);

  return assistant;
}
