/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  MenuItem,
  DialogTitle,
  DialogActions,
  Dialog,
} from '@mui/material';
import RangerNumber from '../RangeNumber';
import Select, { SingleValue } from 'react-select';
import { useState, useMemo, useEffect, useCallback } from 'react';
import SnackBar from '../SnackBar';
import HeaderDescription from '../HeaderDescription';

import { MaterialReactTable, MRT_Row, type MRT_ColumnDef } from 'material-react-table';
import { ICheckinCertificate, InitDateClassroom } from '../../../core/interfaces';
import { Delete } from '@mui/icons-material';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import TextButton from '../Button';

type OnChangeCallback = (
  dataTable: ICheckinCertificate[]
) => void;

interface IProspDefinedCheckinCertificate{
  amountCheckin?: number;
  eventDuration?: InitDateClassroom;
  initData: ICheckinCertificate[]
}
const DefinedCheckinCertificate: React.FC<IProspDefinedCheckinCertificate &{onChange: OnChangeCallback}> = ({amountCheckin=1,initData,eventDuration,onChange}) => {
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const [selectRow, setSelectRow] = useState<MRT_Row<ICheckinCertificate> | any>();
  const [tableData, setTableData] = useState<ICheckinCertificate[]>(() => initData);
  const [valueRange, SetValueRange] = useState<string | number>(1);
  const [valueSelect, setValueSelect] = useState<boolean>(true);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const options = [
    { value: true,  label: 'Maior ou igual que (>=)' },
  ];

  const hasDuplicateData = (newObj: ICheckinCertificate) => {
    return tableData.find(
      (obj) =>
        obj.threshold === newObj.threshold
    );
  };

  const handleChange = useCallback(() => {
    onChange(
      tableData
    );
  }, [
    tableData,
    onChange,
  ]);

  useEffect(() => {
    handleChange();
  }, [handleChange, tableData]);

  const handleOnClick = () => {
    const newObj: ICheckinCertificate = {
      id: tableData.length + 1,
      aprove:  valueSelect ? 'Apto para o certificado' : 'Inelegível para o certificado',
      threshold: valueRange as number,
    };
    const hasDuplicate = hasDuplicateData(newObj);
    if (hasDuplicate) {
      setSnackbarOpen(true);
      setSnackbarSeverity('info');
      setSnackbarMessage('Esse valor já foi definido.');
      return;
    }
    const newData = tableData.concat(newObj);
    setTableData(newData);
  };

  const formatWithTwoDigits = (number: number) => {
    return number < 10 ? `0${number}` : number;
  }
  const formatDateString =(dateString: InitDateClassroom) => {
    const dateRanges = dateString.eventDurationDates?.split(" - ");
    if (dateRanges) {
      const startDate = new Date(dateRanges[0]);
      const endDate = new Date(dateRanges[1]);
    
      const formattedStartDate = `${formatWithTwoDigits(startDate.getDate())}/${formatWithTwoDigits(startDate.getMonth() + 1)}/${startDate.getFullYear()}`;
      const formattedEndDate = `${formatWithTwoDigits(endDate.getDate())}/${formatWithTwoDigits(endDate.getMonth() + 1)}/${endDate.getFullYear()}`;
     
      const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
      const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
  
      return `${formattedStartDate} - ${formattedEndDate} - ${dayDifference} dias`;
    }

    return `${dateString.eventDurantionDays} dias`;

  }

  const columns = useMemo<MRT_ColumnDef<ICheckinCertificate>[]>(
    () => [
      {
        accessorKey: 'threshold',
        header: 'Número de checkin',
        muiTableBodyCellEditTextFieldProps: () => ({
          type: 'number',
        }),
      },
      {
        accessorKey: 'aprove',
        header: 'Esta',
        muiTableBodyCellEditTextFieldProps: {
          select: true, //change to select for a dropdown
          children: options.map((option) => (
            <MenuItem key={String(option.label)} value={String(option.label)}>
              {option.label}
            </MenuItem>
          )),
        },
      },
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        enableHiding: true,
        size: 80,
      },
    ],
    [],
  );

  const handleChangeSelect = (
    newValue: SingleValue<{ value: boolean; label: string }>,
  ) => {
    if (newValue) {
      setValueSelect(newValue.value);
    }
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  const validateValue = (value: string | number)=>{
    if(value as number > amountCheckin){
      return true;
    }

    return false;
  }

  const handleChangeRangerNumber = (value: string | number) => {
    let disableButton: boolean =  value.toString() === '';
    setButtonDisable(false);
    if (disableButton) {
      setButtonDisable(true);
      return;
    }
    disableButton = validateValue(value)
    if (disableButton) {
      setButtonDisable(true);
      return;
    }
    
    SetValueRange(value);
  };

  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleDialogConfirm = async () => {
    tableData.splice(selectRow.index, 1);
    setTableData([...tableData]);
    setOpenDialog(!openDialog);
    setSnackbarOpen(true);
    setSnackbarSeverity('success');
    setSnackbarMessage('Data deletada com sucesso!');
  };
  return (
    <>
      <HeaderDescription
        title="Defina a presença do aluno."
        subtitle="Aqui, você determina a quantidade de check-ins necessários para que o aluno obtenha o certificado, ou a quantidade que resultará na não obtenção do certificado."
      />
       <List sx={{ bgcolor: 'background.paper' }}>
       { eventDuration && <ListItem alignItems="flex-start">
          <ListItemText
            primary="Duração do eventos(Referente a base de Origem)."
            secondary={
              <>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                 {formatDateString(eventDuration)}
                </Typography>
              </>
            }
          />
        </ListItem>}
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="Número de Check-ins necessários para este evento."
            secondary={
              <>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                 São necessarios: {amountCheckin}
                </Typography>
              </>
            }
          />
        </ListItem>
      </List>
      <Dialog
        open={openDialog}
        onClose={handleDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {' '}
          Você tem certeza que deseja deletar esta data?{' '}
        </DialogTitle>
        <DialogActions>
          <TextButton
            style={{ backgroundColor: 'red' }}
            label="Não"
            onClick={() => {
              handleDialog();
            }}
            variant="contained"
          />
          <TextButton
            label="Sim"
            onClick={() => {
              handleDialogConfirm();
            }}
            variant="contained"
          />
        </DialogActions>
      </Dialog>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'flex-end',
          margin: '1rem',
          zIndex:10,
          position: 'initial'
        }}
      >
     
        <Select
          options={options}
          isSearchable={false}
          onChange={handleChangeSelect}
          defaultValue={{
            label: 'Maior ou igual que (>=)',
            value: true,
          }}
        />
        <RangerNumber
          amountCheckin={amountCheckin}
          onChange={handleChangeRangerNumber}
        />
        <Button color={valueSelect?"success":"error"}>
            {valueSelect ? 'Apto para o certificado' : 'Inelegível para o certificado'}
        </Button>
        <Typography variant="subtitle2" gutterBottom>

        </Typography>
        <Button
          onClick={handleOnClick}
          disabled={buttonDisable}
          variant="contained"
        >
          Adicionar
        </Button>
      </div>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        initialState={{
          columnVisibility: { id: false },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        enableEditing
        enableFilters
        enableToolbarInternalActions={false}
        enableTopToolbar={false}
        localization={MRT_Localization_PT_BR}
        positionActionsColumn="last"
        renderRowActions={({row}) => (
          <Box sx={{ display: 'flex', gap: '1rem', marginLeft: 'auto' }}>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton
                color="error"
                onClick={() => {
                  handleDialog();
                  setSelectRow(row);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={handleCloseSnackBar}
      />
    </>
  );
};

export default DefinedCheckinCertificate;
