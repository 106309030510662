import { MenuItem } from '@material-ui/core';

interface IPropsSupplierMenu {
  onClickSendQRCode: () => void;
  onClickSendEmail: () => void;
}

const SupplierMenu: React.FC<IPropsSupplierMenu> = ({
  onClickSendQRCode,
  onClickSendEmail,
}) => {
  return (
    <>
      <MenuItem key="sendQRCode" onClick={onClickSendQRCode}>
        Reenviar QR Code
      </MenuItem>
      <MenuItem key="sendByEmail" onClick={onClickSendEmail}>
        Reenviar por E-mail
      </MenuItem>
    </>
  );
};

export default SupplierMenu;
