/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line
import "rsuite/dist/rsuite.min.css";
// eslint-disable-next-line
import React, { useState, useEffect, useCallback } from 'react';
import {
  Modal,
  TextField,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControl,
  FormHelperText,
  Box,
  Snackbar,
  Alert,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  Grid,
} from '@mui/material';
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import { useFormContext, useWatch } from 'react-hook-form';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { DateRangePicker } from 'rsuite';

import generateDateRange from '../../../../core/utils/generateDateRange';
import { IShift, IShiftsWithDate } from '../../../../core/interfaces';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import ErrorAPI from '../../../../core/models/ErrorAPI';
import { getAllshifts } from '../../../../service/ShiftsService';
import HeaderDescription from '../../../components/HeaderDescription';
import { capitalizeFirstLetter } from '../../../../core/utils';

const StepTwo: React.FC = () => {
  const {
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();
  const beforeToday = DateRangePicker.beforeToday();
  const checkin_number_date = useWatch({
    name: 'checkin_number_date',
    defaultValue: [],
  });
  const selectedShifts = useWatch({ name: 'selectedShifts', defaultValue: [] });
  const navigate = useNavigate();

  const [dateRange, setDateRange] = useState<DateRange | null>(
    getValues('dateRange') || null,
  );
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('error');
  const [shifts, setShifts] = useState<IShift[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<IShiftsWithDate | null>(null);

  const openEditModal = (item: IShiftsWithDate) => {
    setEditData(item);
    setModalOpen(true);
  };

  const handleEditChange =
    (prop: keyof IShiftsWithDate) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (editData) {
        setEditData({ ...editData, [prop]: event.target.value });
      }
    };

  const saveEdit = () => {
    if (editData) {
      if (!editData.number_checkin || Number(editData.number_checkin) <= 0) {
        setSnackbarMessage('A quantidade de checkins deve ser maior que zero.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      const updatedItems = checkin_number_date.map((item: IShiftsWithDate) =>
        item.id === editData.id ? editData : item,
      );

      setValue('checkin_number_date', updatedItems);
      handleModalClose();
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setEditData(null);
  };

  const retrieveSelectedShiftsFromItems = useCallback(() => {
    const selectedShiftsIds = checkin_number_date.map(
      (item: IShiftsWithDate) => item.id.split('-')[0],
    );
    return shifts.filter((shift) => selectedShiftsIds.includes(shift.id));
  }, [checkin_number_date, shifts]);

  useEffect(() => {
    async function searchDataShiftsAPI() {
      const shifts = await getAllshifts();
      if (shifts instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setShifts(shifts);
      }
    }
    searchDataShiftsAPI();
  }, [navigate]);

  // useEffect(() => {
  //   const selectedShifts = retrieveSelectedShiftsFromItems();
  //   setValue('selectedShifts', selectedShifts);
  // }, [checkin_number_date, retrieveSelectedShiftsFromItems, setValue]);

  const handleDeleteItem = (id: GridRowId) => {
    const updatedItems = checkin_number_date.filter(
      (item: IShiftsWithDate) => item.id !== id,
    );
    setValue('checkin_number_date', updatedItems);
  };

  const valueFormatter = (params: { value: string | number | Date }) => {
    const date = new Date(params.value);
    const adjustedDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60000,
    );
    return format(adjustedDate, 'dd/MM/yyyy', { locale: ptBR });
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Horário', width: 150 },
    {
      field: 'date',
      headerName: 'Data',
      width: 150,
      valueFormatter: valueFormatter,
    },
    {
      field: 'number_checkin',
      headerName: 'Quantidade de Checkin',
      width: 180,
      editable: true,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="delete"
            color="error"
            onClick={() => handleDeleteItem(params.id)}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => openEditModal(params.row as IShiftsWithDate)}
          >
            <EditIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const handleShiftChange = (shiftId: string, checked: boolean) => {
    const updatedSelectedShifts = checked
      ? [...selectedShifts, shiftId]
      : selectedShifts.filter((id: string) => id !== shiftId);

    setValue('selectedShifts', updatedSelectedShifts);
    updateShiftedData(updatedSelectedShifts, dateRange);
  };

  const handleDateRangePickerChange = (value: DateRange | null) => {
    setDateRange(value);
    setValue('dateRange', value);
    updateShiftedData(selectedShifts, value);
  };

  const updateShiftedData = (
    selectedShiftIds: string[],
    value: DateRange | null = dateRange,
  ) => {
    const selectedShifts = shifts.filter((shift) =>
      selectedShiftIds.includes(shift.id!),
    );
    if (value && selectedShifts.length > 0) {
      const startDate = new Date(value[0]);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(value[1]);
      endDate.setHours(0, 0, 0, 0);
      const dateRangeArray = generateDateRange(
        startDate.toISOString(),
        endDate.toISOString(),
      );
      const shiftedData = createShiftedData(selectedShifts, dateRangeArray, 1);
      setValue('checkin_number_date', shiftedData);
      setValue('start_date_event', startDate.toISOString().split('T')[0]);
      setValue('end_date_event', endDate.toISOString().split('T')[0]);
    } else {
      setValue('checkin_number_date', []);
    }
  };

  const createShiftedData = (
    shifts: IShift[],
    dateRange: Date[],
    checkinNumber: number,
  ): IShiftsWithDate[] => {
    return shifts.flatMap(
      (shift) =>
        dateRange.map((date) => ({
          id: `${shift.id},${date.toISOString()}`,
          name: capitalizeFirstLetter(shift.name),
          active: shift.active,
          date: date.toISOString().split('T')[0],
          number_checkin: checkinNumber,
        })) as IShiftsWithDate[],
    );
  };

  return (
    <>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <HeaderDescription
        title="Configuração de horário de check-in"
        subtitle="Aqui, você determina os dias e horários disponíveis para fazer o check-in nesta sala."
      />
      <Box margin="normal">
        <DateRangePicker
          value={dateRange}
          onChange={handleDateRangePickerChange}
          editable={false}
          onOk={handleDateRangePickerChange}
          shouldDisableDate={beforeToday}
          format="dd/MM/yyyy"
          placeholder="Selecione uma data ou um período."
          showOneCalendar
          style={{ width: '100%' }}
        />
      </Box>
      <FormControl fullWidth margin="normal">
        <FormGroup>
          <Grid container spacing={2}>
            {shifts.map((shift, index) => (
              <Grid item xs={12} sm={6} md={4} key={shift.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedShifts.includes(shift.id)}
                      onChange={(e) =>
                        handleShiftChange(shift.id!, e.target.checked)
                      }
                    />
                  }
                  label={shift.name}
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
      </FormControl>
      <Box margin="normal">
        <DataGrid
          rows={checkin_number_date}
          columns={columns}
          pageSize={5}
          autoHeight
          disableSelectionOnClick
        />
        {errors.checkin_number_date && (
          <FormHelperText error>
            {String(errors.checkin_number_date.message)}
          </FormHelperText>
        )}
      </Box>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
          }}
        >
          <DialogTitle>Editar Check-in</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Data"
              type="date"
              disabled
              fullWidth
              value={editData?.date ?? ''}
              onChange={handleEditChange('date')}
            />
            <TextField
              margin="dense"
              label="Quantidade de Check-in"
              type="number"
              fullWidth
              value={editData?.number_checkin ?? ''}
              onChange={handleEditChange('number_checkin')}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={saveEdit} color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Box>
      </Modal>
    </>
  );
};

export default StepTwo;
