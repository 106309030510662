import { Grid } from '@mui/material';
import { ICompositeEvent } from '../../../core/interfaces';
import BasicCardCompositeEvent from '../BasicCompositeEvent';

interface IPropsCardsCompositeEvent {
  compositeEvent: ICompositeEvent[];
}
const CardsCompositeEvent: React.FunctionComponent<
  IPropsCardsCompositeEvent
> = ({ compositeEvent }) => {
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {compositeEvent.map((element) => (
          <BasicCardCompositeEvent
            compositeEvent={element}
            key={compositeEvent.indexOf(element)}
          />
        ))}
      </Grid>
    </>
  );
};

export default CardsCompositeEvent;
