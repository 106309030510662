/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { useNavigate } from 'react-router-dom';
import { classSendCertificate } from '../../../../service/ClassroomService';
import { FileUploader } from 'react-drag-drop-files';
import ErrorAPI from '../../../../core/models/ErrorAPI';
import parse from 'html-react-parser';
import { Button, TextField, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import convertImageToBase64 from '../../../../core/utils/convertImageToBase64';
import SnackBar from '../../../components/SnackBar';
import QrcodeExample from './qrcode_example.png';

type severity = 'success' | 'info' | 'warning' | 'error';
const GenerateCertificate: React.FC = () => {
  const navigate = useNavigate();
  const [contentFront, setContentFront] = useState<string>(
    '<p>Insira o texto aqui</p>',
  );
  const [imageFront, setImageFront] = useState<string | null>(null);
  const [fileFront, setFileFront] = useState<File | null>(null);
  const containerFrontRef = useRef<HTMLDivElement | null>(null);

  const [contentBack, setContentBack] = useState<string>('');
  const [imageBack, setImageBack] = useState<string | null>(null);
  const [fileBack, setFileBack] = useState<File | null>(null);
  const containerBackRef = useRef<HTMLDivElement | null>(null);

  const [checked, setChecked] = useState(true);

  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<severity>('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleEditorChangeFront = (newContent: string) => {
    setContentFront(newContent);
  };

  const handleEditorChangeBack = (newContent: string) => {
    setContentBack(newContent);
  };

  const handleFileBackgroundFront = (file: File) => {
    if (file) {
      setFileFront(file);
      convertImageToBase64(file, (base64String) => {
        setImageFront(base64String);
      });
    }
  };

  const handleOpenSnackBar = (msg: string, severity: severity) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(msg);
    setSnackbarOpen(true);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };
  const handleFileBackgroundBack = (file: File) => {
    if (file) {
      setFileBack(file);
      convertImageToBase64(file, (base64String) => {
        setImageBack(base64String);
      });
    }
  };

  const handleSaveModel = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const htmlFront = containerFrontRef.current;
    const htmlBack = containerBackRef.current;
    const form = event.currentTarget;
    const formData = new FormData(form);
    const certificado_name = formData.get('certificado_name')?.toString();
    if (!htmlFront) {
      handleOpenSnackBar(
        'É fundamental implementar um modelo neste contexto.',
        'warning',
      );
      return;
    }

    if (!htmlBack && checked) {
      handleOpenSnackBar(
        'É fundamental implementar um modelo de verso neste contexto.',
        'warning',
      );
      return;
    }

    if (!certificado_name) {
      return;
    }

    if (!checked) {
      setFileBack(null);
      setContentBack('');
    }
    const htmBackValidate = htmlBack != null ? htmlBack.outerHTML : '';
    const response = await classSendCertificate(
      checked,
      certificado_name,
      htmlFront.outerHTML,
      htmBackValidate,
      fileFront,
      fileBack,
      contentFront,
      contentBack,
    );
    if (response instanceof ErrorAPI) {
      navigate('/logout');
    } else {
      handleOpenSnackBar('Os dados foram enviados com sucesso.', 'success');
    }
  };

  return (
    <>
      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={handleCloseSnackBar}
      />
      <Box style={{ margin: '1rem', display: 'flex', alignItems: 'center' }}>
        <FormControlLabel
          required
          control={
            <Switch
              checked={checked}
              onChange={handleChangeSwitch}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Habilitar o verso do certificado"
        />
      </Box>
      <Box
        component="form"
        onSubmit={handleSaveModel}
        noValidate
        style={{ margin: '1rem', display: 'flex', alignItems: 'center' }}
      >
        <TextField
          id="certificado_name"
          name="certificado_name"
          style={{ margin: '1rem' }}
          fullWidth
          variant="outlined"
          label="Nome do certificado"
        />
        <Divider />
        <Button
          type="submit"
          color="inherit"
          onClick={() => {}}
          style={{ padding: '0 !important' }}
        >
          Enviar Modelo
        </Button>
      </Box>
      <div style={{ margin: '1rem' }}>
        <section>
          <Typography variant="h6" gutterBottom>
            Configure a frente do seu certificado
          </Typography>
          <Divider />
          <CKEditor
            editor={Editor}
            data={contentFront}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onReady={(editor) => {}}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleEditorChangeFront(data);
            }}
          />
          {!imageFront && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '2rem',
              }}
            >
              <FileUploader
                handleChange={handleFileBackgroundFront}
                name="file"
                multiple={false}
                hoverTitle="Solte o arquivo aqui"
                label="Carregue o arquivo aqui"
                types={['JPEG', 'PNG', 'GIF']}
              />
            </div>
          )}
        </section>
        {imageFront && (
          <div
            id="container"
            ref={containerFrontRef}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              position: 'relative',
              alignItems: 'flex-start',
              backgroundImage: `url(${imageFront})`,
              backgroundSize: 'cover',
              backgroundColor: 'black',
              overflow: 'hidden',
              width: '1123px',
              height: '794px',
              margin: '0 auto',
            }}
          >
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                margin: '2rem',
                width: '150px',
                height: '150px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${QrcodeExample})`,
                backgroundColor: 'red',
              }}
            >
              <img
                id="qrcode"
                src={QrcodeExample}
                alt="Girl in a jacket"
                width="150"
                height="150"
              />
            </div>
            <div
              style={{
                width: '100%',
              }}
            >
              {parse(contentFront)}
            </div>
          </div>
        )}

        {checked && (
          <section>
            <Typography variant="h6" gutterBottom>
              Configure o verso do seu certificado
            </Typography>
            <Divider />
            <CKEditor
              editor={Editor}
              data={contentBack}
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              onReady={(editor) => {}}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleEditorChangeBack(data);
              }}
            />
            {!imageBack && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '2rem',
                }}
              >
                <FileUploader
                  handleChange={handleFileBackgroundBack}
                  name="file"
                  multiple={false}
                  hoverTitle="Solte o arquivo aqui"
                  label="Carregue o arquivo aqui"
                  types={['JPEG', 'PNG', 'GIF']}
                />
              </div>
            )}

            {imageBack && (
              <div
                id="container"
                ref={containerBackRef}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  position: 'relative',
                  alignItems: 'flex-start',
                  backgroundImage: `url(${imageBack})`,
                  backgroundSize: 'cover',
                  backgroundColor: 'black',
                  overflow: 'hidden',
                  width: '1123px',
                  height: '794px',
                  margin: '0 auto',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    margin: '2rem',
                    width: '150px',
                    height: '150px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'black',
                  }}
                >
                  <img
                    id="qrcode"
                    src={QrcodeExample}
                    alt="Girl in a jacket"
                    width="150"
                    height="150"
                  />
                </div>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  {parse(contentBack)}
                </div>
              </div>
            )}
          </section>
        )}
      </div>
    </>
  );
};

export default GenerateCertificate;
