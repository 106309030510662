export const imageExtensions = [
  'JPG',
  'JPEG',
  'PNG',
  'GIF',
  'BMP',
  'SVG',
  'WEBP',
  'TIF',
  'TIFF',
  'ICO',
  'JIF',
  'JFIF',
  'JP2',
  'JPX',
  'J2K',
  'J2C',
  'FPX',
  'PCD',
  'PDF',
  'PSD',
  'AI',
  'EPS',
  'RAW',
  'CR2',
  'NEF',
  'ORF',
  'SR2',
  'RAF',
  'DNG',
  'MOS',
  'X3F',
  'ARW',
  'RW2',
  'RWL',
  'SRW',
  'PEF',
  'SRW',
  'NRW',
  'DCR',
  'KDC',
  'MEF',
  'CRW',
  'MRW',
  'SRF',
  '3FR',
  'CAP',
  'FFF',
  'IIQ',
  'WDP',
  'HDP',
  'XPM',
  'PBM',
  'PGM',
  'PPM',
  'PNM',
  'WEBP',
  'HEIF',
  'HEIC',
  'AVIF',
  'AVIFS',
  'BPG',
  'JXL',
  'JPE',
  'PSP',
  'TGA',
  'WDP',
  'HDP',
  // Adicione outras extensões de imagem conforme necessário
];
