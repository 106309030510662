// import { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import DBGrid from '../../../components/Grid';
import style from './style.module.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import ErrorAPI from '../../../../core/models/ErrorAPI';
import { IUser } from '../../../../core/interfaces';
import {
  activateUser,
  getAssistantDisabled,
} from '../../../../service/AssistantService';
import SnackBar from '../../../components/SnackBar';

const ActiveUserTemp = () => {
  const navigate = useNavigate();
  const [rowsAssistant, setRowsAssistant] = useState<IUser[]>([] as IUser[]);
  const [selectedAssistant, setSelectedAssistant] = useState<IUser[]>(
    [] as IUser[],
  );
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const columnsAssistant = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'first_name', headerName: 'Primeiro nome', width: 250 },
    { field: 'last_name', headerName: 'Ultimo nome', width: 250 },
    { field: 'cpf', headerName: 'CPF', width: 150 },
    { field: 'email', headerName: 'E-mail', width: 250, editable: true },
  ];
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');

  useEffect(() => {
    async function getAllData() {
      setLoading(true);
      const data = await getAssistantDisabled();
      setLoading(false);
      if (data instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setRowsAssistant(data);
      }
    }
    getAllData();
  }, []);
  const handleGetValue = (values: object[]) => {
    setDisableButton(true);
    if (values.length > 0) {
      setDisableButton(false);
    }
    setSelectedAssistant(values as IUser[]);
  };

  const handleActivateUser = async () => {
    const data = {
      users: selectedAssistant,
    };
    setLoading(true);
    const response = await activateUser(data);
    if (response instanceof ErrorAPI) {
      navigate('/logout');
    } else {
      if (response === 500) {
        setSnackbarMessage('Ops! Algo deu errado ao ativar o usuário.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Usuário ativado com sucesso!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setRowsAssistant(response);
      }
    }
    setLoading(false);
  };
  return (
    <>
      <div className={style.addBttn}>
        <Button
          disabled={disableButton}
          onClick={handleActivateUser}
          variant="contained"
        >
          Restaurar ativação
        </Button>
      </div>
      <DBGrid
        columnVisibilityModel={{ id: false }}
        checkboxSelection={true}
        selectedValue={handleGetValue}
        columns={columnsAssistant}
        rows={rowsAssistant}
      />
      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
      <Loading open={loading} />
    </>
  );
};

export default ActiveUserTemp;
