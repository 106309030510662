import SideBar from '../../components/Drawer';
import Header from '../../components/Header';
import { useParams } from 'react-router-dom';
import { DrawerProvider } from '../../contexts';
import InformationClassroom from '../../components/InformationClassroom';
import { useEffect } from 'react';
import { useState } from 'react';
import React from 'react';
import { Icon } from '@mui/material';
import style from './style.module.css';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { useNavigate } from 'react-router-dom';
import {
  classData,
  classSendPhoneQRcode,
  classSendEmailQRcode,
} from '../../../service/ClassroomService';
import ErrorAPI from '../../../core/models/ErrorAPI';
import Loading from '../../components/Loading';
import SnackBar from '../../components/SnackBar';
import Cookies from 'js-cookie';
import { hasRole } from '../../../core/utils';
interface Action {
  icon: React.ReactNode;
  name: string;
  onClick: () => void;
}

const ListStudent: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { id_class } = useParams();
  const [buttonAddStudent, setButtonAddStudent] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const roles = Cookies.get('roles');

  let rolesFromCookie = JSON.parse('[]');

  if (roles !== 'undefined' && roles !== undefined) {
    rolesFromCookie = JSON.parse(roles);
  }

  const isManager = hasRole(rolesFromCookie, 'MANAGER');
  const isSupplier = hasRole(rolesFromCookie, 'SUPPLIER');
  useEffect(() => {
    async function serchDataAPI() {
      if (id_class === undefined) {
        navigate('/generate');
        return;
      }
      const classValid = await classData(id_class);

      if (classValid instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        if (
          classValid.EGratis &&
          classValid.EColetiva &&
          !classValid.TurmaAbertaInscricao &&
          !classValid.EventoFinalizado
        ) {
          setButtonAddStudent(true);
        }
        if (classValid.EventoFinalizado) {
          setIsFinished(true);
        }
      }
    }
    serchDataAPI();
  }, [navigate]);

  const handleAddClick = () => {
    navigate(`/classroom/${id_class}/add/student`);
  };

  const handleQRCodeClick = () => {
    navigate(`/scanner/${id_class}`, {
      state: 'ListStudent',
    });
  };

  const handleSendQRCodeClick = async () => {
    setOpenLoading(true);
    const response = await classSendPhoneQRcode(id_class);
    if (response instanceof ErrorAPI) {
      navigate('/logout');
    }
    setOpenLoading(false);
    setSnackbarOpen(true);
    setSnackbarSeverity('success');
    setSnackbarMessage('Reenviado com sucesso!');
  };

  const handleSendByEmail = async () => {
    setOpenLoading(true);

    const response = await classSendEmailQRcode(id_class);
    if (response instanceof ErrorAPI) {
      navigate('/logout');
    }

    setOpenLoading(false);
    setSnackbarOpen(true);
    setSnackbarSeverity('success');
    setSnackbarMessage('Reenviado com sucesso! ');
  };

  const handleAddAssistent = () => {
    navigate(`/assistant/${id_class}`);
  };

  const commonActions: Action[] = [
    { icon: <Icon>qr_code</Icon>, name: 'QR Code', onClick: handleQRCodeClick },
    {
      icon: <Icon>reply_all</Icon>,
      name: 'Reenviar',
      onClick: handleSendQRCodeClick,
    },
    {
      icon: <Icon>mail</Icon>,
      name: 'Reenviar por e-mail',
      onClick: handleSendByEmail,
    },
  ];

  const additionalActions: Action[] = [
    {
      icon: <Icon>group_add</Icon>,
      name: 'Adicionar um assistente de sala',
      onClick: handleAddAssistent,
    },
    { icon: <Icon>add</Icon>, name: 'Add', onClick: handleAddClick },
    ...commonActions,
  ];
  const actionsToShow: Action[] =
    isManager || isSupplier ? additionalActions : commonActions;

  const speedDialActions = (actions: Action[]) =>
    actions.map((action) => (
      <SpeedDialAction
        key={action.name}
        icon={action.icon}
        tooltipTitle={action.name}
        onClick={action.onClick}
      />
    ));

  return (
    <>
      <DrawerProvider>
        <SideBar>
          <Loading open={openLoading} />
          <Header title="Alunos da turma" />
          <InformationClassroom />
          {!isFinished ? (
            !buttonAddStudent ? (
              <Box className={style.speedDial}>
                <SpeedDial
                  ariaLabel="SpeedDial"
                  sx={{ position: 'absolute', bottom: 16, right: 16 }}
                  icon={<SpeedDialIcon />}
                >
                  {speedDialActions(actionsToShow)}
                </SpeedDial>
              </Box>
            ) : (
              <Box className={style.speedDial}>
                <SpeedDial
                  ariaLabel="SpeedDial"
                  sx={{ position: 'absolute', bottom: 16, right: 16 }}
                  icon={<SpeedDialIcon />}
                >
                  {speedDialActions(commonActions)}
                </SpeedDial>
              </Box>
            )
          ) : null}
        </SideBar>
        <SnackBar
          open={snackBarOpen}
          type={snackbarSeverity}
          message={snackBarMessage}
          onClose={() => {
            setSnackbarOpen(false);
          }}
        />
      </DrawerProvider>
    </>
  );
};

export default ListStudent;
