/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios, {
  AxiosError,
  AxiosResponse,
  AxiosRequestConfig,
  AxiosProgressEvent,
} from 'axios';
import { ContentType } from '../../../core/types/TContentType';

function returnAxiosInstance() {
  const instance = Axios.create();

  const baseURL = `${process.env.REACT_APP_BASE_URL_KEYCLOAK}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}:${process.env.REACT_APP_CLIENT_ID_KEYCLOAK}`;
  const session = sessionStorage.getItem(`oidc.user:${baseURL}`);

  let token: string;
  if (session) {
    const values = JSON.parse(session);
    token = values['access_token'];
  }

  instance.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );
  return instance;
}

export async function get(
  url: string,
  apiKey?: string | undefined,
  params?: URLSearchParams,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config?: AxiosRequestConfig<any> | undefined,
) {
  const axios = returnAxiosInstance();
  if (apiKey) {
    axios.defaults.headers.common['Authorization'] = apiKey;
  }
  if (params) {
    url += `?${params.toString()}`;
  }

  const response = await axios.get(url, config);
  return response;
}

export async function post(
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestData?: any,
  apiKey?: string | undefined,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  contentType?: ContentType,
) {
  const axios = returnAxiosInstance();

  const config: AxiosRequestConfig = {
    onUploadProgress: onUploadProgress, // Adicione aqui o callback para o progresso de upload
  };

  if (apiKey) {
    axios.defaults.headers.common['Authorization'] = apiKey;
  }

  if (contentType) {
    axios.defaults.headers.post['Content-Type'] = contentType;
  }
  const response = await axios.post(url, requestData, config);

  return response;
}

export async function patch(
  url: string,
  requestData?: any,
  apiKey?: string | undefined,
  contentType?: ContentType,
) {
  const axios = returnAxiosInstance();
  if (apiKey) {
    axios.defaults.headers.common['Authorization'] = apiKey;
  }

  if (contentType) {
    axios.defaults.headers.post['Content-Type'] = contentType;
  }

  const response = await axios.patch(url, requestData);

  return response;
}

export async function put(
  apiKey: string | undefined,
  url: string,
  requestData: any,
) {
  try {
    const axios = returnAxiosInstance();
    if (apiKey) {
      axios.defaults.headers.common['Authorization'] = apiKey;
    }
    return axios.put(url, requestData);
  } catch (error) {
    if (error instanceof AxiosError) {
      return handleAPIError(error);
    }
  }
}

export async function remove(
  url: string,
  requestData?: any,
  apiKey?: string | undefined,
) {
  const axios = returnAxiosInstance();
  if (apiKey) {
    axios.defaults.headers.common['Authorization'] = apiKey;
  }
  const response = await axios.delete(url, requestData);

  return response;
}

function handleAPIError(error: AxiosError) {
  let res: AxiosResponse;

  if (error.response) {
    console.error(error.response);
    res = error.response;
    return res;
  } else if (error.request) {
    console.error(error.request);
    res = error.request;
    return res;
  } else {
    // Alguma coisa acontenceu ao configurar a requisição que acionou este erro.
    console.error('Error', error.message);
  }
}
