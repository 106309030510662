/* eslint-disable @typescript-eslint/no-unused-vars */
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import SendIcon from '@mui/icons-material/Send';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ListSubheader from '@mui/material/ListSubheader';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { Box, List, ListItemText, Modal } from '@mui/material';
import DialogAlert from '../../DialogAlert';
import TextArea from '../../Textarea';
import { useState, useEffect } from 'react';
import {
  classSendEventProof,
  classSendMessagePhone,
  classSyncStudents,
} from '../../../../service/ClassroomService';
import ErrorAPI from '../../../../core/models/ErrorAPI';
import { useNavigate, useParams } from 'react-router-dom';
import DropFileOrPhoto from '../../DropFileOrPhoto';
import style from './style.module.css';
import Loading from '../../Loading';
import SnackBar from '../../SnackBar';
import LinearProgressWithLabel from '../../LinearProgressWithLabel';
import { FileWithPreview } from '../../../../core/types/TFilesWithPreview';
import linkToFile from '../../../../core/utils/linkToFile';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import QRcode from '../../QRcode';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { IStudent } from '../../../../core/interfaces';
import AttendanceTable from '../../AttendanceTable';
interface IPropsListActionSpeed {
  students: IStudent[];
  defaultFileProofWithPreview?: FileWithPreview[];
  url_qrcode?: string;
}
const ListActionSpeed: React.FC<IPropsListActionSpeed> = ({
  students = [],
  defaultFileProofWithPreview = [],
  url_qrcode,
}) => {
  const { id_class = '/' } = useParams<'id_class'>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const handleDialogOpenOrClose = () => setOpenDialog(!openDialog);
  const [textMessagePhone, setTextMessagePhone] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalQrcode, setOpenModalQrcode] = useState<boolean>(false);
  const [openModalCardeneta, setOpenModalCardeneta] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [files, setFiles] = useState<FileWithPreview[]>(
    defaultFileProofWithPreview,
  );
  useEffect(() => {
    setFiles(defaultFileProofWithPreview);
  }, [defaultFileProofWithPreview]);

  const navigate = useNavigate();

  const handleGetValuesSnack = (
    value: boolean,
    message: string,
    severity: string,
  ) => {
    setSnackbarOpen(value);
    setSnackbarSeverity(severity as 'success' | 'info' | 'warning' | 'error');
    setSnackbarMessage(message);
  };

  const handleSyncClassroom = async () => {
    setOpenLoading(true);
    const response = await classSyncStudents(id_class);
    setOpenLoading(false);
    if (response instanceof ErrorAPI) {
      if (response.statusCode === 401) {
        navigate('/logout');
      }

      if (response.statusCode === 400) {
        handleGetValuesSnack(
          true,
          'Não foi possível encontrar nenhuma fonte de origem.',
          'warning',
        );
      }

      if (response.statusCode === 404) {
        handleGetValuesSnack(
          true,
          'Não foi possível encontrar nenhuma fonte de origem.',
          'warning',
        );
      }
      if (response.statusCode === 500) {
        handleGetValuesSnack(
          true,
          'Algo deu errado. Por favor, tente novamente mais tarde.',
          'warning',
        );
      }

      if (response.statusCode === 503) {
        handleGetValuesSnack(
          true,
          'Algo deu errado. Por favor, tente novamente mais tarde.',
          'warning',
        );
      }
    } else {
      handleGetValuesSnack(true, 'Turma atualizada com sucesso!.', 'success');
      navigate(0);
    }
  };

  const handleModalQrcode = () => setOpenModalQrcode(!openModalQrcode);

  const handleDialog = async () => {
    setOpenLoading(true);
    const response = await classSendMessagePhone(id_class, textMessagePhone);
    setOpenLoading(false);
    if (response instanceof ErrorAPI) {
      if (response.statusCode === 401) {
        navigate('/logout');
      }

      if (response.statusCode === 400) {
        handleGetValuesSnack(
          true,
          'Mensagem não enviada. Por favor, tente novamente mais tarde.',
          'warning',
        );
      }

      if (response.statusCode === 404) {
        handleGetValuesSnack(
          true,
          'Mensagem não enviada. Por favor, tente novamente mais tarde.',
          'warning',
        );
      }
      if (response.statusCode === 500) {
        handleGetValuesSnack(
          true,
          'Mensagem não enviada. Por favor, tente novamente mais tarde.',
          'warning',
        );
      }

      if (response.statusCode === 503) {
        handleGetValuesSnack(
          true,
          'Mensagem não enviada. Por favor, tente novamente mais tarde.',
          'warning',
        );
      }
    } else {
      handleGetValuesSnack(
        true,
        'Envio de mensagem concluído com sucesso! Sua mensagem foi entregue com êxito.',
        'success',
      );
    }
    handleDialogOpenOrClose();
  };

  const handleModal = () => setOpenModal((prevent) => !prevent);

  const handleModalCardeneta = () =>
    setOpenModalCardeneta((prevent) => !prevent);
  const handleChangeFiles = async (files: File[]) => {
    if (files) {
      const response = await classSendEventProof(
        id_class,
        files,
        (progressEvent) => {
          if (progressEvent) {
            if (progressEvent.progress) {
              setProgress(progressEvent.progress * 100);
            }
          }
        },
      );

      if (response instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        const filesPromises = response.map(async (value) => {
          return await linkToFile(value.path_file, value.id);
        });

        const files = await Promise.all(filesPromises);
        setProgress(0);
        setFiles(files as FileWithPreview[]);
      }
    }
  };

  return (
    <>
      <DialogAlert
        titleDialog={'Defina a mensagem de envio no campo abaixo.'}
        descriptionDialog={
          'Os alunos cujos números estão registrados em nosso sistema receberão esta mensagem.'
        }
        nameButtonConfirm="Enviar"
        nameButtonCancel="Cancelar"
        openDialog={openDialog}
        onCloseDialog={handleDialogOpenOrClose}
        confirmationButtonCallback={() => {
          handleDialog();
        }}
        cancelButtonCallback={() => {
          handleDialogOpenOrClose();
        }}
      >
        <TextArea
          onChange={(event) => {
            setTextMessagePhone(event.target.value);
          }}
        />
      </DialogAlert>

      <Modal open={openModal} onClose={handleModal}>
        <>
          {progress > 1 && <LinearProgressWithLabel value={progress} />}
          <Box className={style.modal}>
            <DropFileOrPhoto
              defaultsFiles={files}
              onChangeFiles={handleChangeFiles}
            />
          </Box>
        </>
      </Modal>
      <Modal open={openModalCardeneta} onClose={handleModalCardeneta}>
        <div className={style['modal-cardeneta']}>
          <div className={style.modalContent}>
            <div className={style.modalHeader}>
              <h2 className={style.modalTitle}>Lista de Presença</h2>
              <button
                className={style.closeButton}
                onClick={handleModalCardeneta}
              >
                &times;
              </button>
            </div>
            <div className={style.modalBody}>
              <AttendanceTable className={id_class} data={students} />
            </div>
          </div>
        </div>
      </Modal>
      <List
        sx={{
          width: '100%',
          p: 2,
          bgcolor: 'background.paper',
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            style={{ zIndex: 0 }}
            component="div"
            id="nested-list-subheader"
          >
            Ações rápidas.
          </ListSubheader>
        }
      >
        <ListItemButton onClick={handleDialogOpenOrClose}>
          <ListItemIcon>
            <SendIcon />
          </ListItemIcon>
          <ListItemText primary="Enviar mensagem via whatsApp para os alunos da turma." />
        </ListItemButton>

        <ListItemButton onClick={handleSyncClassroom}>
          <ListItemIcon>
            <CloudSyncIcon />
          </ListItemIcon>
          <ListItemText primary="Sincronizar Alunos Locais com Turma de Origem" />
        </ListItemButton>

        <ListItemButton onClick={handleModal}>
          <ListItemIcon>
            <ReceiptLongIcon />
          </ListItemIcon>
          <ListItemText primary="Anexar comprovante de realização do evento." />
        </ListItemButton>

        <ListItemButton onClick={handleModalCardeneta}>
          <ListItemIcon>
            <EventNoteIcon />
          </ListItemIcon>
          <ListItemText primary="Caderneta digital" />
        </ListItemButton>

        <ListItemButton onClick={handleModalQrcode}>
          <ListItemIcon>
            <QrCode2Icon />
          </ListItemIcon>
          <ListItemText primary="Visualizar Qrcode." />
        </ListItemButton>
      </List>
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={openModalQrcode}
        onClose={handleModalQrcode}
      >
        <Box className={style.styleBox}>
          <QRcode url_qrcode={url_qrcode} />
        </Box>
      </Modal>

      <Loading open={openLoading} />
      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
    </>
  );
};

export default ListActionSpeed;
