/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  FormLabel,
  SelectChangeEvent,
  Tooltip,
  IconButton,
  MenuItem,
  TextField,
  FormGroup,
  Divider,
  FormControl,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  MaterialReactTable,
  type MaterialReactTableProps,
  type MRT_Cell,
  type MRT_ColumnDef,
  type MRT_Row,
} from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { Delete, Edit } from '@mui/icons-material';
import {
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';

import style from './style.module.css';

import HeaderDescription from '../HeaderDescription';
// eslint-disable-next-line
import { DateRange } from 'rsuite/esm/DateRangePicker';
// eslint-disable-next-line
import { DateRangePicker } from 'rsuite';

import Checkmarks from '../Checkmarks';

import { IShiftsWithDate, IShift, IEventDate } from '../../../core/interfaces';
import generateDateRange from '../../../core/utils/generateDateRange';
import SnackBar from '../SnackBar';
import { capitalizeFirstLetter } from '../../../core/utils';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import TextButton from '../../components/Button';

type OnChangeCallback = (
  data: IEventDate,
  initShiftsNames: string[],
  shiftsActives: IShift[],
  dates: DateRange,
) => void;
interface IPropDefinedCheckinShifts {
  shifts: IShift[];
  initDates: DateRange;
  initData: IShiftsWithDate[];
  initShiftsNames: string[];
  initShiftsActives?: IShift[];
  onValueChange: (value: boolean) => void;
  boolIsNull?: boolean;
}

const DefinedCheckinShifts: React.FC<
  IPropDefinedCheckinShifts & { onChange: OnChangeCallback }
> = ({
  shifts,
  boolIsNull,
  onValueChange,
  initData = [],
  initShiftsNames = [],
  initShiftsActives = shifts,
  onChange,
  initDates,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { beforeToday } = DateRangePicker;
  const [isNull, setIsNull] = useState<boolean | undefined>(boolIsNull);
  const [selectRow, setSelectRow] = useState<MRT_Row<IShiftsWithDate> | any>();

  const [tableData, setTableData] = useState<IShiftsWithDate[]>(() => initData);
  const [shiftName, setShiftNames] = useState<string[]>(initShiftsNames);
  const [shiftsActives, setShiftsActives] =
    useState<IShift[]>(initShiftsActives);
  const [dates, setDates] = useState<Date[]>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [initDate, setInitDate] = useState<DateRange>(initDates);

  // const [disableSwift, setDisableSwift] = useState<boolean>(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedShiftId, setSelectedShiftId] = useState<string | null>(null);

  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');

  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const handleChange = useCallback(() => {
    onChange(
      { startDate, endDate, tableData },
      shiftName,
      shiftsActives,
      initDate,
    );
  }, [
    startDate,
    endDate,
    tableData,
    onChange,
    shiftName,
    shiftsActives,
    initDate,
  ]);

  useEffect(() => {
    handleChange();
  }, [handleChange, tableData]);

  useEffect(() => {
    handleEventDate(initDate, false);
  }, []);

  const getCommonEditTextFieldProps = useCallback(
    (
      cell: MRT_Cell<IShiftsWithDate>,
    ): MRT_ColumnDef<IShiftsWithDate>['muiTableBodyCellEditTextFieldProps'] => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isNumberCheckin =
            cell.column.id === 'number_checkin'
              ? parseInt(event.target.value) <= 0
              : false;

          if (isNumberCheckin) {
            setValidationErrors({
              ...validationErrors,
              [cell.id]:
                'O número de check-in deve ser maior que zero e não pode ser negativo.',
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  const columns = useMemo<MRT_ColumnDef<IShiftsWithDate>[]>(
    () => [
      {
        accessorKey: 'date',
        header: 'Data',
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({
          cell,
        }: {
          cell: MRT_Cell<IShiftsWithDate>;
        }) => ({
          type: 'date',
          children: (
            <DatePicker
              value={selectedDate || new Date(String(cell.getValue()))}
              onChange={(date) => setSelectedDate(date)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={selectedDate ? selectedDate : ''}
                />
              )}
            />
          ),
        }),
        Cell: ({ row }) => {
          const formattedDate = row.original.date.replace(
            /^(\d{4})-(\d{2})-(\d{2})$/,
            '$3/$2/$1',
          );

          return formattedDate;
        },
      },
      {
        accessorKey: 'name',
        header: 'Horario',
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'number',
          select: true,
          children: shifts.map((shift) => (
            <MenuItem
              key={shift.id}
              value={capitalizeFirstLetter(shift.name)}
              onClick={() => setSelectedShiftId(shift.id ? shift.id : null)}
            >
              {capitalizeFirstLetter(shift.name)}
            </MenuItem>
          )),
        }),
      },
      {
        accessorKey: 'number_checkin',
        header: 'Quantidade de Checkin',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'number',
        }),
      },
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        enableHiding: true,
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps, selectedDate, shifts],
  );

  const handleSaveRowEdits: MaterialReactTableProps<IShiftsWithDate>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        const selectedShiftId =
          shifts.find(
            (shift) => capitalizeFirstLetter(shift.name) === values.name,
          )?.id || '';
        const existingCombination = tableData.find(
          (rowData) =>
            rowData.date === values.date && rowData.id === selectedShiftId,
        );

        if (
          existingCombination &&
          existingCombination !== tableData[row.index]
        ) {
          setSnackbarOpen(true);
          setSnackbarSeverity('warning');
          setSnackbarMessage('Essa combinação de data e horário já existe.');
          return;
        }
        values.date = selectedDate?.toLocaleDateString() ?? values.date;
        tableData[row.index] = { ...values, id: selectedShiftId, active: true };
        //send/receive api updates here, then refetch or update local table data for re-render

        setTableData([...tableData]);
        exitEditingMode(); //required to exit editing mode and close modal
      }
    };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  const createShiftedData = (
    shifts: IShift[],
    dateRange: Date[],
    checkinNumber: number,
  ): IShiftsWithDate[] => {
    setTableData([]);
    return shifts
      .filter((shift) => shift.active)
      .flatMap((shift) =>
        dateRange.map(
          (date) =>
            ({
              id: shift.id,
              name: capitalizeFirstLetter(shift.name),
              active: shift.active,
              date: date.toISOString().split('T')[0], // Converta a data para o formato "yyyy-mm-dd"
              number_checkin: checkinNumber, // Preencha o número correto de check-in aqui
            }) as IShiftsWithDate,
        ),
      );
  };

  const handleDateRangePickerChange = (value: DateRange | null) => {
    handleEventDate(value);
  };

  const handleEventDate = (
    value: DateRange | null,
    updateTableData: boolean = true,
  ) => {
    if (value) {
      const startDate = new Date(value[0]);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(value[1]);
      endDate.setHours(0, 0, 0, 0);

      const dateRange = generateDateRange(
        startDate.toISOString(),
        endDate.toISOString(),
      );

      setDates(dateRange);
      setStartDate(startDate.toISOString());
      setEndDate(endDate.toISOString());
      setInitDate(value);
      if (updateTableData) {
        const shiftedData = createShiftedData(shiftsActives, dateRange, 1);
        setTableData(shiftedData);
      }
      setIsNull(false);
      onValueChange(false);
    } else {
      setIsNull(true);
      onValueChange(true);
    }
  };

  const handleChangeCheckmarks = (
    event: SelectChangeEvent<typeof shiftName>,
  ) => {
    const {
      target: { value },
    } = event;

    const shiftsActives = shifts.map((shift) => ({
      ...shift,
      active: value.includes(shift.name),
    }));

    setShiftsActives(shiftsActives);
    setShiftNames(typeof value === 'string' ? value.split(',') : value);
    if (dates.length > 0) {
      const shiftedData = createShiftedData(shiftsActives, dates, 1);
      setTableData(shiftedData);
    }
  };

  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleDialogConfirm = async () => {
    tableData.splice(selectRow.index, 1);
    setTableData([...tableData]);
    setOpenDialog(!openDialog);
    setSnackbarOpen(true);
    setSnackbarSeverity('success');
    setSnackbarMessage('Data deletada com sucesso!');
  };

  return (
    <>
      <HeaderDescription
        title="Configuração de check-in"
        subtitle="Faça a configuração dos checkin para essa turma"
      />

      <Dialog
        open={openDialog}
        onClose={handleDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {' '}
          Você tem certeza que deseja deletar esta data?{' '}
        </DialogTitle>
        <DialogActions>
          <TextButton
            style={{ backgroundColor: 'red' }}
            label="Não"
            onClick={() => {
              handleDialog();
            }}
            variant="contained"
          />
          <TextButton
            label="Sim"
            onClick={() => {
              handleDialogConfirm();
            }}
            variant="contained"
          />
        </DialogActions>
      </Dialog>

      <FormGroup
        className={style.formGroup}
        sx={{
          marginLeft: '1rem',
          marginBottom: '1rem',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <FormControl className={style.formControl}>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Configuração de data do evento
          </FormLabel>
          <DateRangePicker
            onChange={handleDateRangePickerChange}
            onOk={handleDateRangePickerChange}
            shouldDisableDate={beforeToday?.()}
            editable={false}
            format={'dd/MM/yyyy'}
            placeholder={'Selecione uma data ou um período.'}
            defaultValue={isNull ? initDate : initDate}
            showOneCalendar
          />
        </FormControl>

        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          className={style.divider}
        />

        <FormControl className={style.formControl}>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Configuração de horário do evento
          </FormLabel>
          <Checkmarks
            style={{ height: '2.2rem' }}
            value={shiftName}
            items={shiftsActives}
            onChange={handleChangeCheckmarks}
            // disabled={disableSwift}
          />
        </FormControl>

        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          className={style.lastDivider}
        />
      </FormGroup>

      <Box style={{ margin: '10px' }}>
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 120,
            },
          }}
          initialState={{
            columnVisibility: { id: false },
          }}
          columns={columns}
          data={tableData}
          editingMode="modal" //default
          enableEditing
          onEditingRowSave={handleSaveRowEdits}
          onEditingRowCancel={handleCancelRowEdits}
          localization={MRT_Localization_PT_BR}
          positionActionsColumn="last"
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem', marginLeft: 'auto' }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => table.setEditingRow(row)}>
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton
                  color="error"
                  onClick={() => {
                    handleDialog();
                    setSelectRow(row);
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
        <SnackBar
          open={snackBarOpen}
          type={snackbarSeverity}
          message={snackBarMessage}
          onClose={handleCloseSnackBar}
        />
      </Box>
    </>
  );
};

export default DefinedCheckinShifts;
