/* eslint-disable @typescript-eslint/no-unused-vars */
import SideBar from '../../components/Drawer';
import Header from '../../components/Header';
import CardsCompositeEvent from '../../components/CardsBasicCompositeEvent';

import { DrawerProvider } from '../../contexts';
import SnackBar from '../../components/SnackBar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorAPI from '../../../core/models/ErrorAPI';
import Loading from '../../components/Loading';
import FilterCompositeEvent from '../../components/FilterCompositeEvent';
import style from './style.module.css';
import Select, { SingleValue } from 'react-select';
import { FormGroup, FormLabel } from '@mui/material';
import { TFilterCompositeEvent } from '../../../core/types/TFilterCompositeEvent';
import { getAllCompositeEvent } from '../../../service/CompositeEventOperationService';
import { ICompositeEvent } from '../../../core/interfaces';

const ListCompositeEvent: React.FunctionComponent = () => {
  const [compositeEvent, setCompositeEvent] = useState<ICompositeEvent[]>([]);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const [typeFilter, setTypeFilter] = useState<TFilterCompositeEvent>('status');

  const navigate = useNavigate();

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  const handleChangeFilter = (
    newValue: SingleValue<{ value: string; label: string }>,
  ) => {
    const typeFilter: TFilterCompositeEvent = newValue?.value
      ? (newValue.value as TFilterCompositeEvent)
      : 'status';

    setTypeFilter(typeFilter);
  };

  const options = [
    { value: 'name', label: 'Por nome' },
    { value: 'status', label: 'Por status' },
    { value: 'date', label: 'Por periodo' },
  ];

  const handleOpenSnackBar = () => {
    setSnackbarMessage('Não encontramos nenhum evento.');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
  };

  useEffect(() => {}, []);

  const handleParams = (value: string, typeParams?: TFilterCompositeEvent) => {
    const params = new URLSearchParams();
    if (typeParams === 'status') {
      const status = value !== 'finished' ? 'True' : 'False';
      params.append('finished', status);
    }

    if (typeParams === 'name') {
      params.append('name', value);
    }

    if (typeParams === 'date') {
      const dates = value.split(/\s*,\s*/);
      params.append('init_period', dates[0]);
      params.append('end_period', dates[1]);
    }
    return params;
  };

  useEffect(() => {
    async function serchDataAPI() {
      const params = handleParams('finished', 'status');
      setOpenLoading(true);
      const compisteEvents = await getAllCompositeEvent(params);
      setOpenLoading(false);

      if (compisteEvents instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        if (compisteEvents.length === 0) {
          handleOpenSnackBar();
        }
        setCompositeEvent(compisteEvents);
      }
    }

    serchDataAPI();
  }, []);

  const handleInput = async (
    value: string,
    typeFilter: TFilterCompositeEvent,
  ) => {
    const params = handleParams(value, typeFilter);
    setOpenLoading(true);
    const compisteEvents = await getAllCompositeEvent(params);
    setOpenLoading(false);
    if (compisteEvents instanceof ErrorAPI) {
      navigate('/logout');
    } else {
      if (compisteEvents.length === 0) {
        handleOpenSnackBar();
      }
      setCompositeEvent(compisteEvents);
    }
  };

  return (
    <>
      <div className={style.container_filter}>
        <FormGroup>
          <FormLabel component="legend">Selecione o tipo de filtro</FormLabel>
          <Select
            options={options}
            className={style.select}
            isSearchable={false}
            onChange={handleChangeFilter}
            defaultValue={options[1]}
          />
        </FormGroup>
        <FilterCompositeEvent
          typeFilter={typeFilter}
          getValueWithType={handleInput}
        />
      </div>

      <Loading open={openLoading} />
      <CardsCompositeEvent compositeEvent={compositeEvent} />
      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={handleCloseSnackBar}
      />
    </>
  );
};

export default ListCompositeEvent;
