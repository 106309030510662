/* eslint-disable @typescript-eslint/no-unused-vars */
import SideBar from '../../components/Drawer';
import Header from '../../components/Header';
import style from './style.module.css';
import SnackBar from '../../components/SnackBar';
import { DrawerProvider } from '../../contexts';
import { QrReader } from 'react-qr-reader';
import { Result } from '@zxing/library';
import { isJson } from '../../../core/utils';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import SwitchCameraIcon from '@mui/icons-material/SwitchCamera';
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function Scanner() {
  const { id_class } = useParams();
  const navigate = useNavigate();
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const { state } = useLocation();
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>(
    'environment',
  );
  const [qrReaderKey, setQrReaderKey] = useState(0); // Add state for QR reader key
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    startTimeout();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const startTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(handleGoBack, 20000); // 20 segundos
  };

  const HandleonResult = (
    result: Result | null | undefined,
    error: Error | null | undefined,
  ) => {
    if (result) {
      if (isJson(result.getText())) {
        navigate(`/checkin/${id_class}`, {
          state: {
            data: JSON.parse(result.getText()),
            origin: state.origin,
            placePerformanceID: state.placePerformanceID,
          },
        });
      } else {
        setSnackbarOpen(true);
        setSnackbarSeverity('error');
        setSnackbarMessage('QR Code Invalido');
        startTimeout(); // Reinicia o temporizador se um QR code inválido for detectado
      }
    }
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  const handleGoBack = () => {
    if (state === null) {
      navigate(`/classroom/detail/${id_class}`); // Navegar para a página anterior
      return;
    }

    if (state.origin !== 'classroomInfo') {
      navigate(`/classroom/detail/${id_class}`); // Navegar para a página anterior
    } else {
      navigate(`/information/place/performance/${state.placePerformanceID}`); // Navegar para a página anterior
    }
  };

  const toggleFacingMode = () => {
    setFacingMode((prevMode) =>
      prevMode === 'environment' ? 'user' : 'environment',
    );
    setQrReaderKey((prevKey) => prevKey + 1); // Change key to force re-render
    startTimeout(); // Reinicia o temporizador ao trocar de câmera
  };

  return (
    <>
      <DrawerProvider>
        <SideBar>
          <Header title="Leitura do QRcode " />

          <QrReader
            key={qrReaderKey} // Add key prop to force re-render
            onResult={HandleonResult}
            scanDelay={500}
            constraints={{ facingMode }}
            videoId="video"
            containerStyle={{
              position: 'fixed',
              width: '100%',
              height: '100%',
            }}
            videoContainerStyle={{
              width: '100%',
              height: '100%',
              paddingTop: '0px',
              position: 'static',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            videoStyle={{
              width: '100%',
              height: '100%',
              position: 'unset',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              objectFit: 'cover',
            }}
          />
          <div className={style.element}> </div>

          <div className={style.footerMenu}>
            <Button
              variant="contained"
              onClick={handleGoBack}
              className={style.addBttn}
            >
              Voltar
            </Button>
            <IconButton
              onClick={toggleFacingMode}
              className={style.addBttn}
              style={{ marginLeft: '10px', padding: '10px' }}
            >
              <SwitchCameraIcon />
            </IconButton>
          </div>
        </SideBar>
        <SnackBar
          open={snackBarOpen}
          type={snackbarSeverity}
          message={snackBarMessage}
          onClose={handleCloseSnackBar}
        />
      </DrawerProvider>
    </>
  );
}
