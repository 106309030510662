import { IMaskInput } from 'react-imask';
import React, { RefObject } from 'react';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustomCNPJ = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="**.***.***/****-**"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref as RefObject<HTMLInputElement>}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

export default TextMaskCustomCNPJ;
