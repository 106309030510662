export function maskDate(e?: string): string {
  if (e !== undefined) {
    let value = e;
    value = value.replace(/\D/g, '');
    value = value.substring(0, 8); // Remove caracteres a partir do índice
    const ano = value.slice(0, 4); // Primeiros 4 dígitos
    const mes = value.slice(4, value.length - 2); // Dígitos intermediários
    const dia = value.slice(value.length - 2); // Últimos 2 dígitos
    value = dia + mes + ano; // Reorganiza a ordem dos dígitos
    value =
      value.substring(0, 2) +
      '/' +
      value.substring(2, 4) +
      '/' +
      value.substring(4); // Adiciona barras após o segundo e quarto caractere
    e = value;
  }
  return e || '';
}

export default maskDate;
