import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import SnackBar from '../../components/SnackBar';
import { getPlacePerformanceCurrentClassroom } from '../../../service/PlacePerformance';
import ErrorAPI from '../../../core/models/ErrorAPI';
import { useNavigate, useParams } from 'react-router-dom';
import { IPlacePerformance } from '../../../core/interfaces';
import parse from 'html-react-parser';
import SideBar from '../../components/Drawer';
import { DrawerProvider } from '../../contexts/DrawerContext';
import Header from '../../components/Header';
import Fab from '@mui/material/Fab';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Box } from '@mui/material';
import style from './style.module.css';
import sebraeGif from './sebrae.gif';
const ClassroomInfo = () => {
  const navigate = useNavigate();
  const { id_place } = useParams();
  const [placePerformance, setPlacePerformance] = useState<IPlacePerformance>(
    {} as IPlacePerformance,
  );
  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');

  useEffect(() => {
    const getData = async () => {
      setOpenLoading(true);
      const response = await getPlacePerformanceCurrentClassroom(id_place!);
      setOpenLoading(false);

      if (response instanceof ErrorAPI) {
        if (response.statusCode == 401) {
          navigate('/logout');
        }

        if (response.statusCode == 500) {
          setSnackbarMessage(response.message);
          setSnackbarSeverity('warning');
          setSnackbarOpen(true);
        }
      } else {
        setPlacePerformance(response);
      }
    };

    getData();
    const interval = setInterval(getData, 60000); // 60000 ms = 1 minuto

    // Limpar o intervalo quando o componente for desmontado
    return () => clearInterval(interval);
  }, [id_place, navigate]);

  const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
  };

  return (
    <>
      <DrawerProvider>
        <SideBar>
          <Header title="Exibição das informações" />
          {placePerformance.is_empty ? (
            <div
              id="container"
              style={{
                display: 'flex',
                backgroundSize: 'cover',
                overflow: 'hidden',
                width: '63rem',
                height: '794px',
                margin: '1rem auto',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                }}
              >
                <img src={sebraeGif} alt="GIF animado" />
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div style={{ margin: '1rem' }}>
                  {placePerformance.content_editor ? (
                    <div
                      id="container"
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        position: 'relative',
                        alignItems: 'flex-start',
                        backgroundImage: `url(${placePerformance.base_64_image})`,
                        backgroundSize: 'cover',
                        backgroundColor: 'black',
                        overflow: 'hidden',
                        width: '1123px',
                        height: '794px',
                        margin: '0 auto',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        {parse(placePerformance.content_editor)}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <Box className={style.speedDial}>
                <Fab
                  color="success"
                  sx={fabStyle}
                  onClick={() => {
                    navigate(
                      `/scanner/${placePerformance.classroom_id_current}`,
                      {
                        state: {
                          origin: 'classroomInfo',
                          placePerformanceID: placePerformance.uuid,
                        },
                      },
                    );
                  }}
                  variant="extended"
                >
                  <QrCodeIcon sx={{ mr: 1 }} />
                  Confirmação de presença
                </Fab>
              </Box>
            </>
          )}

          <Loading open={openLoading} />
          <SnackBar
            open={snackBarOpen}
            type={snackbarSeverity}
            message={snackBarMessage}
            onClose={() => {
              setSnackbarOpen(false);
            }}
          />
        </SideBar>
      </DrawerProvider>
    </>
  );
};

export default ClassroomInfo;
