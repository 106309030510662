import { MenuItem } from '@material-ui/core';

interface IPropsManagerMenu {
  onClickSendQRCode: () => void;
  onClickSendEmail: () => void;
  onClickSendCheckin: () => void;
}
const ManagerMenu: React.FC<IPropsManagerMenu> = ({
  onClickSendQRCode,
  onClickSendEmail,
  onClickSendCheckin,
}) => {
  return (
    <>
      <MenuItem key="sendQRCode" onClick={onClickSendQRCode}>
        Reenviar QR Code
      </MenuItem>
      <MenuItem key="sendByEmail" onClick={onClickSendEmail}>
        Reenviar por E-mail
      </MenuItem>
      <MenuItem key="checkin" onClick={onClickSendCheckin}>
        Realizar checkin
      </MenuItem>
    </>
  );
};

export default ManagerMenu;
