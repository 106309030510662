import { type_error } from '../../../core/types';

class CheckinErroDTO {
  message: string;
  model: string;
  type_error: type_error;

  constructor(message: string, model: string, type_error: type_error) {
    this.model = model;
    this.message = message;
    this.type_error = type_error;
  }
}

export default CheckinErroDTO;
