/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import {
  Tabs,
  Tab,
  Card,
  CardContent,
  Divider,
  Modal,
  Typography,
  Icon,
  CardActions,
  IconButton,
  Box,
} from '@mui/material';

import SideBar from '../../components/Drawer';
import Header from '../../components/Header';
import TabPanel from '../../components/TabPanel';
import SnackBar from '../../components/SnackBar';
import TextButton from '../../components/Button';
import TimeRangeSwitch from '../../components/TimeSwitch';

import { DrawerProvider } from '../../contexts';

import {
  getAllshifts,
  postShifts,
  updateShifts,
} from '../../../service/ShiftsService';
import ErrorAPI from '../../../core/models/ErrorAPI';
import { IShiftsOperations } from '../../../core/interfaces';

import style from './style.module.css';
import PermissionSettings from './PermissionSettings';
import Generatecertificate from './Generatecertificate';
import EditCertificate from './EditCertificate';
import ActiveUserTemp from './ActiveUserTemp';
import PlacePerformance from './PlacePerformance';

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleListItemClick = (path: string) => {
    if (location.pathname === path) {
      window.location.reload();
    } else {
      navigate(path);
    }
  };

  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setUpdate(false);
    setOpenModal(false);
    setNomeTurno('');
  };

  const handleChangeName = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setNomeTurno(event.target.value);
  };

  const [turnosDefinidos, setTurnosDefinidos] = useState<
    IShiftsOperations[] | ErrorAPI
  >([]);

  const [nomeTurno, setNomeTurno] = useState<string>('');
  const [idTurno, setIdTurno] = useState<string>('');
  const [timeRangePersonalizado, setTimeRangePersonalizado] = useState<
    [string?, string?]
  >(['00:00', '01:00']);

  const handleTimeRangePersonalizadoChange = (
    newTimeRange: [string?, string?],
  ) => {
    setTimeRangePersonalizado(newTimeRange);
  };

  const [refresh, setRefresh] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);

  const [tab, setTab] = useState<number>(0);

  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
    setBtnDisabled(true);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  const isNomeTurnoValid = () => {
    return nomeTurno.trim() !== '';
  };

  const handleButtonPersonalizadoSalvar = async () => {
    if (!isNomeTurnoValid()) {
      setSnackbarOpen(true);
      setSnackbarSeverity('warning');
      setSnackbarMessage('O campo nome é obrigatório!');
      return;
    }

    const data = {
      name: nomeTurno,
      init_period: timeRangePersonalizado[0],
      end_period: timeRangePersonalizado[1],
    };

    const shifts = await postShifts(data);

    if (shifts instanceof ErrorAPI) {
      navigate('/logout');
    } else {
      setSnackbarOpen(true);
      setSnackbarSeverity('success');
      setSnackbarMessage('Horarios Definidos');
    }
    handleCloseModal();
    setRefresh(!refresh);
  };

  const handleEdit = async () => {
    if (!isNomeTurnoValid()) {
      setSnackbarOpen(true);
      setSnackbarSeverity('warning');
      setSnackbarMessage('O campo nome é obrigatório!');
      return;
    }

    const data = {
      uuid: idTurno,
      shift_name: nomeTurno,
      init_period: timeRangePersonalizado[0],
      end_period: timeRangePersonalizado[1],
    };

    const shifts = await updateShifts(data);

    if (shifts instanceof ErrorAPI) {
      navigate('/logout');
    } else {
      setSnackbarOpen(true);
      setSnackbarSeverity('success');
      setSnackbarMessage('Horario atualizado!');
    }
    setRefresh(!refresh);
    handleListItemClick('/settings');
    handleCloseModal();
  };

  useEffect(() => {
    async function searchDataShiftsAPI() {
      const shifts = await getAllshifts();

      if (shifts instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setTurnosDefinidos(shifts);
      }
    }
    searchDataShiftsAPI();
  }, [navigate, refresh]);

  return (
    <>
      <DrawerProvider>
        <SideBar>
          <Header title="Configurações" />
          <Modal open={openModal} onClose={handleCloseModal}>
            <Box className={style.styleBox}>
              <Typography className={style.shareWithTitle} variant="h6">
                {' '}
                Defina o seu horário:{' '}
              </Typography>
              <div className={style.container}>
                <Card className={style.card}>
                  <TextField
                    style={{ margin: '1rem' }}
                    id="name"
                    name="name"
                    label="Nome"
                    value={nomeTurno}
                    onChange={handleChangeName}
                    multiline
                    maxRows={4}
                    variant="standard"
                  />
                  <Divider />
                  <TimeRangeSwitch
                    onTimeRangeChange={handleTimeRangePersonalizadoChange}
                    defaultValues={
                      update ? timeRangePersonalizado : ['00:00', '01:00']
                    }
                  />
                </Card>
              </div>
              <TextButton
                className={style.buttonAtualizar}
                label={update ? 'Editar' : 'Salvar'}
                onClick={() => {
                  update ? handleEdit() : handleButtonPersonalizadoSalvar();
                }}
                variant="contained"
              />
            </Box>
          </Modal>

          <Box sx={{ width: '100%' }}>
            <Tabs
              allowScrollButtonsMobile
              scrollButtons
              variant="scrollable"
              value={tab}
              onChange={handleChange}
            >
              <Tab label="Permissões de usuario" />
              <Tab label="Configuração de Horario" />
              <Tab label="Gerar certificado" />
              <Tab label="Editar certificado" />
              <Tab label="Provisionamento de usuário temporário" />
              <Tab label="Local" />
            </Tabs>
            <TabPanel value={tab} index={0}>
              <div>
                <PermissionSettings />
              </div>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <div className={style.addButton}>
                <TextButton
                  isIcon={true}
                  className={style.button}
                  onClick={handleModal}
                  variant="contained"
                />
              </div>
              <div className={style.container}>
                {Array.isArray(turnosDefinidos) ? (
                  turnosDefinidos.map((value, index) => (
                    <Card key={index} className={style.card}>
                      <CardContent>
                        <div className={style.flexContainer}>
                          <p>{value.name}</p>
                          <CardActions
                            disableSpacing
                            className={style.cardAction}
                          >
                            <IconButton
                              style={{ marginLeft: '0px' }}
                              onClick={() => {
                                setUpdate(true);
                                setIdTurno(value.id);
                                setNomeTurno(value.name);
                                setTimeRangePersonalizado([
                                  value.init_period,
                                  value.end_period,
                                ]);
                                handleModal();
                              }}
                              aria-label="Editar"
                            >
                              <Icon>edit</Icon>
                            </IconButton>
                          </CardActions>
                        </div>
                        <Divider />
                        <TimeRangeSwitch
                          onTimeRangeChange={handleTimeRangePersonalizadoChange}
                          defaultValues={[value.init_period, value.end_period]}
                          isDisabled={true}
                        />
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Card className={style.card}>
                    <CardContent>
                      <p>Não há turnos adicionados!</p>
                    </CardContent>
                  </Card>
                )}
              </div>
            </TabPanel>
            <TabPanel
              value={tab}
              index={2}
              style={{
                height: '100vh',
              }}
            >
              <Generatecertificate />
            </TabPanel>
            <TabPanel
              value={tab}
              index={3}
              style={{
                height: '100vh',
              }}
            >
              <EditCertificate />
            </TabPanel>
            <TabPanel
              value={tab}
              index={4}
              style={{
                height: '100vh',
              }}
            >
              <ActiveUserTemp />
            </TabPanel>
            <TabPanel
              value={tab}
              index={5}
              style={{
                height: '100vh',
              }}
            >
              <PlacePerformance />
            </TabPanel>
          </Box>
        </SideBar>
        <SnackBar
          open={snackBarOpen}
          type={snackbarSeverity}
          message={snackBarMessage}
          onClose={handleCloseSnackBar}
        />
      </DrawerProvider>
    </>
  );
};

export default Settings;
