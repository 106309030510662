import { AppBar, Button, IconButton, Toolbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import FullScreenDialog from '../../../../components/FullScreenDialog';
import { useState } from 'react';
import { createUser } from '../../../../../service/AuthService';
import { useNavigate } from 'react-router-dom';
import ErrorAPI from '../../../../../core/models/ErrorAPI';
import TextButton from '../../../../components/Button';
import SnackBar from '../../../../components/SnackBar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IFormCreateUser } from '../../../../../core/interfaces';
import FormCreateUser from '../../../../components/FormCreateUser';

interface IPropsCreateUser {
  openDialogCreatUser: boolean;
  setOpenDialogCreate: React.Dispatch<React.SetStateAction<boolean>>;
  hasCreateAssistant?: (send: boolean) => void;
  assistant: boolean;
}
const CreateUser: React.FC<IPropsCreateUser> = ({
  openDialogCreatUser,
  setOpenDialogCreate,
  hasCreateAssistant,
  assistant,
}) => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');

  const handleCloseDialog = () => {
    setOpenDialogCreate(false); // Fecha o diálogo ao clicar no ícone de fechamento
  };

  const handleSubmit = async (user_obj: IFormCreateUser) => {
    const data = {
      firstName: user_obj.firstName,
      lastName: user_obj.lastName,
      cpf: user_obj.cpf,
      email: user_obj.email,
      username: user_obj.username,
      password: user_obj.password,
      assistant: assistant,
    };

    const response = await createUser(data);
    if (response instanceof ErrorAPI) {
      if (response.statusCode == 401) {
        navigate('/logout');
      }
      if (response.statusCode == 409) {
        setSnackbarMessage(response.message);
        setSnackbarSeverity('warning');
        setSnackbarOpen(true);
      }

      if (response.statusCode == 503) {
        setSnackbarMessage(response.message);
        setSnackbarSeverity('warning');
        setSnackbarOpen(true);
      }
    } else {
      if (assistant) {
        hasCreateAssistant?.(true);
      }
      setOpenDialog(!openDialog);
    }
  };

  const handleDialog = () => {
    handleCloseDialog();
    setOpenDialog(!openDialog);
  };

  return (
    <>
      <FullScreenDialog onClose={handleCloseDialog} open={openDialogCreatUser}>
        <Dialog
          open={openDialog}
          onClose={handleDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Criação de usuário:  É necessário fazer login'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Após a criação do usuário, será necessário fazer o login para que
              ele apareça na lista de usuários.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDialog()} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <AppBar position="static" style={{ background: '#1976d2' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseIcon style={{ color: 'white' }} />
            </IconButton>
            <TextButton
              style={{ color: 'white' }}
              label={'Adicionar'}
              type="submit"
              onClick={undefined}
            />
          </Toolbar>
        </AppBar>
        <FormCreateUser onChange={handleSubmit} />
        <SnackBar
          open={snackBarOpen}
          type={snackbarSeverity}
          message={snackBarMessage}
          onClose={() => {
            setSnackbarOpen(false);
          }}
        />
      </FullScreenDialog>
    </>
  );
};

export default CreateUser;
