import { Card, CardContent, Divider } from '@material-ui/core';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  CardActions,
  Icon,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import { IStudent } from '../../../core/interfaces';
import {
  sendCertificate,
  studentUpdate,
} from '../../../service/StudentService';
import style from './style.module.css';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import FullScreenDialog from '../FullScreenDialog';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import TextButton from '../Button';
import ErrorAPI from '../../../core/models/ErrorAPI';
import SnackBar from '../SnackBar';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PositionedMenuOptionStudent from '../PositionedMenuOptionStudent';
import LinearProgressWithLabel from '../LinearProgressWithLabel';

interface IBasicCardStudent {
  student: IStudent;
  uuidClassroom: string;
  classroomFinished: boolean;
  checkin_required: number;
  classroomRequireCertificate: boolean;
  getValuesLoading?: (value: boolean) => void;
  getValuesSnack?: (value: boolean, message: string, severity: string) => void;
}
const BasicCardStudent: React.FunctionComponent<IBasicCardStudent> = ({
  student,
  uuidClassroom,
  classroomFinished,
  classroomRequireCertificate,
  checkin_required,
  getValuesLoading,
  getValuesSnack,
}) => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [name, setName] = useState<string>(student.name);
  const [whatsapp, setWhatsapp] = useState<string>(
    student.whatsapp ? student.whatsapp : '',
  );
  const [email, setEmail] = useState<string>(
    student.email ? student.email : '',
  );

  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');

  const handleFormatCPF = (value: string) => {
    value = value.replace(/\D/g, ''); // Remove tudo que não é dígito
    value = value.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
    value = value.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o sexto e o sétimo dígitos
    value = value.replace(/(\d{3})(\d{1,2})/, '$1-$2'); // Coloca um hífen entre o nono e o décimo dígitos
    value = value.substr(0, 14); // Limita a 14 caracteres (CPF com máscara tem 14 caracteres)
    return value;
  };

  const handleFormatCNPJ = (value: string) => {
    value = value.replace(/\D/g, ''); // Remove caracteres não numéricos
    value = value.replace(/^(\d{2})(\d)/, '$1.$2'); // Formata XX.
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); // Formata XX.XXX.
    value = value.replace(/\.(\d{3})(\d)/, '.$1/$2'); // Formata XX.XXX/XX
    value = value.replace(/(\d{4})(\d)/, '$1-$2'); // Formata XX.XXX/XX-XX
    return value;
  };

  const handleChangeName = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setName(event.target.value);
  };

  const handleChangeWhatsapp = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setWhatsapp(event.target.value);
  };

  const handleChangeEmail = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setEmail(event.target.value);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleSendPDF = async () => {
    await sendCertificate(student.uuid, uuidClassroom);
  };

  const handleCloseDialog = () => {
    setName(student.name);
    setWhatsapp(student.whatsapp ? student.whatsapp : '');
    setEmail(student.email ? student.email : '');
    setOpenDialog(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);

    const name = formData.get('name')?.toString();
    const whatsapp = formData.get('whatsapp')?.toString();
    const email = formData.get('email')?.toString();

    const data = {
      name: name,
      whatsapp: whatsapp,
      email: email,
    };

    const statusCode = await studentUpdate(student.uuid, data);
    if (statusCode instanceof ErrorAPI) {
      navigate('/logout');
    } else {
      handleOpenSnackBar();
      handleCloseDialog();
      navigate(0);
    }
  };

  const handleOpenSnackBar = () => {
    setSnackbarSeverity('success');
    setSnackbarMessage('Os dados foram atualizados com sucesso.');
    setSnackbarOpen(true);
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  const handleGetValuesLoading = (value: boolean) => {
    getValuesLoading?.(value);
  };

  const handleGetValuesSnack = (
    value: boolean,
    message: string,
    severity: string,
  ) => {
    getValuesSnack?.(value, message, severity);
  };

  return (
    <Grid className={style.styleGrid}>
      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={handleCloseSnackBar}
      />
      <FullScreenDialog open={openDialog} onClose={handleCloseDialog}>
        <FormControl component="form" onSubmit={handleSubmit}>
          <AppBar position="static" style={{ background: '#1976d2' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseDialog}
                aria-label="close"
              >
                <CloseIcon style={{ color: 'white' }} />
              </IconButton>
              <Typography
                style={{ color: 'white' }}
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
                component="div"
              >
                Editando Cliente
              </Typography>
              <TextButton
                style={{ color: 'white' }}
                label={'Atualizar'}
                type="submit"
                onClick={undefined}
              />
            </Toolbar>
          </AppBar>

          <TextField
            style={{
              marginLeft: '1rem',
              marginRight: '1rem',
              marginTop: '1rem',
            }}
            id="cpf"
            name="cpf"
            label="CPF"
            value={handleFormatCPF(student.CPF)}
            InputProps={{
              disableUnderline: true,
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <AssignmentIndIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
          <TextField
            style={{
              marginLeft: '1rem',
              marginRight: '1rem',
              marginTop: '1rem',
            }}
            id="name"
            name="name"
            label="Nome"
            value={name}
            onChange={handleChangeName}
            multiline
            maxRows={4}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
          <TextField
            style={{
              marginLeft: '1rem',
              marginRight: '1rem',
              marginTop: '1rem',
            }}
            id="email"
            name="email"
            label="E-mail"
            onChange={handleChangeEmail}
            value={email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
          <TextField
            style={{
              marginLeft: '1rem',
              marginRight: '1rem',
              marginTop: '1rem',
            }}
            id="cnpj"
            name="cnpj"
            label="CNPJ"
            value={student.CNPJ ? handleFormatCNPJ(student.CNPJ) : ''}
            multiline
            maxRows={4}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
          <TextField
            style={{
              marginLeft: '1rem',
              marginRight: '1rem',
              marginTop: '1rem',
            }}
            id="empresa"
            label="Empresa"
            name="empresa"
            value={student.company_name}
            multiline
            maxRows={4}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <BusinessIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
          <TextField
            style={{
              marginLeft: '1rem',
              marginRight: '1rem',
              marginTop: '1rem',
            }}
            id="whatsapp"
            label="Whatsapp"
            name="whatsapp"
            value={whatsapp}
            onChange={handleChangeWhatsapp}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </FormControl>
      </FullScreenDialog>
      <Card className={style.card}>
        <CardContent className={style.cardContent}>
          <LinearProgressWithLabel
            color={student.color_percentage}
            style={{ margin: '1rem' }}
            value={student.percentage ? student.percentage : 0}
          />
          <div style={{ float: 'right', zIndex: '1', position: 'relative' }}>
            <PositionedMenuOptionStudent
              uuidClass={uuidClassroom}
              studentNumber={student.whatsapp}
              uuidStudent={student.uuid}
              getValuesLoading={handleGetValuesLoading}
              getValuesSnack={handleGetValuesSnack}
            />
          </div>
          <List dense={true} className={style.styleList}>
            <ListItem disablePadding>
              <ListItemText
                primary="CPF"
                secondary={handleFormatCPF(student.CPF)}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Nome"
                secondary={
                  student.name.slice(0, 30) +
                  (student.name.length > 30 ? '...' : '')
                }
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Email"
                secondary={student.email ? student.email : 'Sem informação'}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="CNPJ"
                secondary={
                  student.CNPJ
                    ? handleFormatCNPJ(student.CNPJ)
                    : 'Sem informação'
                }
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Empresa"
                secondary={
                  student.company_name
                    ? student.company_name.slice(0, 30) +
                      (student.company_name.length > 30 ? '...' : '')
                    : 'Sem informação'
                }
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary="Whatsapp"
                secondary={
                  student.whatsapp ? student.whatsapp : 'Sem informação'
                }
              />
            </ListItem>

            {student.checkin !== 0 && (
              <ListItem disablePadding>
                <ListItemText
                  primary={`Check-in`}
                  secondary={`${student.checkin_realized}/${student.checkin}`}
                />
              </ListItem>
            )}
          </List>

          <div className={style.actions}>
            {(student.checkin_realized ?? 0) > checkin_required &&
              classroomFinished && (
                <>
                  {classroomRequireCertificate === true && (
                    <>
                      <Divider />
                      <CardActions disableSpacing>
                        <IconButton
                          onClick={handleSendPDF}
                          aria-label="Enviar certificado"
                        >
                          <PictureAsPdfIcon /> <span>Enviar certificado</span>
                        </IconButton>
                      </CardActions>
                    </>
                  )}
                </>
              )}
            <CardActions disableSpacing>
              <IconButton
                onClick={handleOpenDialog}
                aria-label="Editar usuario"
              >
                <Icon>edit</Icon> <span>Editar</span>
              </IconButton>
            </CardActions>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default BasicCardStudent;
