import CardsClassroom from '../../components/CardsClassroom';

import SnackBar from '../../components/SnackBar';
import { useEffect, useState } from 'react';
import { IClassroom } from '../../../core/interfaces';
import { useNavigate } from 'react-router-dom';
import { searchClassrooms } from '../../../service/ClassroomService';
import ErrorAPI from '../../../core/models/ErrorAPI';
import { TFilterClassroom } from '../../../core/types';
import Loading from '../../components/Loading';
import FilterClassroom from '../../components/FilterClassroom';
import style from './style.module.css';
import Select, { SingleValue } from 'react-select';
import { FormGroup, FormLabel } from '@mui/material';

const ListClassroom: React.FunctionComponent = () => {
  const [classroom, setClassroom] = useState<Array<IClassroom>>(
    [] as Array<IClassroom>,
  );
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const [openLoading, setOpenLoading] = useState<boolean>(true);
  const [typeFilter, setTypeFilter] = useState<TFilterClassroom>('status');

  const navigate = useNavigate();
  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  const handleChangeFilter = (
    newValue: SingleValue<{ value: string; label: string }>,
  ) => {
    const typeFilter: TFilterClassroom = newValue?.value
      ? (newValue.value as TFilterClassroom)
      : 'status';

    setTypeFilter(typeFilter);
  };

  const options = [
    { value: 'code', label: 'Por Código' },
    { value: 'status', label: 'Por status' },
    { value: 'date', label: 'Por periodo' },
  ];

  const handleOpenSnackBar = () => {
    setSnackbarMessage('Não encontramos nenhuma turma.');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
  };

  useEffect(() => {
    async function serchDataAPI() {
      const params = handleParams('validation_not_started', 'status');
      setOpenLoading(true);
      const classroom = await searchClassrooms(params);
      setOpenLoading(false);

      if (classroom instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        if (classroom.length === 0) {
          handleOpenSnackBar();
        }
        setClassroom(classroom);
      }
    }

    serchDataAPI();
  }, [navigate]);

  const handleParams = (value: string, typeParams?: TFilterClassroom) => {
    const params = new URLSearchParams();
    if (typeParams === 'status') {
      params.append(value, '1');
    }

    if (typeParams === 'code') {
      params.append('code_classroom', value);
    }

    if (typeParams === 'date') {
      const dates = value.split(/\s*,\s*/);
      params.append('start_date', dates[0]);
      params.append('end_date', dates[1]);
    }
    return params;
  };
  const handleInput = async (value: string, typeFilter: TFilterClassroom) => {
    const params = handleParams(value, typeFilter);
    setOpenLoading(true);

    const classroom = await searchClassrooms(params);
    setOpenLoading(false);
    if (classroom instanceof ErrorAPI) {
      navigate('/logout');
    } else {
      if (classroom.length === 0) {
        handleOpenSnackBar();
      }
      setClassroom(classroom);
    }
  };

  return (
    <>
      <div className={style.container_filter}>
        <FormGroup>
          <FormLabel component="legend">Selecione o tipo de filtro</FormLabel>
          <Select
            options={options}
            className={style.select}
            isSearchable={false}
            onChange={handleChangeFilter}
            defaultValue={{
              label: 'Status',
              value: 'status',
            }}
          />
        </FormGroup>
        <FilterClassroom
          typeFilter={typeFilter}
          getValueWithType={handleInput}
        />
      </div>

      <Loading open={openLoading} />
      <CardsClassroom classroom={classroom} />
      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={handleCloseSnackBar}
      />
    </>
  );
};

export default ListClassroom;
