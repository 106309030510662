import { TextField } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';

type OnChangeCallback = (values: number | string) => void;

interface IPropsRangeNumber {
  amountCheckin?: number | string;
}
const RangerNumber: React.FC<
  IPropsRangeNumber & { onChange: OnChangeCallback }
> = ({ onChange, amountCheckin = 1 }) => {
  const [hintActiveValue, setHintActiveValue] = useState<boolean>(false);
  const [value, setValue] = useState<number | string>(amountCheckin);

  const handleValidation = (value: number) => {
    setHintActiveValue(false);
    if (isNaN(value)) {
      setValue('');
      setHintActiveValue(true);
      return false;
    }

    if (value <= 0) {
      setValue(1);
      return false;
    }

    return true;
  };

  const handlerValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: number = parseInt(event.target.value);
    const isValid = handleValidation(value);
    if (isValid) {
      setValue(value);
    }
  };

  const handleChange = useCallback(() => {
    onChange(value);
  }, [value]);
  useEffect(() => {
    handleChange();
  }, [handleChange, value]);
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'flex-end',
        }}
      >
        <TextField
          value={value}
          error={hintActiveValue}
          type="number"
          size="small"
          onChange={handlerValue}
        />
      </div>
    </>
  );
};

export default RangerNumber;
