import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import oidcConfig from './infra/api/authkeycloak';
import { AuthProvider } from 'react-oidc-context';
import PublicRoutes from './PublicRoutes';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const regexPtBr = /\/certificado/;
const regexIng = /\/certificate/;
const renderProvider = (
  <>
    {regexPtBr.test(window.location.pathname) ||
    regexIng.test(window.location.pathname) ? (
      <BrowserRouter>
        <PublicRoutes />
      </BrowserRouter>
    ) : (
      <AuthProvider {...oidcConfig}>
        <DndProvider backend={HTML5Backend}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </DndProvider>
      </AuthProvider>
    )}
  </>
);
root.render(renderProvider);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
