import React from 'react';
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  Column,
  TableInstance,
  UseSortByColumnProps,
  UseFiltersInstanceProps,
  UsePaginationInstanceProps,
  TableState,
} from 'react-table';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TextField,
  Button,
  Paper,
  InputAdornment,
  TablePagination,
} from '@mui/material';
import styles from './style.module.css';
import { exportToExcel } from '../../../core/utils/exportToExcel';
import { IStudent } from '../../../core/interfaces';
import SearchIcon from '@mui/icons-material/Search';
import GetAppIcon from '@mui/icons-material/GetApp';

type AttendanceTableProps = {
  data: IStudent[];
  className: string;
};

interface ExtendedTableState<D extends object> extends TableState<D> {
  pageIndex: number;
  pageSize: number;
}

interface ExtendedTableInstance<D extends object>
  extends TableInstance<D>,
    UseFiltersInstanceProps<D>,
    UsePaginationInstanceProps<D> {
  state: ExtendedTableState<D>;
}

const AttendanceTable: React.FC<AttendanceTableProps> = ({
  className,
  data,
}) => {
  const columns: Column<IStudent>[] = React.useMemo(
    () => [
      {
        Header: 'CPF',
        accessor: 'CPF',
      },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'CNPJ',
        accessor: 'CNPJ',
      },
      {
        Header: 'Empresa',
        accessor: 'company_name',
      },
      {
        Header: 'Whatsapp',
        accessor: 'whatsapp',
      },
      {
        Header: 'Check-in',
        accessor: 'checkin',
      },
    ],
    [],
  );

  const tableInstance = useTable<IStudent>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
      } as ExtendedTableState<IStudent>,
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setFilter,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance as ExtendedTableInstance<IStudent>;

  const handleChangePage = (event: unknown, newPage: number) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPageSize(Number(event.target.value));
  };

  return (
    <Paper elevation={0} className={styles.tableContainer}>
      <div className={styles.tableHeader}>
        <div className={styles.filterContainer}>
          <TextField
            className={styles.filterInput}
            label="Filtro CPF"
            variant="outlined"
            size="small"
            onChange={(e) => setFilter('CPF', e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className={styles.filterInput}
            label="Filtro Nome"
            variant="outlined"
            size="small"
            onChange={(e) => setFilter('name', e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className={styles.filterInput}
            label="Filtro Whatsapp"
            variant="outlined"
            size="small"
            onChange={(e) => setFilter('whatsapp', e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      <div className={styles.exportButton}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<GetAppIcon />}
          onClick={() => exportToExcel(className, data)}
        >
          Exportar para planilha
        </Button>
      </div>

      <div className={styles.tableWrapper}>
        <Table {...getTableProps()} className={styles.table}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const sortByProps = (
                    column as unknown as UseSortByColumnProps<IStudent>
                  ).getSortByToggleProps();
                  const isSorted = (
                    column as unknown as UseSortByColumnProps<IStudent>
                  ).isSorted;
                  const isSortedDesc = (
                    column as unknown as UseSortByColumnProps<IStudent>
                  ).isSortedDesc;

                  return (
                    <TableCell {...column.getHeaderProps(sortByProps)}>
                      {column.render('Header')}
                      <TableSortLabel
                        active={isSorted}
                        direction={isSortedDesc ? 'desc' : 'asc'}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className={styles.paginationContainer}>
        <TablePagination
          component="div"
          count={data.length}
          rowsPerPage={pageSize}
          page={pageIndex}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página:"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count}`
          }
          SelectProps={{
            native: true,
          }}
        />
      </div>
    </Paper>
  );
};

export default AttendanceTable;
