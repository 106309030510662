import React, { useState } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Container,
  Box,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';

import {
  ICheckinCertificate,
  IShift,
  ISupplier,
} from '../../../core/interfaces';
import { DrawerProvider } from '../../contexts';
import SideBar from '../../components/Drawer';
import Header from '../../components/Header';
import ErrorAPI from '../../../core/models/ErrorAPI';
import SnackBar from '../../components/SnackBar';
import { classroomUpdateIntegraTemp } from '../../../service/ClassroomService';

interface FormData {
  nome: string;
  sebrae_origin: string;
  unit_origin: string;
  place_performance: string;
  start_date_event: string;
  end_date_event: string;
  telefone: string;
  requireCertificate: boolean;
  checkin_number_date: IShift[];
  checkin_certificate: ICheckinCertificate[];
  certificate_uuid: string;
  searchResults: ISupplier[];
  selectedSuppliers: ISupplier[];
  belongsToABigEvent: boolean;
  main_event: object | null;
}

const getStepLabels = (requireCertificate: boolean) => [
  'Passo 1',
  'Passo 2',
  'Passo 3',
  ...(requireCertificate ? ['Passo 4'] : []),
  'Passo 5',
];

const getStepContent = (step: number, requireCertificate: boolean) => {
  if (requireCertificate) {
    switch (step) {
      case 0:
        return <StepOne />;
      case 1:
        return <StepTwo />;
      case 2:
        return <StepThree />;
      case 3:
        return <StepFour />;
      case 4:
        return <StepFive />;
      default:
        return 'Passo desconhecido';
    }
  } else {
    switch (step) {
      case 0:
        return <StepOne />;
      case 1:
        return <StepTwo />;
      case 2:
        return <StepThree />;
      case 3:
        return <StepFive />;
      default:
        return 'Passo desconhecido';
    }
  }
};

const StepNavigator: React.FC<{ activeStep: number; steps: string[] }> = ({
  activeStep,
  steps,
}) => (
  <Stepper activeStep={activeStep} sx={{ my: 2 }}>
    {steps.map((label) => (
      <Step key={label}>
        <StepLabel>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>
);

const FormActions: React.FC<{
  activeStep: number;
  handleBack: () => void;
  steps: string[];
}> = ({ activeStep, handleBack, steps }) => (
  <Box display="flex" justifyContent="space-between" mt={2}>
    {activeStep !== 0 && (
      <Button variant="contained" color="secondary" onClick={handleBack}>
        Voltar
      </Button>
    )}
    <Button variant="contained" color="primary" type="submit">
      {activeStep === steps.length - 1 ? 'Enviar' : 'Próximo'}
    </Button>
  </Box>
);

const MultiStepForm: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const methods = useForm<FormData>({
    mode: 'onTouched',
    defaultValues: {
      main_event: null,
    },
  });

  const { handleSubmit, setValue, trigger, getValues, watch } = methods;
  const { id_class } = useParams<'id_class'>();
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const navigate = useNavigate();

  const requireCertificate = watch('requireCertificate', false);
  const belongsToABigEvent = watch('belongsToABigEvent', false);
  const main_event = watch('main_event', null);

  const steps = getStepLabels(requireCertificate);

  const validateStepBeforeNext = async () => {
    const valid = await trigger();
    if (!valid) return;

    if (belongsToABigEvent && !main_event) {
      setSnackbarMessage('Por favor, selecione um evento principal.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    const needsCheck: Record<number, keyof FormData> = {
      1: 'checkin_number_date',
      2: 'checkin_certificate',
      4: 'searchResults',
    };

    const stepToValidate = requireCertificate
      ? activeStep
      : activeStep < 3
        ? activeStep
        : activeStep + 1;
    const field = needsCheck[stepToValidate];

    const value = getValues(field);
    if (field && (!value || (Array.isArray(value) && value.length === 0))) {
      setValue(field, getValues(field), { shouldValidate: true });
      setSnackbarMessage(
        'Por favor, preencha um valor para continuar a construção.',
      );
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setActiveStep((prevStep) => prevStep + 1);
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (activeStep === steps.length - 1) {
      const selectedSuppliers = getValues('selectedSuppliers');
      if (!selectedSuppliers || selectedSuppliers.length === 0) return;

      const response = await classroomUpdateIntegraTemp(id_class ?? '/', data);
      if (response instanceof ErrorAPI) {
        if (response.statusCode === 401) {
          navigate('/logout');
        } else if (response.statusCode === 500) {
          setSnackbarMessage(response.message);
          setSnackbarSeverity('warning');
          setSnackbarOpen(true);
        } else if (response.statusCode === 400) {
          setSnackbarMessage(
            'Não foi possível finalizar a construção da turma. Por favor, tente novamente mais tarde.',
          );
          setSnackbarSeverity('warning');
          setSnackbarOpen(true);
        }
      } else {
        navigate('/classroom');
        // Realizar ação final do formulário
      }
    } else {
      validateStepBeforeNext();
    }
  };

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  return (
    <DrawerProvider>
      <SideBar>
        <Header title="Finalizar construção de turma" />
        <Container>
          <FormProvider {...methods}>
            <StepNavigator activeStep={activeStep} steps={steps} />
            <form onSubmit={handleSubmit(onSubmit)}>
              {getStepContent(activeStep, requireCertificate)}
              <FormActions
                activeStep={activeStep}
                handleBack={handleBack}
                steps={steps}
              />
            </form>
            <SnackBar
              open={snackBarOpen}
              type={snackbarSeverity}
              message={snackBarMessage}
              onClose={() => setSnackbarOpen(false)}
            />
          </FormProvider>
        </Container>
      </SideBar>
    </DrawerProvider>
  );
};

export default MultiStepForm;
