/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Divider,
  Icon,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  Typography,
  Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDrawerContext } from '../../contexts';
import Select from 'react-select';
// import MessageIcon from '@mui/icons-material/Message';
import style from './style.module.css';
import Cookies from 'js-cookie';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InstallBanner from '../../components/InstallBanner';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import SchemaIcon from '@mui/icons-material/Schema';
import {
  classroomSendFlowStudent,
  searchClassrooms,
} from '../../../service/ClassroomService';
import ErrorAPI from '../../../core/models/ErrorAPI';
import SnackBar from '../SnackBar';
import Loading from '../Loading';
import TextMaskCustomCPF from '../TextMaskCustomCPF';
import TextMaskCustomPhone from '../TextMaskCustomPhone';
import Autocomplete from '@mui/material/Autocomplete';
import { IClassroom } from '../../../core/interfaces';
import MonitorIcon from '@mui/icons-material/Monitor';
import ModalSelectLocation from '../ModalSelectLocation';
export interface ISideBarProps {
  children?: React.ReactNode | undefined;
}

type Serverity = 'success' | 'info' | 'warning' | 'error';
const SideBar: React.FunctionComponent<ISideBarProps> = (props) => {
  const { children } = props;
  const { isDrawerOpen, toggleDrawerOpen } = useDrawerContext();
  const [viewEvent, setViewEvent] = useState<boolean>(false);
  const [viewEventSettings, setViewEventSettings] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [viewMensagemSettings, setViewMensagemSettings] =
    useState<boolean>(false);
  const [viewPlaceInformation, setViewPlaceInformation] =
    useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [viewCalendar, setViewCalendar] = useState<boolean>(false);
  const [registerEvent, setRegisterEvent] = useState<boolean>(false);
  const permissions = Cookies.get('permissions');
  const [open, setOpen] = useState(false);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<Serverity>('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const [codeClassroom, setCodeClassroom] = useState<{
    label: string;
    value: string;
  }>({} as { label: string; value: string });
  const [classroom, setClassroom] = useState<Array<IClassroom>>([]);
  let permissionsFromCookie = JSON.parse('[]');

  if (permissions !== 'undefined' && permissions !== undefined) {
    permissionsFromCookie = JSON.parse(permissions);
  }

  const navigate = useNavigate();
  const location = useLocation();

  const handlePermission = () => {
    permissionsFromCookie.forEach((element: string) => {
      if (element === 'Cadastrar evento') {
        setRegisterEvent(true);
      } else if (element === 'Visualizar evento') {
        setViewEvent(true);
      } else if (element === 'Acessar Configurações') {
        setViewEventSettings(true);
      } else if (element === 'Configuração de mensagem') {
        setViewMensagemSettings(true);
      } else if (element === 'Visualizar Calendário') {
        setViewCalendar(true);
      } else if (element === 'Visualizar Salas') {
        setViewPlaceInformation(true);
      }
    });
  };

  useEffect(() => {
    handlePermission();
  }, [permissionsFromCookie]);

  const handleListItemClick = (path: string) => {
    if (location.pathname === path) {
      window.location.reload();
    } else {
      navigate(path);
      toggleDrawerOpen();
    }
  };

  const handleCloseButtonClick = () => {
    toggleDrawerOpen();
  };

  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };

  const styleBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
  };

  const handleOpenSnackBar = (msg: string, severity: Serverity) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(msg);
    setSnackbarOpen(true);
  };

  const handleSendFlowUser = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);

    const phone_number = formData.get('phone_number')?.toString();
    const cpf = formData.get('cpf')?.toString();
    const code_classroom = codeClassroom.value;

    if (!phone_number?.trim() || !code_classroom?.trim() || !cpf?.trim()) {
      setSnackbarMessage('Telefone ou código não preenchido');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
    }

    const data = {
      phone_number: `55${phone_number}`,
      code_classroom: code_classroom,
      cpf: cpf,
    };
    setOpenLoading(true);
    const response = await classroomSendFlowStudent(data);
    setOpenLoading(false);
    if (response instanceof ErrorAPI) {
      if (response.statusCode === 401) {
        navigate('/logout');
      }
      if (response.statusCode == 400) {
        setSnackbarMessage(response.message);
        setSnackbarSeverity('warning');
        setSnackbarOpen(true);
      }
      if (response.statusCode == 500) {
        setSnackbarMessage(response.message);
        setSnackbarSeverity('warning');
        setSnackbarOpen(true);
      }
    } else {
      handleOpenSnackBar('Fluxo enviado com sucesso.', 'success');
    }
  };
  const handleOpenModal = () => {
    handleCloseButtonClick();
    setOpen((prevent) => !prevent);
  };

  useEffect(() => {
    async function fetchData() {
      const params = new URLSearchParams();
      params.append('finished_event', 'False');
      const classroom = await searchClassrooms(params);
      if (classroom instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setClassroom(classroom);
      }
    }
    fetchData();
  }, [navigate]);

  const handleModalClose = (location: string) => {
    setModalOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
    toggleDrawerOpen();
  };

  const options = classroom.map((item) => ({
    label: item.solution_name,
    value: item.code_classroom,
  }));

  const handleChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    // Assuming setCodeClassroom is a function passed as a prop to update the selected classroom code.
    setCodeClassroom(selectedOption);
  };
  return (
    <>
      <ModalSelectLocation open={modalOpen} onClose={handleModalClose} />
      <Modal open={open} onClose={() => setOpen((prevent) => !prevent)}>
        <Box
          sx={styleBox}
          component="form"
          onSubmit={handleSendFlowUser}
          noValidate
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Envio de Inscrição via WhatsApp
          </Typography>

          <TextField
            id="cpf"
            name="cpf"
            autoFocus
            label="CPF"
            variant="outlined"
            type="text"
            margin="dense"
            fullWidth
            InputProps={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              inputComponent: TextMaskCustomCPF as any,
            }}
          />
          <TextField
            id="phone_number"
            name="phone_number"
            label="Telefone do usuário"
            variant="outlined"
            InputProps={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              inputComponent: TextMaskCustomPhone as any,
            }}
          />
          <Select
            id="code_classroom"
            options={options}
            value={selectedOption}
            onChange={handleChange}
            placeholder="Código da turma"
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: 'white',
                borderRadius: '4px',
                border: '1px solid #ced4da',
              }),
              singleValue: (provided) => ({
                ...provided,
                color: 'black',
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                color: 'black',
              }),
            }}
          />
          <Button type="submit" variant="contained">
            Enviar
          </Button>
        </Box>
      </Modal>
      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={handleCloseSnackBar}
      />
      <Loading open={openLoading} />
      <Drawer
        style={{ zIndex: '99999999' }}
        open={isDrawerOpen}
        variant={'temporary'}
        onClose={toggleDrawerOpen}
      >
        <Divider />
        <Box flex={1}>
          <List component="nav" className={style.lista}>
            <ListItemButton
              className={style.btn_lista}
              onClick={handleCloseButtonClick}
            >
              <ListItemIcon className={style.icon_lista}>
                <Icon>{'close'}</Icon>
              </ListItemIcon>
              <ListItemText
                className={style.text_lista}
                primary="Fechar menu"
                secondary="Fechar menu lateral"
              />
            </ListItemButton>

            <ListItemButton
              className={style.btn_lista}
              onClick={() => handleListItemClick('/')}
            >
              <ListItemIcon className={style.icon_lista}>
                <Icon>{'home'}</Icon>
              </ListItemIcon>
              <ListItemText
                className={style.text_lista}
                primary="Home"
                secondary="Página Inicial"
              />
            </ListItemButton>
            {viewPlaceInformation && (
              <ListItemButton
                className={style.btn_lista}
                onClick={() => openModal()}
              >
                <ListItemIcon className={style.icon_lista}>
                  <MonitorIcon />
                </ListItemIcon>
                <ListItemText
                  className={style.text_lista}
                  primary="Porta de sala"
                  secondary="Escolha a sala que deseja monitorar."
                />
              </ListItemButton>
            )}
            {registerEvent && (
              <ListItemButton
                className={style.btn_lista}
                onClick={() => handleListItemClick('/generate')}
              >
                <ListItemIcon className={style.icon_lista}>
                  <Icon>{'qr_code'}</Icon>
                </ListItemIcon>
                <ListItemText
                  className={style.text_lista}
                  primary="Gerar Evento"
                  secondary="Emitir QRcode da sala"
                />
              </ListItemButton>
            )}

            {viewEvent && (
              <ListItemButton
                className={style.btn_lista}
                onClick={() => handleListItemClick('/classroom')}
              >
                <ListItemIcon className={style.icon_lista}>
                  <Icon>{'today'}</Icon>
                </ListItemIcon>
                <ListItemText
                  className={style.text_lista}
                  primary="Meus eventos"
                  secondary="Visualizar eventos"
                />
              </ListItemButton>
            )}
            {viewCalendar && (
              <ListItemButton
                className={style.btn_lista}
                onClick={() => handleListItemClick('/classrooms/event/list')}
              >
                <ListItemIcon className={style.icon_lista}>
                  <CalendarMonthIcon />
                </ListItemIcon>
                <ListItemText
                  className={style.text_lista}
                  primary="Calendário"
                  secondary="Calendário de eventos"
                />
              </ListItemButton>
            )}
            {viewEventSettings && (
              <ListItemButton
                className={style.btn_lista}
                onClick={() => handleListItemClick('/settings')}
              >
                <ListItemIcon className={style.icon_lista}>
                  <Icon>{'settings'}</Icon>
                </ListItemIcon>
                <ListItemText
                  className={style.text_lista}
                  primary="Configuração"
                  secondary="Configuração do sistema"
                />
              </ListItemButton>
            )}
            {viewMensagemSettings && (
              <ListItemButton
                className={style.btn_lista}
                onClick={() => handleListItemClick('/edit/message')}
              >
                <ListItemIcon className={style.icon_lista}>
                  <CalendarMonthIcon />
                </ListItemIcon>
                <ListItemText
                  className={style.text_lista}
                  primary="Configuração de mensagem"
                  secondary="Editar mensagem padrões de envio"
                />
              </ListItemButton>
            )}
            <div>
              <InstallBanner isListItem={true} />
            </div>
            <ListItemButton
              className={style.btn_lista}
              onClick={handleOpenModal}
            >
              <ListItemIcon className={style.icon_lista}>
                <SchemaIcon />
              </ListItemIcon>
              <ListItemText
                className={style.text_lista}
                primary="Envio de Inscrição via WhatsApp"
                secondary="O usuário receberá o fluxo para se cadastrar na turma."
              />
            </ListItemButton>
            <ListItemButton
              className={style.btn_lista}
              onClick={() => handleListItemClick('/logout')}
            >
              <ListItemIcon className={style.icon_lista}>
                <Icon>{'logout'}</Icon>
              </ListItemIcon>
              <ListItemText
                className={style.text_lista}
                primary="Logout"
                secondary="Sair da conta"
              />
            </ListItemButton>

            <List style={{ marginTop: `auto` }}>
              <ListItem>
                <ListItemText>
                  Versão:{process.env.REACT_APP_VERSION}
                </ListItemText>
              </ListItem>
            </List>
          </List>
        </Box>
      </Drawer>
      <Box height="100vh" marginLeft={0}>
        {children}
      </Box>
    </>
  );
};

export default SideBar;
