/* eslint-disable prefer-const */
import { get, post, patch } from '../adapters/xhr';
import {
  IClassroomCertificate,
  IStudentCertificate,
} from '../../core/interfaces';
import { AxiosError } from 'axios';
import ErrorAPI from '../../core/models/ErrorAPI';
import { serialize } from 'object-to-formdata';

export const ClassroomOperationCertificateAPI = () => ({
  classSendCertificate: async (
    verseActive: boolean,
    nameFile: string,
    htmlFront: string,
    htmlBack: string,
    imageFront: File | null,
    imageBack: File | null,
    contentEditorFront: string,
    contentEditorBack: string | null,
  ) => {
    try {
      const data = {
        verse_active: verseActive,
        name_file: nameFile,
        html_front: htmlFront,
        content_editor_front: contentEditorFront,
        image_front: imageFront,
        html_back: verseActive ? htmlBack : null,
        image_back: verseActive ? imageBack : null,
        content_editor_back: verseActive ? contentEditorBack : null,
      };

      const formData = serialize(data, {
        noFilesWithArrayNotation: true,
      });
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/classroom/get_create/certificate`,
        formData,
        undefined,
        undefined,
        'multipart/form-data',
      );
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return 500;
    }
  },
  getCertificate: async () => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await get(
        `${BaseURL}/api/v1/classroom/get_create/certificate`,
      );
      return response.data as IClassroomCertificate[];
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return [] as IClassroomCertificate[];
    }
  },
  updateCertificate: async (
    uuid: string,
    verseActive: boolean,
    nameFile: string,
    htmlFront: string,
    htmlBack: string,
    imageFront: File | null,
    imageBack: File | null,
    contentEditorFront: string,
    contentEditorBack: string | null,
  ) => {
    try {
      const data = {
        uuid: uuid,
        verse_active: verseActive,
        name_file: nameFile,
        html_front: htmlFront,
        html_back: htmlBack,
        image_front: imageFront,
        image_back: imageBack,
        content_editor_front: contentEditorFront,
        content_editor_back: contentEditorBack,
      };
      const formData = serialize(data, {
        noFilesWithArrayNotation: true,
      });
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await patch(
        `${BaseURL}/api/v1/classroom/get_create/certificate`,
        formData,
        undefined,
        'multipart/form-data',
      );
      return response.data as IClassroomCertificate;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI: ErrorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }

        return errorAPI;
      }
      return {} as IClassroomCertificate;
    }
  },
  getPreviewCertificate: async (data: object) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/classroom/get/certificate/preview`,
        data,
      );
      if (response.status !== 200) {
        return {} as IStudentCertificate;
      }
      return response.data as IStudentCertificate;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      console.error('error', error);
      return {} as IStudentCertificate;
    }
  },
});
