/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import HeaderDescription from '../../../components/HeaderDescription';
import {
  IClassroom,
  IClassroomCertificate,
  ISearchSOL,
  IStudentCertificate,
} from '../../../../core/interfaces';
import { useEffect, useRef, useState } from 'react';
import {
  classSearchSolutions,
  getCertificate,
  getPreviewCertificate,
} from '../../../../service/ClassroomService';
import ErrorAPI from '../../../../core/models/ErrorAPI';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import {
  Button,
  Divider,
  Icon,
  IconButton,
  InputAdornment,
  ListItemButton,
  ListItemIcon,
  TextField,
  Typography,
} from '@mui/material';
import Filter2Icon from '@mui/icons-material/Filter2';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import parse from 'html-react-parser';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import style from './style.module.css';
import { TSeachClassroom } from '../../../../core/types';
import Modal from '@mui/material/Modal';
import DBGrid from '../../../components/Grid';
import QrcodeExample from './qrcode_example.png';
import Loading from '../../../components/Loading';

interface IDefineCertificateProps {
  selectedCertificateCallBack: (
    certificate: IClassroomCertificate | null,
  ) => void;
  defaultMethodology: string;
  selectedCertificatePersonalize: (certificate: string) => void;
  checkedSelect: string;
  solutionSelect: (solution: ISearchSOL) => void;
  origin: TSeachClassroom;
  defaultValueSolutionSearch: string;
  classroom: IClassroom;
}
const DefineCertificate: React.FC<IDefineCertificateProps> = ({
  selectedCertificateCallBack,
  selectedCertificatePersonalize,
  defaultMethodology,
  checkedSelect,
  solutionSelect,
  origin,
  defaultValueSolutionSearch,
  classroom,
}) => {
  const navigate = useNavigate();
  const [certificatePreView, setCertificatePreView] =
    useState<IStudentCertificate | null>(null);
  const [contentFront, setContentFront] = useState<string>(defaultMethodology);
  const [certificates, setCertificates] = useState<IClassroomCertificate[]>([]);
  const [selectedCertificateObject, setSelectedCertificateObject] =
    useState<IClassroomCertificate | null>(null);
  const containerFrontRef = useRef<HTMLDivElement | null>(null);
  const [selectedCertificateUuid, setSelectedCertificateUuid] =
    useState<string>(checkedSelect);
  const [listSol, setListSol] = useState<ISearchSOL[]>([] as ISearchSOL[]);
  const handleEditorChangeFront = (newContent: string) => {
    setContentFront(newContent);
  };
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [solutionName, setSolutuionName] = useState<string>(
    defaultValueSolutionSearch,
  );
  const [openLoading, setOpenLoading] = useState<boolean>(false);
  const [openModalPrevia, setOpenModalPrevia] = useState(false);
  const handleOpenModalPrevia = async () => {
    if (classroom.students) {
      let divString = '';
      if (containerFrontRef.current) {
        divString = containerFrontRef.current.outerHTML;
      }
      const object = {
        start_date: classroom.start_date_event,
        end_date: classroom.end_date_event,
        solution_name: classroom.solution_name,
        certificate_id: selectedCertificateObject?.uuid,
        certificate_content_verse: divString,
        student: classroom.students[0],
      };
      setOpenLoading(true);
      const response = await getPreviewCertificate(object);
      setOpenLoading(false);
      if (response instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setOpenModalPrevia(true);
        setCertificatePreView(response);
      }
    }
  };

  const handleCloseModalPrevia = () => setOpenModalPrevia(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  function handleCertificateSelect(certificate: IClassroomCertificate) {
    const newSelectedCertificateUuid =
      selectedCertificateUuid === certificate.uuid ? '' : certificate.uuid;
    setSelectedCertificateUuid(newSelectedCertificateUuid);

    // Chame a função de callback apenas se o checkbox estiver marcado
    if (newSelectedCertificateUuid) {
      selectedCertificateCallBack(certificate);
      setSelectedCertificateObject(certificate);
    } else {
      selectedCertificateCallBack(null);
      setSelectedCertificateObject(null);
    }
  }

  function isCertificateUuidSelected(uuid: string): boolean {
    return selectedCertificateUuid === uuid;
  }

  useEffect(() => {
    async function getData() {
      setOpenLoading(true);
      const data = await getCertificate();
      setOpenLoading(false);
      if (data instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setCertificates(data);
        const obj = data.find((obj) => obj.uuid === checkedSelect);
        if (obj) {
          setSelectedCertificateObject(obj);
        }
      }
    }
    getData();
  }, []);

  const handleSubmit = async (event: {
    preventDefault: () => void;
    currentTarget: HTMLFormElement | undefined;
  }) => {
    event.preventDefault();
    const params = new URLSearchParams();
    const data = new FormData(event.currentTarget);
    const keyword = data.get('keyword')?.toString();
    if (keyword) {
      params.append('keyword', keyword);
      const response = await classSearchSolutions(params);
      if (response instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setListSol(response);
        handleOpenModal();
      }
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'Nome', width: 1000 },
  ];

  const getValueSol = (value: Array<object>) => {
    if (value.length > 0) {
      const solution = value[0] as ISearchSOL;
      solutionSelect(solution);
      setSolutuionName(solution.name);
      handleCloseModal();
    }
  };

  const styleModal = {
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Sombra do modal
    display: 'flex',
    position: 'fixed', // Define a posição como fixa para centralizar na tela
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto', // Margem automática para centralizar o modal
    alignItems: 'center',
    justifyContent: 'center',
    height: '90%', // Define a altura como 80% da viewport height
    width: '90%', // Define a largura como 80% da viewport width
  };
  function renderRow(props: ListChildComponentProps) {
    const { index, style } = props;
    const certificate = certificates[index];
    const labelId = `checkbox-list-label-${index}`;
    return (
      <ListItem
        secondaryAction={certificate.verse_active ? <Filter2Icon /> : null}
        key={index}
        style={style}
        disableGutters
      >
        <ListItemButton
          role={undefined}
          onClick={() => handleCertificateSelect(certificate)}
          dense
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={isCertificateUuidSelected(certificate.uuid)}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <ListItemText id={labelId} primary={certificate.certificate_name} />
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <>
      <HeaderDescription
        title="Defina o certificado desse curso."
        subtitle="Por favor, selecione um certificado para dar continuidade à criação da aula. Se a base de origem for o SAS, é necessário encontrar uma solução adequada"
      />
      {origin === 'INTEGRA' && (
        <Box
          className={style.container_input}
          component="form"
          onSubmit={handleSubmit}
          noValidate
        >
          <TextField
            id="keyword"
            name="keyword"
            autoFocus
            className={style.input}
            label="Pesquise a solução"
            variant="outlined"
            type="text"
            margin="dense"
            value={solutionName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSolutuionName(event.target.value);
            }}
            defaultValue={solutionName}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <Icon>{'search'}</Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '1rem',
        }}
      >
        {selectedCertificateObject && (
          <Button variant="contained" onClick={handleOpenModalPrevia}>
            Prévia do certificado
          </Button>
        )}
      </div>
      <Box>
        <FixedSizeList
          height={200}
          width={'100%'}
          itemSize={40}
          itemCount={certificates.length}
          overscanCount={5}
        >
          {renderRow}
        </FixedSizeList>
      </Box>
      <Modal
        open={openModalPrevia}
        onClose={handleCloseModalPrevia}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          {certificatePreView && (
            <object
              data={certificatePreView.pdf_base64}
              type="application/pdf"
              width="100%"
              height="100%"
            >
              Seu navegador não suporta a visualização de PDFs. Você pode{' '}
              <a href={certificatePreView.pdf_base64} download>
                baixar o arquivo
              </a>{' '}
              para visualizá-lo.
            </object>
          )}
        </Box>
      </Modal>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={styleModal}>
          <DBGrid
            columns={columns}
            rows={listSol}
            loading={false}
            checkboxSelection={false}
            selectedValue={getValueSol} //esse é para o callback
            defaultValue={[]}
            columnVisibilityModel={{ uuid: false }}
            className={style.gridTable}
          />
        </Box>
      </Modal>
      {selectedCertificateObject ? (
        <div>
          {selectedCertificateObject.verse_active && (
            <>
              <Typography variant="h6" gutterBottom>
                Configure o verso do seu certificado
              </Typography>
              <Divider />
              <CKEditor
                editor={Editor}
                data={defaultMethodology}
                onReady={(editor) => {
                  const data = editor.getData();
                  handleEditorChangeFront(data);
                  if (containerFrontRef.current) {
                    const divString = containerFrontRef.current.outerHTML;
                    selectedCertificatePersonalize(divString);
                  }
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleEditorChangeFront(data);
                  if (containerFrontRef.current) {
                    const divString = containerFrontRef.current.outerHTML;
                    selectedCertificatePersonalize(divString);
                  }
                }}
              />
              <div
                id="container"
                ref={containerFrontRef}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  position: 'relative',
                  alignItems: 'flex-start',
                  backgroundImage: `url(${selectedCertificateObject.path_file_back})`,
                  backgroundSize: 'cover',
                  backgroundColor: 'black',
                  overflow: 'hidden',
                  width: '1123px',
                  height: '794px',
                  margin: '0 auto',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    margin: '2rem',
                    width: '150px',
                    height: '150px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url(${QrcodeExample})`,
                    backgroundColor: 'red',
                  }}
                >
                  <img
                    id="qrcode"
                    src={QrcodeExample}
                    alt="Girl in a jacket"
                    width="150"
                    height="150"
                  />
                </div>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  {parse(contentFront)}
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div></div>
      )}
      <Loading open={openLoading} />
    </>
  );
};

export default DefineCertificate;
