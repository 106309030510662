/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, Fragment } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ErrorAPI from '../../../core/models/ErrorAPI';
import { IStudentCertificate } from '../../../core/interfaces';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getCertificateStudent } from '../../../service/StudentService';

const Certificate: React.FC = () => {
  const [certificate, setCertificate] = useState<IStudentCertificate>(
    {} as IStudentCertificate,
  );
  const { id_certificate } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      if (id_certificate) {
        const response = await getCertificateStudent(id_certificate);
        if (response instanceof ErrorAPI) {
          navigate('/logout');
        } else {
          const pdfUrl = response.pdf_base64;
          setCertificate(response);
        }
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {certificate !== null && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <object
              data={`${certificate.pdf_base64}`}
              type="application/pdf"
              width="100%"
              height="600px"
            >
              Seu navegador não suporta a visualização de PDFs. Você pode{' '}
              <a href={`${certificate.pdf_base64}`} download>
                baixar o arquivo
              </a>{' '}
              para visualizá-lo.
            </object>
            <Typography
              style={{
                wordBreak: 'break-all',
                marginTop: '16px',
                fontSize: '16px',
              }}
            >
              O certificado acima atesta que concluiu com êxito o curso{' '}
              {certificate.solution_name}, ministrado por {certificate.supplier}{' '}
              . O certificado indica que todo o curso foi concluído, conforme
              validado pelo aluno.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" component="div" marginTop={2}>
              Destinatário do certificado:
            </Typography>
            <List>
              <ListItem disablePadding alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    alt={certificate.name_student}
                    sx={{ width: 50, height: 50 }} // ajuste o tamanho conforme necessário
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Fragment>
                      <Typography variant="h6" component="div">
                        {certificate.name_student}
                      </Typography>
                    </Fragment>
                  }
                />
              </ListItem>
              <Typography sx={{ mt: 2 }} variant="h6" component="div">
                Sobre o curso:
              </Typography>
              <ListItem disablePadding alignItems="flex-start">
                <ListItemText
                  primary="Nome do curso"
                  secondary={certificate.classname}
                />
              </ListItem>
              <ListItem disablePadding alignItems="flex-start">
                <ListItemText
                  primary="Ministrantes"
                  secondary={certificate.supplier}
                />
              </ListItem>
              <ListItem disablePadding alignItems="flex-start">
                <ListItemText
                  primary="Tipo de curso"
                  secondary={
                    certificate.is_coletive ? 'Coletivo' : 'Individual'
                  }
                />
              </ListItem>
            </List>
            <Divider />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Certificate;
