/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Card,
  CardContent,
  Divider,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
  Box,
} from '@mui/material';
import Stack from '@mui/material/Stack';

import DBGrid from '../../../components/Grid';

import { IUser } from '../../../../core/interfaces';
import ErrorAPI from '../../../../core/models/ErrorAPI';

import SnackBar from '../../../components/SnackBar';
import TextButton from '../../../components/Button';

import style from './style.module.css';
import { Permission, Roles } from '../../../../core/types';
import { getAlluser, updateUser } from '../../../../service/AuthService';
import CreateUser from './CreateUser';

const PermissionSettings = () => {
  const navigate = useNavigate();
  const [openDialogCreatUser, setOpenDialogCreatUser] =
    useState<boolean>(false);
  const [user, setUser] = useState<IUser>({} as IUser);
  const [admin, setAdmin] = useState<boolean>(false);
  const [supplier, setSupplier] = useState<boolean>(false);
  const [student, setStudent] = useState<boolean>(false);
  const [assistant, setAssistant] = useState<boolean>(false);
  const [snackBarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const [snackBarMessage, setSnackbarMessage] = useState<string>('');
  const [cadastrarEvento, setCadastrarEvento] = useState<boolean>(false);
  const [visualizarEvento, setVisualizarEvento] = useState<boolean>(false);
  const [visualizarConfiguracoes, setVisualizarConfiguracoes] =
    useState<boolean>(false);
  const [visualizarCalendario, setVisualizarCalendario] =
    useState<boolean>(false);
  const [configuracaoMensagem, setConfiguracaoMensagem] =
    useState<boolean>(false);
  const [visualizarSalas, setVisualizarSalas] = useState<boolean>(false);
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [rows, setRows] = useState<Array<IUser>>([]);
  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'username', headerName: 'Nome de usuário', width: 150 },
    { field: 'cpf', headerName: 'CPF', width: 120 },
  ];
  const handleCloseSnackBar = () => {
    setSnackbarOpen(false);
  };
  useEffect(() => {
    async function searchDataAPI() {
      const users = await getAlluser();

      if (users instanceof ErrorAPI) {
        navigate('/logout');
      } else {
        setRows(users);
      }
    }

    searchDataAPI();
  }, [navigate]);

  const handleUpdatePermission = (): Permission[] => {
    const permissions: Permission[] = [];

    if (cadastrarEvento) {
      permissions.push('Cadastrar evento');
    }

    if (visualizarEvento) {
      permissions.push('Visualizar evento');
    }
    if (visualizarConfiguracoes) {
      permissions.push('Acessar Configurações');
    }

    if (visualizarCalendario) {
      permissions.push('Visualizar Calendário');
    }

    if (configuracaoMensagem) {
      permissions.push('Configuração de mensagem');
    }

    if (visualizarSalas) {
      permissions.push('Visualizar Salas');
    }

    return permissions;
  };

  const handleUpdateRoles = () => {
    const roles: Roles[] = [];

    if (admin) {
      roles.push('MANAGER');
    }

    if (supplier) {
      roles.push('SUPPLIER');
    }

    if (student) {
      roles.push('STUDENT');
    }

    if (assistant) {
      roles.push('ASSISTANT');
    }

    return roles;
  };

  const handleButtonAtualizar = async () => {
    setSnackbarOpen(true);
    setSnackbarSeverity('success');
    setSnackbarMessage('Permissões atualizadas com sucesso');
    const permissions = handleUpdatePermission();
    const roles = handleUpdateRoles();

    const data = {
      roles: roles,
      permissions: permissions,
    };
    const newUser = await updateUser(user.id, data);

    if (newUser instanceof ErrorAPI) {
      navigate('/logout');
    } else {
      navigate(0);
    }
  };

  const handleChangeCheckedAdmin = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAdmin(event.target.checked);
  };
  const handleChangeCheckedSupplier = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSupplier(event.target.checked);
  };
  const handleChangeCheckedStudent = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setStudent(event.target.checked);
  };

  const handleChangeCheckedAssistant = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAssistant(event.target.checked);
    setAdmin(false);
    setSupplier(false);
    setStudent(false);
    setCadastrarEvento(false);
    setVisualizarEvento(false);
    setVisualizarSalas(false);
    setConfiguracaoMensagem(false);
    setVisualizarCalendario(false);
    setVisualizarConfiguracoes(false);
    setBtnDisabled(false);
  };
  const handleChangeCadastarEvento = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCadastrarEvento(event.target.checked);
  };

  const handleChangeVisualizarEvento = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setVisualizarEvento(event.target.checked);
  };

  const handleChangeVisualizarConfiguracao = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setVisualizarConfiguracoes(event.target.checked);
  };

  const handleChangeVisualizarSalas = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setVisualizarSalas(event.target.checked);
  };

  const handleChangeConfiguracaoMensagem = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfiguracaoMensagem(event.target.checked);
  };

  const handleChangeVisualizarCalendario = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setVisualizarCalendario(event.target.checked);
  };

  const handleTableValueSelect = (selectedRows: any[]) => {
    const selectedUser = selectedRows as IUser[];

    setUser(selectedUser[0]);

    const permissions: string[] = selectedUser[0].permission.reduce(
      (acc: string[], permission) => {
        return acc.concat(permission.name);
      },
      [],
    );

    setAdmin(false);
    setSupplier(false);
    setStudent(false);
    setCadastrarEvento(false);
    setVisualizarEvento(false);
    setVisualizarConfiguracoes(false);
    setVisualizarSalas(false);
    setConfiguracaoMensagem(false);
    setVisualizarCalendario(false);
    setBtnDisabled(false);
    setAssistant(false);

    if (permissions.includes('Cadastrar evento')) {
      setCadastrarEvento(true);
    }

    if (permissions.includes('Visualizar evento')) {
      setVisualizarEvento(true);
    }

    if (permissions.includes('Acessar Configurações')) {
      setVisualizarConfiguracoes(true);
    }

    if (permissions.includes('Visualizar Calendário')) {
      setVisualizarCalendario(true);
    }

    if (permissions.includes('Configuração de mensagem')) {
      setConfiguracaoMensagem(true);
    }

    if (permissions.includes('Visualizar Salas')) {
      setVisualizarSalas(true);
    }

    // eslint-disable-next-line array-callback-return
    selectedUser[0].roles.map((value) => {
      switch (value.role) {
        case 'MANAGER':
          setAdmin(true);
          break;
        case 'SUPPLIER':
          setSupplier(true);
          break;
        case 'STUDENT':
          setStudent(true);
          break;
        case 'ASSISTANT':
          setAssistant(true);
          break;
        default:
          break;
      }
    });
  };

  return (
    <div>
      <Box className={style.boxBttn}>
        <CreateUser
          setOpenDialogCreate={setOpenDialogCreatUser}
          openDialogCreatUser={openDialogCreatUser}
          assistant={false}
        />
        <TextButton
          className={style.buttonAtualizar}
          label={'Adicionar usuário'}
          onClick={() => {
            setOpenDialogCreatUser(true); // Abre o diálogo ao clicar no botão
          }}
          variant="contained"
        />
        <TextButton
          className={style.buttonAtualizar}
          label={'Atualizar'}
          onClick={handleButtonAtualizar}
          variant="contained"
          disabled={btnDisabled}
        />
      </Box>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={{ xs: 1, sm: 2 }}
        flexWrap="wrap"
        useFlexGap
      >
        <Card>
          <CardContent>
            <p>Usuario</p>
            <Divider />
            <DBGrid
              className={style.gridTable}
              columns={columns}
              rows={rows}
              loading={false}
              checkboxSelection={false}
              selectedValue={handleTableValueSelect}
              columnVisibilityModel={{ id: false }}
            />
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <p>Tipo de usuario</p>
            <Divider />
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    disabled={assistant}
                    checked={admin}
                    onChange={handleChangeCheckedAdmin}
                    inputProps={{ 'aria-label': 'controlled' }}
                    name="admin"
                  />
                }
                label="Administrador"
              />
              <FormControlLabel
                control={
                  <Switch
                    disabled={assistant}
                    checked={supplier}
                    onChange={handleChangeCheckedSupplier}
                    inputProps={{ 'aria-label': 'controlled' }}
                    name="supplier"
                  />
                }
                label="Professor"
              />
              <FormControlLabel
                control={
                  <Switch
                    disabled={assistant}
                    checked={student}
                    inputProps={{ 'aria-label': 'controlled' }}
                    onChange={handleChangeCheckedStudent}
                    name="student"
                  />
                }
                label="Aluno"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={assistant}
                    inputProps={{ 'aria-label': 'controlled' }}
                    onChange={handleChangeCheckedAssistant}
                    name="assistant"
                  />
                }
                label="Assistente"
              />
            </FormGroup>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <p>Permissões</p>
            <Divider />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={assistant}
                    checked={cadastrarEvento}
                    name="Cadastrar evento"
                    onChange={handleChangeCadastarEvento}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Cadastrar evento"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={visualizarEvento}
                    name="Visualizar evento"
                    onChange={handleChangeVisualizarEvento}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Visualizar evento"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={assistant}
                    checked={visualizarConfiguracoes}
                    name="Acessar Configurações"
                    onChange={handleChangeVisualizarConfiguracao}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Acessar Configurações"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    disabled={assistant}
                    checked={visualizarCalendario}
                    name="Visualizar Calendário"
                    onChange={handleChangeVisualizarCalendario}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Visualizar Calendário"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    disabled={assistant}
                    checked={configuracaoMensagem}
                    name="Configuração de mensagem"
                    onChange={handleChangeConfiguracaoMensagem}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Configuração de mensagem"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    disabled={assistant}
                    checked={visualizarSalas}
                    name="Visualizar Salas"
                    onChange={handleChangeVisualizarSalas}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Visualizar informação da sala"
              />
            </FormGroup>
          </CardContent>
        </Card>
      </Stack>
      <SnackBar
        open={snackBarOpen}
        type={snackbarSeverity}
        message={snackBarMessage}
        onClose={handleCloseSnackBar}
      />
    </div>
  );
};

export default PermissionSettings;
