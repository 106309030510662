/* eslint-disable prefer-const */
import { get, post, patch } from '../adapters/xhr';
import { AxiosError } from 'axios';
import ErrorAPI from '../../core/models/ErrorAPI';

export const ClassroomOperationAPI = () => ({
  classroomAddStudent: async (data: object) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/classroom/create/student/proposal`,
        data,
      );
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI: ErrorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.msg);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.msg);
            break;
          case 403:
            errorAPI = new ErrorAPI(response.status, response.data?.msg);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.msg);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }
        return errorAPI;
      }
    }
  },
  classroomInitValidate: async (
    classroom_id: string | undefined,
    init: boolean,
  ) => {
    try {
      const data = {
        validation_started: init,
      };

      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await patch(
        `${BaseURL}/api/v1/classroom/operation/init/validate/${classroom_id}`,
        data,
      );
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
    }
  },
  classroomFinalize: async (
    classroom_id: string | undefined,
    sendFiles: boolean,
  ) => {
    try {
      const data = {
        sendFiles: sendFiles,
      };

      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await patch(
        `${BaseURL}/api/v1/classroom/operation/finalize/checkin/${classroom_id}`,
        data,
      );
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
    }
  },
  classroomClosed: async (classroom_id: string | undefined) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await patch(
        `${BaseURL}/api/v1/classroom/operation/finished/checkin/${classroom_id}`,
      );
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
    }
  },
  classSendPhoneQRcode: async (classroom_id: string | undefined) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/classroom/operation/create/qrcode/phone/student/${classroom_id}`,
      );
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return 500;
    }
  },
  classSendEmailQRcode: async (classroom_id: string | undefined) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/classroom/operation/create/qrcode/email/student/${classroom_id}`,
      );
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }
      return 500;
    }
  },
  classSendMessagePhone: async (
    classroom_id: string | undefined,
    mensagem: string,
  ) => {
    const data = {
      from_send: 'checkin',
      uuid: classroom_id,
      message: mensagem,
    };
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/classroom/operation/create/menssage/phone`,
        data,
      );
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI: ErrorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }

        return errorAPI;
      }
      return 500;
    }
  },
  classSyncStudents: async (classroom_id: string | undefined) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await get(
        `${BaseURL}/api/v1/classroom/operation/refresh/student/${classroom_id}`,
      );
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI: ErrorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }

        return errorAPI;
      }
      return 500;
    }
  },
  reopenClass: async (classroom_id: string | undefined) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await patch(
        `${BaseURL}/api/v1/classroom/operation/reopen/${classroom_id}`,
      );
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI: ErrorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.error);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }

        return errorAPI;
      }
      return 500;
    }
  },
  classroomSendFlowStudent: async (data: object) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await post(
        `${BaseURL}/api/v1/classroom/operation/flow/send/student`,
        data,
      );
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI: ErrorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.msg);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.msg);
            break;
          case 403:
            errorAPI = new ErrorAPI(response.status, response.data?.msg);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.msg);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }
        return errorAPI;
      }
    }
  },
  classroomUpdateIntegraTemp: async (
    classroom_id: string | undefined,
    data: object,
  ) => {
    try {
      const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
      let response = await patch(
        `${BaseURL}/api/v1/classroom/operation/update/integra/classroom/${classroom_id}`,
        data,
      );
      return response.data.pk as string;
    } catch (error) {
      if (error instanceof AxiosError) {
        const { response } = error;
        let errorAPI: ErrorAPI;

        switch (response?.status) {
          case 400:
            errorAPI = new ErrorAPI(response.status, response.data?.msg);
            break;
          case 401:
            errorAPI = new ErrorAPI(response.status, response.statusText);
            break;
          case 404:
            errorAPI = new ErrorAPI(response.status, response.data?.msg);
            break;
          case 403:
            errorAPI = new ErrorAPI(response.status, response.data?.msg);
            break;
          case 503:
            errorAPI = new ErrorAPI(response.status, response.data?.msg);
            break;
          default:
            console.error('Unhandled AxiosError:', error);
            errorAPI = new ErrorAPI(
              500,
              'Desculpe, algo deu errado. Por favor, tente novamente mais tarde.',
            );
            break;
        }
        return errorAPI;
      }
    }
  },
});
