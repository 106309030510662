import { SignoutRedirectArgs } from 'oidc-client-ts';
import { useAuth } from 'react-oidc-context';

const Logout: React.FunctionComponent = () => {
  const { removeUser, signoutRedirect } = useAuth();

  const redirect: SignoutRedirectArgs = {
    post_logout_redirect_uri: process.env.REACT_APP_REDIRECT_URI ?? '',
  };

  void removeUser();
  void signoutRedirect(redirect);

  return <></>;
};

export default Logout;
