import React, { useState, ChangeEvent, useEffect } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

interface NumberInputProps extends Omit<TextFieldProps, 'onChange'> {
  label: string;
  maxValue?: number;
  initialValue?: number;
  onChange?: (value: number | '') => void;
}

const NumberInput: React.FC<NumberInputProps> = ({
  label,
  maxValue = 30,
  initialValue,
  onChange,
  ...otherProps
}) => {
  const [value, setValue] = useState<number | ''>(initialValue ?? '');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (initialValue !== undefined) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const numericValue = Number(inputValue);

    if (numericValue > maxValue || numericValue <= 0 || isNaN(numericValue)) {
      setError(`O valor deve estar entre 1 e ${maxValue}.`);
      setValue(1);
      if (onChange) onChange(1);
    } else {
      setError(null);
      setValue(numericValue);
      if (onChange) onChange(numericValue);
    }
  };

  return (
    <TextField
      label={label}
      error={!!error}
      variant="outlined"
      helperText={error || ''}
      value={value === '' ? '' : String(value)} // Converte para string para evitar warning
      onChange={handleChange}
      fullWidth
      type="number"
      inputProps={{ min: 1, max: maxValue }}
      {...otherProps}
    />
  );
};

export default NumberInput;
