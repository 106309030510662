const generateDateRange = (startDate: string, endDate: string) => {
  const dates = [];
  const current = new Date(startDate);
  const lastDate = new Date(endDate);

  while (current <= lastDate) {
    dates.push(new Date(current));
    current.setDate(current.getDate() + 1);
  }

  return dates;
};

export default generateDateRange;
