export async function downloadFile(
  fileUrl: string,
  fileName: string,
): Promise<void> {
  try {
    // Fetch the file data
    const response = await fetch(fileUrl);

    if (!response.ok) {
      throw new Error(`Failed to download file. Status: ${response.status}`);
    }

    // Convert the response to a blob
    const blob = await response.blob();

    // Create a blob URL
    const blobUrl = URL.createObjectURL(blob);

    // Create an anchor element
    const anchor = document.createElement('a');

    // Set the href attribute to the blob URL
    anchor.href = blobUrl;

    const timestamp = new Date().toISOString().replace(/[:.]/g, '-');

    // Set the download attribute to specify the file name
    anchor.download = `${fileName}${timestamp}`;

    // Append the anchor to the document body
    document.body.appendChild(anchor);

    // Trigger a click event on the anchor element
    anchor.click();

    // Remove the anchor and revoke the blob URL
    document.body.removeChild(anchor);
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error(`Error downloading file`);
    // Handle the error as needed, e.g., show a user-friendly message
  }
}
