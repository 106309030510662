import React, { useState } from 'react';
import { Container, Typography, Button } from '@mui/material';
import ModalSelectLocation from '../../components/ModalSelectLocation';

const PlaceInformation: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);

  const handleModalClose = (location: string) => {
    if (location) {
      setSelectedLocation(location);
    }
    setModalOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Monitoramento de Locais
      </Typography>
      {selectedLocation ? (
        <Typography variant="h6">
          Local Selecionado: {selectedLocation}
        </Typography>
      ) : (
        <Typography variant="h6">Nenhum local selecionado.</Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={openModal}
        style={{ marginTop: 20 }}
      >
        Mudar Local
      </Button>
      <ModalSelectLocation open={modalOpen} onClose={handleModalClose} />
    </Container>
  );
};

export default PlaceInformation;
