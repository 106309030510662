/* eslint-disable prefer-const */
import { AxiosError } from 'axios';
import { get, post, patch } from '../adapters/xhr';
import ErrorAPI from '../../core/models/ErrorAPI';
import { IUser } from '../../core/interfaces';

export const assistantAPI = () => ({
  getAssistant: async () => {
    const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
    try {
      let response = await get(`${BaseURL}/api/v1/assistant/list`);
      return response.data as IUser[];
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }

      return [] as IUser[];
    }
  },
  sendAssistant: async (id_classroom: string, data: object) => {
    const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
    try {
      let response = await post(
        `${BaseURL}/api/v1/assistant/create/classroom/${id_classroom}`,
        data,
      );
      return response.status;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }

      return 500;
    }
  },
  getAssistantDisabled: async () => {
    const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
    try {
      let response = await get(`${BaseURL}/api/v1/auth/list/assistant`);
      return response.data as IUser[];
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }

      return [] as IUser[];
    }
  },
  activateUser: async (data: object) => {
    const BaseURL: string | undefined = process.env.REACT_APP_BASE_URL;
    try {
      let response = await patch(`${BaseURL}/api/v1/auth/live/assistant`, data);
      return response.data as IUser[];
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          const response = new ErrorAPI(
            error.response?.status,
            error.response.statusText,
          );
          return response;
        }
      }

      return 500;
    }
  },
});
