import {
  FormGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Divider,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { TOptionsClassroom, TSeachClassroom } from '../../../../../core/types';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

interface IPropsOptionClassroom {
  initOptions: TOptionsClassroom;
  typeClassroom: TSeachClassroom;
  onChange: (value: TOptionsClassroom) => void;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const OptionClassroom: React.FC<IPropsOptionClassroom> = ({
  initOptions,
  typeClassroom,
  onChange,
}) => {
  const [options, setOptions] = useState<TOptionsClassroom>(initOptions);
  const handleChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      optionKey: keyof TOptionsClassroom,
    ) => {
      const newValue = event.target.value === 'true';
      const updatedOptions = { ...options, [optionKey]: newValue };
      setOptions(updatedOptions);
      // Optamos por chamar onChange aqui diretamente ao invés de usar useEffect
      onChange(updatedOptions);
    },
    [options, onChange],
  );

  return (
    <>
      <FormGroup
        sx={{
          margin: '1rem',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row', md: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Item>
            <FormControl sx={{ marginRight: '1rem', marginLeft: '0.2rem' }}>
              <FormLabel id="supplier-permission-row-radio-buttons-group-label">
                Validar o QRCode na entrada do evento?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="supplier-permission-row-radio-buttons-group-label"
                name="supplierPermission"
                value={String(options.supplierPermission)}
                onChange={(e) => handleChange(e, 'supplierPermission')}
              >
                <FormControlLabel
                  value={'false'}
                  control={<Radio />}
                  label="Sim"
                />
                <FormControlLabel
                  value={'true'}
                  control={<Radio />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Item>

          {typeClassroom !== 'LOCAL' && (
            <Item>
              <FormControl sx={{ marginRight: '1rem', marginLeft: '0.2rem' }}>
                <FormLabel id="auto-send-row-radio-buttons-group-label">
                  Deseja enviar uma mensagem com o QR code (carteira) para o
                  aluno antecipadamente?"
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="auto-send-row-radio-buttons-group-label"
                  name="autoSend"
                  value={String(options.autoSend)}
                  onChange={(e) => handleChange(e, 'autoSend')}
                >
                  <FormControlLabel
                    value={'true'}
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value={'false'}
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              </FormControl>
            </Item>
          )}

          <Item>
            <FormControl sx={{ marginRight: '1rem', marginLeft: '0.2rem' }}>
              <FormLabel id="event-receipt-row-radio-buttons-group-label">
                Necessária evidência da realização do evento ?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="event-receipt-row-radio-buttons-group-label"
                name="eventReceipt"
                value={String(options.eventReceipt)}
                onChange={(e) => handleChange(e, 'eventReceipt')}
              >
                <FormControlLabel
                  value={'true'}
                  control={<Radio />}
                  label="Sim"
                />
                <FormControlLabel
                  value={'false'}
                  control={<Radio />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Item>
          <Item>
            <FormControl sx={{ marginRight: '1rem', marginLeft: '0.2rem' }}>
              <FormLabel id="is-limite-enroolment-row-radio-buttons-group-label">
                Quer limitar a quantidade de alunos nesta turma?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="is-limite-enrollment-row-radio-buttons-group-label"
                name="isLimitedEnrollment"
                value={String(options.isLimitedEnrollment)}
                onChange={(e) => handleChange(e, 'isLimitedEnrollment')}
              >
                <FormControlLabel
                  value={'true'}
                  control={<Radio />}
                  label="Sim"
                />
                <FormControlLabel
                  value={'false'}
                  control={<Radio />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Item>

          <Item>
            <FormControl sx={{ marginRight: '1rem', marginLeft: '0.2rem' }}>
              <FormLabel id="required-certificate-row-radio-buttons-group-label">
                Necessário certificado para o evento?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="required-certificate-row-radio-buttons-group-label"
                name="requireCertificate"
                value={String(options.requireCertificate)}
                onChange={(e) => handleChange(e, 'requireCertificate')}
              >
                <FormControlLabel
                  value={'true'}
                  control={<Radio />}
                  label="Sim"
                />
                <FormControlLabel
                  value={'false'}
                  control={<Radio />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Item>

          <Item>
            <FormControl sx={{ marginRight: '1rem', marginLeft: '0.2rem' }}>
              <FormLabel id="event-composite-row-radio-buttons-group-labe">
                É evento principal ?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="event-composite-row-radio-buttons-group-labe"
                name="eventComposite"
                value={String(options.eventComposite)}
                onChange={(e) => handleChange(e, 'eventComposite')}
              >
                <FormControlLabel
                  value={'true'}
                  control={<Radio />}
                  label="Sim"
                />
                <FormControlLabel
                  value={'false'}
                  control={<Radio />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Item>

          <Item>
            <FormControl sx={{ marginRight: '1rem', marginLeft: '0.2rem' }}>
              <FormLabel id="belogns-to-a-big-event-row-radio-buttons-group-labe">
                Faz parte de um evento principal ?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="belogns-to-a-big-event-row-radio-buttons-group-labe"
                name="belongsToABigEvent"
                value={String(options.belongsToABigEvent)}
                onChange={(e) => handleChange(e, 'belongsToABigEvent')}
              >
                <FormControlLabel
                  value={'true'}
                  control={<Radio />}
                  label="Sim"
                />
                <FormControlLabel
                  value={'false'}
                  control={<Radio />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Item>
        </Stack>
      </FormGroup>
    </>
  );
};

export default OptionClassroom;
