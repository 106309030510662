import { ICompositeEvent } from '../core/interfaces';
import { CompositeEventOperation } from '../infra/api/CompositeEventOperation';

export async function generateCompositeEvent(compositeEvent: ICompositeEvent) {
  const instaceInfra = CompositeEventOperation();
  const compositeEventOperation =
    await instaceInfra.generateCompositeEventAPI(compositeEvent);

  return compositeEventOperation;
}

export async function getAllCompositeEvent(params?: URLSearchParams) {
  const instaceInfra = CompositeEventOperation();
  const compositeEventOperation =
    await instaceInfra.getAllCompositeEventAPI(params);

  return compositeEventOperation;
}

export async function getCompositeEventClassroom(id_composite_event?: string) {
  const instaceInfra = CompositeEventOperation();
  const classroom =
    await instaceInfra.getCompositeEventClassroomAPI(id_composite_event);

  return classroom;
}
